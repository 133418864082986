body, .ui.button {
    font-family: "Inter", sans-serif;
    line-height: 1em;
}

.nav-item {
    font-size: 16px;
}

.menu-row {
    color: #FFF;
}

h1.ui.header {
    color: #014694;
}

.menu-row:nth-child(1) {
    background-color: #014694;
}

.menu-row:nth-child(1) .nav-item:before {
    border-bottom-color: #f0f4f9;
}

.menu-row:nth-child(2) {
    background-color: #f0f4f9 !important;
    color: #014694 !important;
    border-bottom: 1px solid #d6dcd8 !important;
}

.menu-row:nth-child(2) .nav-item {
    display: inline-block;
    min-width: 10em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-row:nth-child(2) .nav-item.active {
    background-color: #e7eef7;
    font-weight: bold;
    border-bottom: 4px solid #014694;
    padding-top: 4px;
}

.menu-row:nth-child(2) .nav-item:before {
    display: none;
}

html {
    font-size: 14px;
}

/* Make none fullscreen modal have the close icon inside the modal instead of
 * outside.
 */
.ui.modal > .close {
    top: 1.0535rem;
    right: 1rem;
    color: rgba(0, 0, 0, 0.87);
}

a:hover {
    color: unset;
    text-decoration: unset;
}

.ui.table {
    border-radius: 0;
    border: unset;
}

.ui.table > thead > tr > th {
    background-color: #F0F4F9;
    color: #014694;
    border-bottom: unset !important;
}

.ui.table > thead > tr > th {
    background-color: #f0f4f9;
    color: #014694;
    border-bottom: unset !important;
}

.ui.table > tbody > tr > td {
    border-top: unset;
}

.ui.table > tbody > tr:nth-child(odd) > td {
    background-color: #e7eef7;
}

.ui.table > tbody > tr:nth-child(even) > td {
    background-color: #f0f4f9;
}
