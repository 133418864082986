i.icon.md-outlined.md-outlined {
    font-family: 'Material Icons Outlined' !important;
}
i.icon.md-123 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-123:after {
    content: '123';
    font-size: 1.2em;
}
i.icon.md-360 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-360:after {
    content: '360';
    font-size: 1.2em;
}
i.icon.md-10k {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-10k:after {
    content: '10k';
    font-size: 1.2em;
}
i.icon.md-10mp {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-10mp:after {
    content: '10mp';
    font-size: 1.2em;
}
i.icon.md-11mp {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-11mp:after {
    content: '11mp';
    font-size: 1.2em;
}
i.icon.md-12mp {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-12mp:after {
    content: '12mp';
    font-size: 1.2em;
}
i.icon.md-13mp {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-13mp:after {
    content: '13mp';
    font-size: 1.2em;
}
i.icon.md-14mp {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-14mp:after {
    content: '14mp';
    font-size: 1.2em;
}
i.icon.md-15mp {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-15mp:after {
    content: '15mp';
    font-size: 1.2em;
}
i.icon.md-16mp {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-16mp:after {
    content: '16mp';
    font-size: 1.2em;
}
i.icon.md-17mp {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-17mp:after {
    content: '17mp';
    font-size: 1.2em;
}
i.icon.md-18-up-rating {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-18-up-rating:after {
    content: '18_up_rating';
    font-size: 1.2em;
}
i.icon.md-18mp {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-18mp:after {
    content: '18mp';
    font-size: 1.2em;
}
i.icon.md-19mp {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-19mp:after {
    content: '19mp';
    font-size: 1.2em;
}
i.icon.md-1k {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-1k:after {
    content: '1k';
    font-size: 1.2em;
}
i.icon.md-1k-plus {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-1k-plus:after {
    content: '1k_plus';
    font-size: 1.2em;
}
i.icon.md-1x-mobiledata {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-1x-mobiledata:after {
    content: '1x_mobiledata';
    font-size: 1.2em;
}
i.icon.md-20mp {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-20mp:after {
    content: '20mp';
    font-size: 1.2em;
}
i.icon.md-21mp {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-21mp:after {
    content: '21mp';
    font-size: 1.2em;
}
i.icon.md-22mp {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-22mp:after {
    content: '22mp';
    font-size: 1.2em;
}
i.icon.md-23mp {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-23mp:after {
    content: '23mp';
    font-size: 1.2em;
}
i.icon.md-24mp {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-24mp:after {
    content: '24mp';
    font-size: 1.2em;
}
i.icon.md-2k {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-2k:after {
    content: '2k';
    font-size: 1.2em;
}
i.icon.md-2k-plus {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-2k-plus:after {
    content: '2k_plus';
    font-size: 1.2em;
}
i.icon.md-2mp {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-2mp:after {
    content: '2mp';
    font-size: 1.2em;
}
i.icon.md-30fps {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-30fps:after {
    content: '30fps';
    font-size: 1.2em;
}
i.icon.md-30fps-select {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-30fps-select:after {
    content: '30fps_select';
    font-size: 1.2em;
}
i.icon.md-3d-rotation {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-3d-rotation:after {
    content: '3d_rotation';
    font-size: 1.2em;
}
i.icon.md-3g-mobiledata {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-3g-mobiledata:after {
    content: '3g_mobiledata';
    font-size: 1.2em;
}
i.icon.md-3k {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-3k:after {
    content: '3k';
    font-size: 1.2em;
}
i.icon.md-3k-plus {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-3k-plus:after {
    content: '3k_plus';
    font-size: 1.2em;
}
i.icon.md-3mp {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-3mp:after {
    content: '3mp';
    font-size: 1.2em;
}
i.icon.md-3p {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-3p:after {
    content: '3p';
    font-size: 1.2em;
}
i.icon.md-4g-mobiledata {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-4g-mobiledata:after {
    content: '4g_mobiledata';
    font-size: 1.2em;
}
i.icon.md-4g-plus-mobiledata {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-4g-plus-mobiledata:after {
    content: '4g_plus_mobiledata';
    font-size: 1.2em;
}
i.icon.md-4k {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-4k:after {
    content: '4k';
    font-size: 1.2em;
}
i.icon.md-4k-plus {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-4k-plus:after {
    content: '4k_plus';
    font-size: 1.2em;
}
i.icon.md-4mp {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-4mp:after {
    content: '4mp';
    font-size: 1.2em;
}
i.icon.md-5g {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-5g:after {
    content: '5g';
    font-size: 1.2em;
}
i.icon.md-5k {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-5k:after {
    content: '5k';
    font-size: 1.2em;
}
i.icon.md-5k-plus {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-5k-plus:after {
    content: '5k_plus';
    font-size: 1.2em;
}
i.icon.md-5mp {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-5mp:after {
    content: '5mp';
    font-size: 1.2em;
}
i.icon.md-60fps {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-60fps:after {
    content: '60fps';
    font-size: 1.2em;
}
i.icon.md-60fps-select {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-60fps-select:after {
    content: '60fps_select';
    font-size: 1.2em;
}
i.icon.md-6-ft-apart {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-6-ft-apart:after {
    content: '6_ft_apart';
    font-size: 1.2em;
}
i.icon.md-6k {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-6k:after {
    content: '6k';
    font-size: 1.2em;
}
i.icon.md-6k-plus {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-6k-plus:after {
    content: '6k_plus';
    font-size: 1.2em;
}
i.icon.md-6mp {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-6mp:after {
    content: '6mp';
    font-size: 1.2em;
}
i.icon.md-7k {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-7k:after {
    content: '7k';
    font-size: 1.2em;
}
i.icon.md-7k-plus {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-7k-plus:after {
    content: '7k_plus';
    font-size: 1.2em;
}
i.icon.md-7mp {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-7mp:after {
    content: '7mp';
    font-size: 1.2em;
}
i.icon.md-8k {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-8k:after {
    content: '8k';
    font-size: 1.2em;
}
i.icon.md-8k-plus {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-8k-plus:after {
    content: '8k_plus';
    font-size: 1.2em;
}
i.icon.md-8mp {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-8mp:after {
    content: '8mp';
    font-size: 1.2em;
}
i.icon.md-9k {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-9k:after {
    content: '9k';
    font-size: 1.2em;
}
i.icon.md-9k-plus {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-9k-plus:after {
    content: '9k_plus';
    font-size: 1.2em;
}
i.icon.md-9mp {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-9mp:after {
    content: '9mp';
    font-size: 1.2em;
}
i.icon.md-abc {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-abc:after {
    content: 'abc';
    font-size: 1.2em;
}
i.icon.md-ac-unit {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-ac-unit:after {
    content: 'ac_unit';
    font-size: 1.2em;
}
i.icon.md-access-alarm {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-access-alarm:after {
    content: 'access_alarm';
    font-size: 1.2em;
}
i.icon.md-access-alarms {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-access-alarms:after {
    content: 'access_alarms';
    font-size: 1.2em;
}
i.icon.md-access-time {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-access-time:after {
    content: 'access_time';
    font-size: 1.2em;
}
i.icon.md-access-time-filled {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-access-time-filled:after {
    content: 'access_time_filled';
    font-size: 1.2em;
}
i.icon.md-accessibility {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-accessibility:after {
    content: 'accessibility';
    font-size: 1.2em;
}
i.icon.md-accessibility-new {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-accessibility-new:after {
    content: 'accessibility_new';
    font-size: 1.2em;
}
i.icon.md-accessible {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-accessible:after {
    content: 'accessible';
    font-size: 1.2em;
}
i.icon.md-accessible-forward {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-accessible-forward:after {
    content: 'accessible_forward';
    font-size: 1.2em;
}
i.icon.md-account-balance {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-account-balance:after {
    content: 'account_balance';
    font-size: 1.2em;
}
i.icon.md-account-balance-wallet {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-account-balance-wallet:after {
    content: 'account_balance_wallet';
    font-size: 1.2em;
}
i.icon.md-account-box {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-account-box:after {
    content: 'account_box';
    font-size: 1.2em;
}
i.icon.md-account-circle {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-account-circle:after {
    content: 'account_circle';
    font-size: 1.2em;
}
i.icon.md-account-tree {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-account-tree:after {
    content: 'account_tree';
    font-size: 1.2em;
}
i.icon.md-ad-units {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-ad-units:after {
    content: 'ad_units';
    font-size: 1.2em;
}
i.icon.md-adb {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-adb:after {
    content: 'adb';
    font-size: 1.2em;
}
i.icon.md-add {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-add:after {
    content: 'add';
    font-size: 1.2em;
}
i.icon.md-add-a-photo {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-add-a-photo:after {
    content: 'add_a_photo';
    font-size: 1.2em;
}
i.icon.md-add-alarm {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-add-alarm:after {
    content: 'add_alarm';
    font-size: 1.2em;
}
i.icon.md-add-alert {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-add-alert:after {
    content: 'add_alert';
    font-size: 1.2em;
}
i.icon.md-add-box {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-add-box:after {
    content: 'add_box';
    font-size: 1.2em;
}
i.icon.md-add-business {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-add-business:after {
    content: 'add_business';
    font-size: 1.2em;
}
i.icon.md-add-card {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-add-card:after {
    content: 'add_card';
    font-size: 1.2em;
}
i.icon.md-add-chart {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-add-chart:after {
    content: 'add_chart';
    font-size: 1.2em;
}
i.icon.md-add-circle {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-add-circle:after {
    content: 'add_circle';
    font-size: 1.2em;
}
i.icon.md-add-circle-outline {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-add-circle-outline:after {
    content: 'add_circle_outline';
    font-size: 1.2em;
}
i.icon.md-add-comment {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-add-comment:after {
    content: 'add_comment';
    font-size: 1.2em;
}
i.icon.md-add-home {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-add-home:after {
    content: 'add_home';
    font-size: 1.2em;
}
i.icon.md-add-home-work {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-add-home-work:after {
    content: 'add_home_work';
    font-size: 1.2em;
}
i.icon.md-add-ic-call {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-add-ic-call:after {
    content: 'add_ic_call';
    font-size: 1.2em;
}
i.icon.md-add-link {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-add-link:after {
    content: 'add_link';
    font-size: 1.2em;
}
i.icon.md-add-location {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-add-location:after {
    content: 'add_location';
    font-size: 1.2em;
}
i.icon.md-add-location-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-add-location-alt:after {
    content: 'add_location_alt';
    font-size: 1.2em;
}
i.icon.md-add-moderator {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-add-moderator:after {
    content: 'add_moderator';
    font-size: 1.2em;
}
i.icon.md-add-photo-alternate {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-add-photo-alternate:after {
    content: 'add_photo_alternate';
    font-size: 1.2em;
}
i.icon.md-add-reaction {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-add-reaction:after {
    content: 'add_reaction';
    font-size: 1.2em;
}
i.icon.md-add-road {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-add-road:after {
    content: 'add_road';
    font-size: 1.2em;
}
i.icon.md-add-shopping-cart {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-add-shopping-cart:after {
    content: 'add_shopping_cart';
    font-size: 1.2em;
}
i.icon.md-add-task {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-add-task:after {
    content: 'add_task';
    font-size: 1.2em;
}
i.icon.md-add-to-drive {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-add-to-drive:after {
    content: 'add_to_drive';
    font-size: 1.2em;
}
i.icon.md-add-to-home-screen {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-add-to-home-screen:after {
    content: 'add_to_home_screen';
    font-size: 1.2em;
}
i.icon.md-add-to-photos {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-add-to-photos:after {
    content: 'add_to_photos';
    font-size: 1.2em;
}
i.icon.md-add-to-queue {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-add-to-queue:after {
    content: 'add_to_queue';
    font-size: 1.2em;
}
i.icon.md-addchart {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-addchart:after {
    content: 'addchart';
    font-size: 1.2em;
}
i.icon.md-adf-scanner {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-adf-scanner:after {
    content: 'adf_scanner';
    font-size: 1.2em;
}
i.icon.md-adjust {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-adjust:after {
    content: 'adjust';
    font-size: 1.2em;
}
i.icon.md-admin-panel-settings {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-admin-panel-settings:after {
    content: 'admin_panel_settings';
    font-size: 1.2em;
}
i.icon.md-ads-click {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-ads-click:after {
    content: 'ads_click';
    font-size: 1.2em;
}
i.icon.md-agriculture {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-agriculture:after {
    content: 'agriculture';
    font-size: 1.2em;
}
i.icon.md-air {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-air:after {
    content: 'air';
    font-size: 1.2em;
}
i.icon.md-airline-seat-flat {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-airline-seat-flat:after {
    content: 'airline_seat_flat';
    font-size: 1.2em;
}
i.icon.md-airline-seat-flat-angled {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-airline-seat-flat-angled:after {
    content: 'airline_seat_flat_angled';
    font-size: 1.2em;
}
i.icon.md-airline-seat-individual-suite {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-airline-seat-individual-suite:after {
    content: 'airline_seat_individual_suite';
    font-size: 1.2em;
}
i.icon.md-airline-seat-legroom-extra {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-airline-seat-legroom-extra:after {
    content: 'airline_seat_legroom_extra';
    font-size: 1.2em;
}
i.icon.md-airline-seat-legroom-normal {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-airline-seat-legroom-normal:after {
    content: 'airline_seat_legroom_normal';
    font-size: 1.2em;
}
i.icon.md-airline-seat-legroom-reduced {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-airline-seat-legroom-reduced:after {
    content: 'airline_seat_legroom_reduced';
    font-size: 1.2em;
}
i.icon.md-airline-seat-recline-extra {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-airline-seat-recline-extra:after {
    content: 'airline_seat_recline_extra';
    font-size: 1.2em;
}
i.icon.md-airline-seat-recline-normal {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-airline-seat-recline-normal:after {
    content: 'airline_seat_recline_normal';
    font-size: 1.2em;
}
i.icon.md-airline-stops {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-airline-stops:after {
    content: 'airline_stops';
    font-size: 1.2em;
}
i.icon.md-airlines {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-airlines:after {
    content: 'airlines';
    font-size: 1.2em;
}
i.icon.md-airplane-ticket {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-airplane-ticket:after {
    content: 'airplane_ticket';
    font-size: 1.2em;
}
i.icon.md-airplanemode-active {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-airplanemode-active:after {
    content: 'airplanemode_active';
    font-size: 1.2em;
}
i.icon.md-airplanemode-inactive {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-airplanemode-inactive:after {
    content: 'airplanemode_inactive';
    font-size: 1.2em;
}
i.icon.md-airplay {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-airplay:after {
    content: 'airplay';
    font-size: 1.2em;
}
i.icon.md-airport-shuttle {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-airport-shuttle:after {
    content: 'airport_shuttle';
    font-size: 1.2em;
}
i.icon.md-alarm {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-alarm:after {
    content: 'alarm';
    font-size: 1.2em;
}
i.icon.md-alarm-add {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-alarm-add:after {
    content: 'alarm_add';
    font-size: 1.2em;
}
i.icon.md-alarm-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-alarm-off:after {
    content: 'alarm_off';
    font-size: 1.2em;
}
i.icon.md-alarm-on {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-alarm-on:after {
    content: 'alarm_on';
    font-size: 1.2em;
}
i.icon.md-album {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-album:after {
    content: 'album';
    font-size: 1.2em;
}
i.icon.md-align-horizontal-center {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-align-horizontal-center:after {
    content: 'align_horizontal_center';
    font-size: 1.2em;
}
i.icon.md-align-horizontal-left {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-align-horizontal-left:after {
    content: 'align_horizontal_left';
    font-size: 1.2em;
}
i.icon.md-align-horizontal-right {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-align-horizontal-right:after {
    content: 'align_horizontal_right';
    font-size: 1.2em;
}
i.icon.md-align-vertical-bottom {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-align-vertical-bottom:after {
    content: 'align_vertical_bottom';
    font-size: 1.2em;
}
i.icon.md-align-vertical-center {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-align-vertical-center:after {
    content: 'align_vertical_center';
    font-size: 1.2em;
}
i.icon.md-align-vertical-top {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-align-vertical-top:after {
    content: 'align_vertical_top';
    font-size: 1.2em;
}
i.icon.md-all-inbox {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-all-inbox:after {
    content: 'all_inbox';
    font-size: 1.2em;
}
i.icon.md-all-inclusive {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-all-inclusive:after {
    content: 'all_inclusive';
    font-size: 1.2em;
}
i.icon.md-all-out {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-all-out:after {
    content: 'all_out';
    font-size: 1.2em;
}
i.icon.md-alt-route {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-alt-route:after {
    content: 'alt_route';
    font-size: 1.2em;
}
i.icon.md-alternate-email {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-alternate-email:after {
    content: 'alternate_email';
    font-size: 1.2em;
}
i.icon.md-analytics {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-analytics:after {
    content: 'analytics';
    font-size: 1.2em;
}
i.icon.md-anchor {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-anchor:after {
    content: 'anchor';
    font-size: 1.2em;
}
i.icon.md-android {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-android:after {
    content: 'android';
    font-size: 1.2em;
}
i.icon.md-animation {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-animation:after {
    content: 'animation';
    font-size: 1.2em;
}
i.icon.md-announcement {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-announcement:after {
    content: 'announcement';
    font-size: 1.2em;
}
i.icon.md-aod {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-aod:after {
    content: 'aod';
    font-size: 1.2em;
}
i.icon.md-apartment {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-apartment:after {
    content: 'apartment';
    font-size: 1.2em;
}
i.icon.md-api {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-api:after {
    content: 'api';
    font-size: 1.2em;
}
i.icon.md-app-blocking {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-app-blocking:after {
    content: 'app_blocking';
    font-size: 1.2em;
}
i.icon.md-app-registration {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-app-registration:after {
    content: 'app_registration';
    font-size: 1.2em;
}
i.icon.md-app-settings-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-app-settings-alt:after {
    content: 'app_settings_alt';
    font-size: 1.2em;
}
i.icon.md-app-shortcut {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-app-shortcut:after {
    content: 'app_shortcut';
    font-size: 1.2em;
}
i.icon.md-approval {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-approval:after {
    content: 'approval';
    font-size: 1.2em;
}
i.icon.md-apps {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-apps:after {
    content: 'apps';
    font-size: 1.2em;
}
i.icon.md-apps-outage {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-apps-outage:after {
    content: 'apps_outage';
    font-size: 1.2em;
}
i.icon.md-architecture {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-architecture:after {
    content: 'architecture';
    font-size: 1.2em;
}
i.icon.md-archive {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-archive:after {
    content: 'archive';
    font-size: 1.2em;
}
i.icon.md-area-chart {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-area-chart:after {
    content: 'area_chart';
    font-size: 1.2em;
}
i.icon.md-arrow-back {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-arrow-back:after {
    content: 'arrow_back';
    font-size: 1.2em;
}
i.icon.md-arrow-back-ios {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-arrow-back-ios:after {
    content: 'arrow_back_ios';
    font-size: 1.2em;
}
i.icon.md-arrow-back-ios-new {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-arrow-back-ios-new:after {
    content: 'arrow_back_ios_new';
    font-size: 1.2em;
}
i.icon.md-arrow-circle-down {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-arrow-circle-down:after {
    content: 'arrow_circle_down';
    font-size: 1.2em;
}
i.icon.md-arrow-circle-left {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-arrow-circle-left:after {
    content: 'arrow_circle_left';
    font-size: 1.2em;
}
i.icon.md-arrow-circle-right {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-arrow-circle-right:after {
    content: 'arrow_circle_right';
    font-size: 1.2em;
}
i.icon.md-arrow-circle-up {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-arrow-circle-up:after {
    content: 'arrow_circle_up';
    font-size: 1.2em;
}
i.icon.md-arrow-downward {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-arrow-downward:after {
    content: 'arrow_downward';
    font-size: 1.2em;
}
i.icon.md-arrow-drop-down {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-arrow-drop-down:after {
    content: 'arrow_drop_down';
    font-size: 1.2em;
}
i.icon.md-arrow-drop-down-circle {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-arrow-drop-down-circle:after {
    content: 'arrow_drop_down_circle';
    font-size: 1.2em;
}
i.icon.md-arrow-drop-up {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-arrow-drop-up:after {
    content: 'arrow_drop_up';
    font-size: 1.2em;
}
i.icon.md-arrow-forward {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-arrow-forward:after {
    content: 'arrow_forward';
    font-size: 1.2em;
}
i.icon.md-arrow-forward-ios {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-arrow-forward-ios:after {
    content: 'arrow_forward_ios';
    font-size: 1.2em;
}
i.icon.md-arrow-left {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-arrow-left:after {
    content: 'arrow_left';
    font-size: 1.2em;
}
i.icon.md-arrow-outward {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-arrow-outward:after {
    content: 'arrow_outward';
    font-size: 1.2em;
}
i.icon.md-arrow-right {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-arrow-right:after {
    content: 'arrow_right';
    font-size: 1.2em;
}
i.icon.md-arrow-right-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-arrow-right-alt:after {
    content: 'arrow_right_alt';
    font-size: 1.2em;
}
i.icon.md-arrow-upward {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-arrow-upward:after {
    content: 'arrow_upward';
    font-size: 1.2em;
}
i.icon.md-art-track {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-art-track:after {
    content: 'art_track';
    font-size: 1.2em;
}
i.icon.md-article {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-article:after {
    content: 'article';
    font-size: 1.2em;
}
i.icon.md-aspect-ratio {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-aspect-ratio:after {
    content: 'aspect_ratio';
    font-size: 1.2em;
}
i.icon.md-assessment {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-assessment:after {
    content: 'assessment';
    font-size: 1.2em;
}
i.icon.md-assignment {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-assignment:after {
    content: 'assignment';
    font-size: 1.2em;
}
i.icon.md-assignment-ind {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-assignment-ind:after {
    content: 'assignment_ind';
    font-size: 1.2em;
}
i.icon.md-assignment-late {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-assignment-late:after {
    content: 'assignment_late';
    font-size: 1.2em;
}
i.icon.md-assignment-return {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-assignment-return:after {
    content: 'assignment_return';
    font-size: 1.2em;
}
i.icon.md-assignment-returned {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-assignment-returned:after {
    content: 'assignment_returned';
    font-size: 1.2em;
}
i.icon.md-assignment-turned-in {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-assignment-turned-in:after {
    content: 'assignment_turned_in';
    font-size: 1.2em;
}
i.icon.md-assist-walker {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-assist-walker:after {
    content: 'assist_walker';
    font-size: 1.2em;
}
i.icon.md-assistant {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-assistant:after {
    content: 'assistant';
    font-size: 1.2em;
}
i.icon.md-assistant-direction {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-assistant-direction:after {
    content: 'assistant_direction';
    font-size: 1.2em;
}
i.icon.md-assistant-photo {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-assistant-photo:after {
    content: 'assistant_photo';
    font-size: 1.2em;
}
i.icon.md-assured-workload {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-assured-workload:after {
    content: 'assured_workload';
    font-size: 1.2em;
}
i.icon.md-atm {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-atm:after {
    content: 'atm';
    font-size: 1.2em;
}
i.icon.md-attach-email {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-attach-email:after {
    content: 'attach_email';
    font-size: 1.2em;
}
i.icon.md-attach-file {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-attach-file:after {
    content: 'attach_file';
    font-size: 1.2em;
}
i.icon.md-attach-money {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-attach-money:after {
    content: 'attach_money';
    font-size: 1.2em;
}
i.icon.md-attachment {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-attachment:after {
    content: 'attachment';
    font-size: 1.2em;
}
i.icon.md-attractions {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-attractions:after {
    content: 'attractions';
    font-size: 1.2em;
}
i.icon.md-attribution {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-attribution:after {
    content: 'attribution';
    font-size: 1.2em;
}
i.icon.md-audio-file {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-audio-file:after {
    content: 'audio_file';
    font-size: 1.2em;
}
i.icon.md-audiotrack {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-audiotrack:after {
    content: 'audiotrack';
    font-size: 1.2em;
}
i.icon.md-auto-awesome {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-auto-awesome:after {
    content: 'auto_awesome';
    font-size: 1.2em;
}
i.icon.md-auto-awesome-mosaic {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-auto-awesome-mosaic:after {
    content: 'auto_awesome_mosaic';
    font-size: 1.2em;
}
i.icon.md-auto-awesome-motion {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-auto-awesome-motion:after {
    content: 'auto_awesome_motion';
    font-size: 1.2em;
}
i.icon.md-auto-delete {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-auto-delete:after {
    content: 'auto_delete';
    font-size: 1.2em;
}
i.icon.md-auto-fix-high {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-auto-fix-high:after {
    content: 'auto_fix_high';
    font-size: 1.2em;
}
i.icon.md-auto-fix-normal {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-auto-fix-normal:after {
    content: 'auto_fix_normal';
    font-size: 1.2em;
}
i.icon.md-auto-fix-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-auto-fix-off:after {
    content: 'auto_fix_off';
    font-size: 1.2em;
}
i.icon.md-auto-graph {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-auto-graph:after {
    content: 'auto_graph';
    font-size: 1.2em;
}
i.icon.md-auto-mode {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-auto-mode:after {
    content: 'auto_mode';
    font-size: 1.2em;
}
i.icon.md-auto-stories {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-auto-stories:after {
    content: 'auto_stories';
    font-size: 1.2em;
}
i.icon.md-autofps-select {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-autofps-select:after {
    content: 'autofps_select';
    font-size: 1.2em;
}
i.icon.md-autorenew {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-autorenew:after {
    content: 'autorenew';
    font-size: 1.2em;
}
i.icon.md-av-timer {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-av-timer:after {
    content: 'av_timer';
    font-size: 1.2em;
}
i.icon.md-baby-changing-station {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-baby-changing-station:after {
    content: 'baby_changing_station';
    font-size: 1.2em;
}
i.icon.md-back-hand {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-back-hand:after {
    content: 'back_hand';
    font-size: 1.2em;
}
i.icon.md-backpack {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-backpack:after {
    content: 'backpack';
    font-size: 1.2em;
}
i.icon.md-backspace {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-backspace:after {
    content: 'backspace';
    font-size: 1.2em;
}
i.icon.md-backup {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-backup:after {
    content: 'backup';
    font-size: 1.2em;
}
i.icon.md-backup-table {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-backup-table:after {
    content: 'backup_table';
    font-size: 1.2em;
}
i.icon.md-badge {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-badge:after {
    content: 'badge';
    font-size: 1.2em;
}
i.icon.md-bakery-dining {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-bakery-dining:after {
    content: 'bakery_dining';
    font-size: 1.2em;
}
i.icon.md-balance {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-balance:after {
    content: 'balance';
    font-size: 1.2em;
}
i.icon.md-balcony {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-balcony:after {
    content: 'balcony';
    font-size: 1.2em;
}
i.icon.md-ballot {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-ballot:after {
    content: 'ballot';
    font-size: 1.2em;
}
i.icon.md-bar-chart {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-bar-chart:after {
    content: 'bar_chart';
    font-size: 1.2em;
}
i.icon.md-batch-prediction {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-batch-prediction:after {
    content: 'batch_prediction';
    font-size: 1.2em;
}
i.icon.md-bathroom {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-bathroom:after {
    content: 'bathroom';
    font-size: 1.2em;
}
i.icon.md-bathtub {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-bathtub:after {
    content: 'bathtub';
    font-size: 1.2em;
}
i.icon.md-battery-0-bar {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-battery-0-bar:after {
    content: 'battery_0_bar';
    font-size: 1.2em;
}
i.icon.md-battery-1-bar {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-battery-1-bar:after {
    content: 'battery_1_bar';
    font-size: 1.2em;
}
i.icon.md-battery-2-bar {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-battery-2-bar:after {
    content: 'battery_2_bar';
    font-size: 1.2em;
}
i.icon.md-battery-3-bar {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-battery-3-bar:after {
    content: 'battery_3_bar';
    font-size: 1.2em;
}
i.icon.md-battery-4-bar {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-battery-4-bar:after {
    content: 'battery_4_bar';
    font-size: 1.2em;
}
i.icon.md-battery-5-bar {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-battery-5-bar:after {
    content: 'battery_5_bar';
    font-size: 1.2em;
}
i.icon.md-battery-6-bar {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-battery-6-bar:after {
    content: 'battery_6_bar';
    font-size: 1.2em;
}
i.icon.md-battery-alert {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-battery-alert:after {
    content: 'battery_alert';
    font-size: 1.2em;
}
i.icon.md-battery-charging-full {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-battery-charging-full:after {
    content: 'battery_charging_full';
    font-size: 1.2em;
}
i.icon.md-battery-full {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-battery-full:after {
    content: 'battery_full';
    font-size: 1.2em;
}
i.icon.md-battery-saver {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-battery-saver:after {
    content: 'battery_saver';
    font-size: 1.2em;
}
i.icon.md-battery-std {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-battery-std:after {
    content: 'battery_std';
    font-size: 1.2em;
}
i.icon.md-battery-unknown {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-battery-unknown:after {
    content: 'battery_unknown';
    font-size: 1.2em;
}
i.icon.md-beach-access {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-beach-access:after {
    content: 'beach_access';
    font-size: 1.2em;
}
i.icon.md-bed {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-bed:after {
    content: 'bed';
    font-size: 1.2em;
}
i.icon.md-bedroom-baby {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-bedroom-baby:after {
    content: 'bedroom_baby';
    font-size: 1.2em;
}
i.icon.md-bedroom-child {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-bedroom-child:after {
    content: 'bedroom_child';
    font-size: 1.2em;
}
i.icon.md-bedroom-parent {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-bedroom-parent:after {
    content: 'bedroom_parent';
    font-size: 1.2em;
}
i.icon.md-bedtime {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-bedtime:after {
    content: 'bedtime';
    font-size: 1.2em;
}
i.icon.md-bedtime-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-bedtime-off:after {
    content: 'bedtime_off';
    font-size: 1.2em;
}
i.icon.md-beenhere {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-beenhere:after {
    content: 'beenhere';
    font-size: 1.2em;
}
i.icon.md-bento {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-bento:after {
    content: 'bento';
    font-size: 1.2em;
}
i.icon.md-bike-scooter {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-bike-scooter:after {
    content: 'bike_scooter';
    font-size: 1.2em;
}
i.icon.md-biotech {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-biotech:after {
    content: 'biotech';
    font-size: 1.2em;
}
i.icon.md-blender {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-blender:after {
    content: 'blender';
    font-size: 1.2em;
}
i.icon.md-blind {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-blind:after {
    content: 'blind';
    font-size: 1.2em;
}
i.icon.md-blinds {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-blinds:after {
    content: 'blinds';
    font-size: 1.2em;
}
i.icon.md-blinds-closed {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-blinds-closed:after {
    content: 'blinds_closed';
    font-size: 1.2em;
}
i.icon.md-block {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-block:after {
    content: 'block';
    font-size: 1.2em;
}
i.icon.md-bloodtype {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-bloodtype:after {
    content: 'bloodtype';
    font-size: 1.2em;
}
i.icon.md-bluetooth {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-bluetooth:after {
    content: 'bluetooth';
    font-size: 1.2em;
}
i.icon.md-bluetooth-audio {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-bluetooth-audio:after {
    content: 'bluetooth_audio';
    font-size: 1.2em;
}
i.icon.md-bluetooth-connected {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-bluetooth-connected:after {
    content: 'bluetooth_connected';
    font-size: 1.2em;
}
i.icon.md-bluetooth-disabled {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-bluetooth-disabled:after {
    content: 'bluetooth_disabled';
    font-size: 1.2em;
}
i.icon.md-bluetooth-drive {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-bluetooth-drive:after {
    content: 'bluetooth_drive';
    font-size: 1.2em;
}
i.icon.md-bluetooth-searching {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-bluetooth-searching:after {
    content: 'bluetooth_searching';
    font-size: 1.2em;
}
i.icon.md-blur-circular {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-blur-circular:after {
    content: 'blur_circular';
    font-size: 1.2em;
}
i.icon.md-blur-linear {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-blur-linear:after {
    content: 'blur_linear';
    font-size: 1.2em;
}
i.icon.md-blur-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-blur-off:after {
    content: 'blur_off';
    font-size: 1.2em;
}
i.icon.md-blur-on {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-blur-on:after {
    content: 'blur_on';
    font-size: 1.2em;
}
i.icon.md-bolt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-bolt:after {
    content: 'bolt';
    font-size: 1.2em;
}
i.icon.md-book {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-book:after {
    content: 'book';
    font-size: 1.2em;
}
i.icon.md-book-online {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-book-online:after {
    content: 'book_online';
    font-size: 1.2em;
}
i.icon.md-bookmark {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-bookmark:after {
    content: 'bookmark';
    font-size: 1.2em;
}
i.icon.md-bookmark-add {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-bookmark-add:after {
    content: 'bookmark_add';
    font-size: 1.2em;
}
i.icon.md-bookmark-added {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-bookmark-added:after {
    content: 'bookmark_added';
    font-size: 1.2em;
}
i.icon.md-bookmark-border {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-bookmark-border:after {
    content: 'bookmark_border';
    font-size: 1.2em;
}
i.icon.md-bookmark-remove {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-bookmark-remove:after {
    content: 'bookmark_remove';
    font-size: 1.2em;
}
i.icon.md-bookmarks {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-bookmarks:after {
    content: 'bookmarks';
    font-size: 1.2em;
}
i.icon.md-border-all {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-border-all:after {
    content: 'border_all';
    font-size: 1.2em;
}
i.icon.md-border-bottom {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-border-bottom:after {
    content: 'border_bottom';
    font-size: 1.2em;
}
i.icon.md-border-clear {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-border-clear:after {
    content: 'border_clear';
    font-size: 1.2em;
}
i.icon.md-border-color {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-border-color:after {
    content: 'border_color';
    font-size: 1.2em;
}
i.icon.md-border-horizontal {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-border-horizontal:after {
    content: 'border_horizontal';
    font-size: 1.2em;
}
i.icon.md-border-inner {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-border-inner:after {
    content: 'border_inner';
    font-size: 1.2em;
}
i.icon.md-border-left {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-border-left:after {
    content: 'border_left';
    font-size: 1.2em;
}
i.icon.md-border-outer {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-border-outer:after {
    content: 'border_outer';
    font-size: 1.2em;
}
i.icon.md-border-right {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-border-right:after {
    content: 'border_right';
    font-size: 1.2em;
}
i.icon.md-border-style {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-border-style:after {
    content: 'border_style';
    font-size: 1.2em;
}
i.icon.md-border-top {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-border-top:after {
    content: 'border_top';
    font-size: 1.2em;
}
i.icon.md-border-vertical {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-border-vertical:after {
    content: 'border_vertical';
    font-size: 1.2em;
}
i.icon.md-boy {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-boy:after {
    content: 'boy';
    font-size: 1.2em;
}
i.icon.md-branding-watermark {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-branding-watermark:after {
    content: 'branding_watermark';
    font-size: 1.2em;
}
i.icon.md-breakfast-dining {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-breakfast-dining:after {
    content: 'breakfast_dining';
    font-size: 1.2em;
}
i.icon.md-brightness-1 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-brightness-1:after {
    content: 'brightness_1';
    font-size: 1.2em;
}
i.icon.md-brightness-2 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-brightness-2:after {
    content: 'brightness_2';
    font-size: 1.2em;
}
i.icon.md-brightness-3 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-brightness-3:after {
    content: 'brightness_3';
    font-size: 1.2em;
}
i.icon.md-brightness-4 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-brightness-4:after {
    content: 'brightness_4';
    font-size: 1.2em;
}
i.icon.md-brightness-5 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-brightness-5:after {
    content: 'brightness_5';
    font-size: 1.2em;
}
i.icon.md-brightness-6 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-brightness-6:after {
    content: 'brightness_6';
    font-size: 1.2em;
}
i.icon.md-brightness-7 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-brightness-7:after {
    content: 'brightness_7';
    font-size: 1.2em;
}
i.icon.md-brightness-auto {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-brightness-auto:after {
    content: 'brightness_auto';
    font-size: 1.2em;
}
i.icon.md-brightness-high {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-brightness-high:after {
    content: 'brightness_high';
    font-size: 1.2em;
}
i.icon.md-brightness-low {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-brightness-low:after {
    content: 'brightness_low';
    font-size: 1.2em;
}
i.icon.md-brightness-medium {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-brightness-medium:after {
    content: 'brightness_medium';
    font-size: 1.2em;
}
i.icon.md-broadcast-on-home {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-broadcast-on-home:after {
    content: 'broadcast_on_home';
    font-size: 1.2em;
}
i.icon.md-broadcast-on-personal {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-broadcast-on-personal:after {
    content: 'broadcast_on_personal';
    font-size: 1.2em;
}
i.icon.md-broken-image {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-broken-image:after {
    content: 'broken_image';
    font-size: 1.2em;
}
i.icon.md-browse-gallery {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-browse-gallery:after {
    content: 'browse_gallery';
    font-size: 1.2em;
}
i.icon.md-browser-not-supported {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-browser-not-supported:after {
    content: 'browser_not_supported';
    font-size: 1.2em;
}
i.icon.md-browser-updated {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-browser-updated:after {
    content: 'browser_updated';
    font-size: 1.2em;
}
i.icon.md-brunch-dining {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-brunch-dining:after {
    content: 'brunch_dining';
    font-size: 1.2em;
}
i.icon.md-brush {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-brush:after {
    content: 'brush';
    font-size: 1.2em;
}
i.icon.md-bubble-chart {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-bubble-chart:after {
    content: 'bubble_chart';
    font-size: 1.2em;
}
i.icon.md-bug-report {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-bug-report:after {
    content: 'bug_report';
    font-size: 1.2em;
}
i.icon.md-build {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-build:after {
    content: 'build';
    font-size: 1.2em;
}
i.icon.md-build-circle {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-build-circle:after {
    content: 'build_circle';
    font-size: 1.2em;
}
i.icon.md-bungalow {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-bungalow:after {
    content: 'bungalow';
    font-size: 1.2em;
}
i.icon.md-burst-mode {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-burst-mode:after {
    content: 'burst_mode';
    font-size: 1.2em;
}
i.icon.md-bus-alert {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-bus-alert:after {
    content: 'bus_alert';
    font-size: 1.2em;
}
i.icon.md-business {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-business:after {
    content: 'business';
    font-size: 1.2em;
}
i.icon.md-business-center {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-business-center:after {
    content: 'business_center';
    font-size: 1.2em;
}
i.icon.md-cabin {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-cabin:after {
    content: 'cabin';
    font-size: 1.2em;
}
i.icon.md-cable {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-cable:after {
    content: 'cable';
    font-size: 1.2em;
}
i.icon.md-cached {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-cached:after {
    content: 'cached';
    font-size: 1.2em;
}
i.icon.md-cake {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-cake:after {
    content: 'cake';
    font-size: 1.2em;
}
i.icon.md-calculate {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-calculate:after {
    content: 'calculate';
    font-size: 1.2em;
}
i.icon.md-calendar-month {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-calendar-month:after {
    content: 'calendar_month';
    font-size: 1.2em;
}
i.icon.md-calendar-today {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-calendar-today:after {
    content: 'calendar_today';
    font-size: 1.2em;
}
i.icon.md-calendar-view-day {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-calendar-view-day:after {
    content: 'calendar_view_day';
    font-size: 1.2em;
}
i.icon.md-calendar-view-month {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-calendar-view-month:after {
    content: 'calendar_view_month';
    font-size: 1.2em;
}
i.icon.md-calendar-view-week {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-calendar-view-week:after {
    content: 'calendar_view_week';
    font-size: 1.2em;
}
i.icon.md-call {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-call:after {
    content: 'call';
    font-size: 1.2em;
}
i.icon.md-call-end {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-call-end:after {
    content: 'call_end';
    font-size: 1.2em;
}
i.icon.md-call-made {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-call-made:after {
    content: 'call_made';
    font-size: 1.2em;
}
i.icon.md-call-merge {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-call-merge:after {
    content: 'call_merge';
    font-size: 1.2em;
}
i.icon.md-call-missed {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-call-missed:after {
    content: 'call_missed';
    font-size: 1.2em;
}
i.icon.md-call-missed-outgoing {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-call-missed-outgoing:after {
    content: 'call_missed_outgoing';
    font-size: 1.2em;
}
i.icon.md-call-received {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-call-received:after {
    content: 'call_received';
    font-size: 1.2em;
}
i.icon.md-call-split {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-call-split:after {
    content: 'call_split';
    font-size: 1.2em;
}
i.icon.md-call-to-action {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-call-to-action:after {
    content: 'call_to_action';
    font-size: 1.2em;
}
i.icon.md-camera {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-camera:after {
    content: 'camera';
    font-size: 1.2em;
}
i.icon.md-camera-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-camera-alt:after {
    content: 'camera_alt';
    font-size: 1.2em;
}
i.icon.md-camera-enhance {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-camera-enhance:after {
    content: 'camera_enhance';
    font-size: 1.2em;
}
i.icon.md-camera-front {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-camera-front:after {
    content: 'camera_front';
    font-size: 1.2em;
}
i.icon.md-camera-indoor {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-camera-indoor:after {
    content: 'camera_indoor';
    font-size: 1.2em;
}
i.icon.md-camera-outdoor {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-camera-outdoor:after {
    content: 'camera_outdoor';
    font-size: 1.2em;
}
i.icon.md-camera-rear {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-camera-rear:after {
    content: 'camera_rear';
    font-size: 1.2em;
}
i.icon.md-camera-roll {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-camera-roll:after {
    content: 'camera_roll';
    font-size: 1.2em;
}
i.icon.md-cameraswitch {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-cameraswitch:after {
    content: 'cameraswitch';
    font-size: 1.2em;
}
i.icon.md-campaign {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-campaign:after {
    content: 'campaign';
    font-size: 1.2em;
}
i.icon.md-cancel {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-cancel:after {
    content: 'cancel';
    font-size: 1.2em;
}
i.icon.md-cancel-presentation {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-cancel-presentation:after {
    content: 'cancel_presentation';
    font-size: 1.2em;
}
i.icon.md-cancel-schedule-send {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-cancel-schedule-send:after {
    content: 'cancel_schedule_send';
    font-size: 1.2em;
}
i.icon.md-candlestick-chart {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-candlestick-chart:after {
    content: 'candlestick_chart';
    font-size: 1.2em;
}
i.icon.md-car-crash {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-car-crash:after {
    content: 'car_crash';
    font-size: 1.2em;
}
i.icon.md-car-rental {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-car-rental:after {
    content: 'car_rental';
    font-size: 1.2em;
}
i.icon.md-car-repair {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-car-repair:after {
    content: 'car_repair';
    font-size: 1.2em;
}
i.icon.md-card-giftcard {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-card-giftcard:after {
    content: 'card_giftcard';
    font-size: 1.2em;
}
i.icon.md-card-membership {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-card-membership:after {
    content: 'card_membership';
    font-size: 1.2em;
}
i.icon.md-card-travel {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-card-travel:after {
    content: 'card_travel';
    font-size: 1.2em;
}
i.icon.md-carpenter {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-carpenter:after {
    content: 'carpenter';
    font-size: 1.2em;
}
i.icon.md-cases {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-cases:after {
    content: 'cases';
    font-size: 1.2em;
}
i.icon.md-casino {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-casino:after {
    content: 'casino';
    font-size: 1.2em;
}
i.icon.md-cast {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-cast:after {
    content: 'cast';
    font-size: 1.2em;
}
i.icon.md-cast-connected {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-cast-connected:after {
    content: 'cast_connected';
    font-size: 1.2em;
}
i.icon.md-cast-for-education {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-cast-for-education:after {
    content: 'cast_for_education';
    font-size: 1.2em;
}
i.icon.md-castle {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-castle:after {
    content: 'castle';
    font-size: 1.2em;
}
i.icon.md-catching-pokemon {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-catching-pokemon:after {
    content: 'catching_pokemon';
    font-size: 1.2em;
}
i.icon.md-category {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-category:after {
    content: 'category';
    font-size: 1.2em;
}
i.icon.md-celebration {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-celebration:after {
    content: 'celebration';
    font-size: 1.2em;
}
i.icon.md-cell-tower {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-cell-tower:after {
    content: 'cell_tower';
    font-size: 1.2em;
}
i.icon.md-cell-wifi {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-cell-wifi:after {
    content: 'cell_wifi';
    font-size: 1.2em;
}
i.icon.md-center-focus-strong {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-center-focus-strong:after {
    content: 'center_focus_strong';
    font-size: 1.2em;
}
i.icon.md-center-focus-weak {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-center-focus-weak:after {
    content: 'center_focus_weak';
    font-size: 1.2em;
}
i.icon.md-chair {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-chair:after {
    content: 'chair';
    font-size: 1.2em;
}
i.icon.md-chair-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-chair-alt:after {
    content: 'chair_alt';
    font-size: 1.2em;
}
i.icon.md-chalet {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-chalet:after {
    content: 'chalet';
    font-size: 1.2em;
}
i.icon.md-change-circle {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-change-circle:after {
    content: 'change_circle';
    font-size: 1.2em;
}
i.icon.md-change-history {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-change-history:after {
    content: 'change_history';
    font-size: 1.2em;
}
i.icon.md-charging-station {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-charging-station:after {
    content: 'charging_station';
    font-size: 1.2em;
}
i.icon.md-chat {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-chat:after {
    content: 'chat';
    font-size: 1.2em;
}
i.icon.md-chat-bubble {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-chat-bubble:after {
    content: 'chat_bubble';
    font-size: 1.2em;
}
i.icon.md-chat-bubble-outline {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-chat-bubble-outline:after {
    content: 'chat_bubble_outline';
    font-size: 1.2em;
}
i.icon.md-check {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-check:after {
    content: 'check';
    font-size: 1.2em;
}
i.icon.md-check-box {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-check-box:after {
    content: 'check_box';
    font-size: 1.2em;
}
i.icon.md-check-box-outline-blank {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-check-box-outline-blank:after {
    content: 'check_box_outline_blank';
    font-size: 1.2em;
}
i.icon.md-check-circle {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-check-circle:after {
    content: 'check_circle';
    font-size: 1.2em;
}
i.icon.md-check-circle-outline {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-check-circle-outline:after {
    content: 'check_circle_outline';
    font-size: 1.2em;
}
i.icon.md-checklist {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-checklist:after {
    content: 'checklist';
    font-size: 1.2em;
}
i.icon.md-checklist-rtl {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-checklist-rtl:after {
    content: 'checklist_rtl';
    font-size: 1.2em;
}
i.icon.md-checkroom {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-checkroom:after {
    content: 'checkroom';
    font-size: 1.2em;
}
i.icon.md-chevron-left {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-chevron-left:after {
    content: 'chevron_left';
    font-size: 1.2em;
}
i.icon.md-chevron-right {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-chevron-right:after {
    content: 'chevron_right';
    font-size: 1.2em;
}
i.icon.md-child-care {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-child-care:after {
    content: 'child_care';
    font-size: 1.2em;
}
i.icon.md-child-friendly {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-child-friendly:after {
    content: 'child_friendly';
    font-size: 1.2em;
}
i.icon.md-chrome-reader-mode {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-chrome-reader-mode:after {
    content: 'chrome_reader_mode';
    font-size: 1.2em;
}
i.icon.md-church {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-church:after {
    content: 'church';
    font-size: 1.2em;
}
i.icon.md-circle {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-circle:after {
    content: 'circle';
    font-size: 1.2em;
}
i.icon.md-circle-notifications {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-circle-notifications:after {
    content: 'circle_notifications';
    font-size: 1.2em;
}
i.icon.md-class {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-class:after {
    content: 'class';
    font-size: 1.2em;
}
i.icon.md-clean-hands {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-clean-hands:after {
    content: 'clean_hands';
    font-size: 1.2em;
}
i.icon.md-cleaning-services {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-cleaning-services:after {
    content: 'cleaning_services';
    font-size: 1.2em;
}
i.icon.md-clear {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-clear:after {
    content: 'clear';
    font-size: 1.2em;
}
i.icon.md-clear-all {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-clear-all:after {
    content: 'clear_all';
    font-size: 1.2em;
}
i.icon.md-close {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-close:after {
    content: 'close';
    font-size: 1.2em;
}
i.icon.md-close-fullscreen {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-close-fullscreen:after {
    content: 'close_fullscreen';
    font-size: 1.2em;
}
i.icon.md-closed-caption {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-closed-caption:after {
    content: 'closed_caption';
    font-size: 1.2em;
}
i.icon.md-closed-caption-disabled {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-closed-caption-disabled:after {
    content: 'closed_caption_disabled';
    font-size: 1.2em;
}
i.icon.md-closed-caption-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-closed-caption-off:after {
    content: 'closed_caption_off';
    font-size: 1.2em;
}
i.icon.md-cloud {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-cloud:after {
    content: 'cloud';
    font-size: 1.2em;
}
i.icon.md-cloud-circle {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-cloud-circle:after {
    content: 'cloud_circle';
    font-size: 1.2em;
}
i.icon.md-cloud-done {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-cloud-done:after {
    content: 'cloud_done';
    font-size: 1.2em;
}
i.icon.md-cloud-download {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-cloud-download:after {
    content: 'cloud_download';
    font-size: 1.2em;
}
i.icon.md-cloud-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-cloud-off:after {
    content: 'cloud_off';
    font-size: 1.2em;
}
i.icon.md-cloud-queue {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-cloud-queue:after {
    content: 'cloud_queue';
    font-size: 1.2em;
}
i.icon.md-cloud-sync {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-cloud-sync:after {
    content: 'cloud_sync';
    font-size: 1.2em;
}
i.icon.md-cloud-upload {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-cloud-upload:after {
    content: 'cloud_upload';
    font-size: 1.2em;
}
i.icon.md-co2 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-co2:after {
    content: 'co2';
    font-size: 1.2em;
}
i.icon.md-co-present {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-co-present:after {
    content: 'co_present';
    font-size: 1.2em;
}
i.icon.md-code {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-code:after {
    content: 'code';
    font-size: 1.2em;
}
i.icon.md-code-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-code-off:after {
    content: 'code_off';
    font-size: 1.2em;
}
i.icon.md-coffee {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-coffee:after {
    content: 'coffee';
    font-size: 1.2em;
}
i.icon.md-coffee-maker {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-coffee-maker:after {
    content: 'coffee_maker';
    font-size: 1.2em;
}
i.icon.md-collections {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-collections:after {
    content: 'collections';
    font-size: 1.2em;
}
i.icon.md-collections-bookmark {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-collections-bookmark:after {
    content: 'collections_bookmark';
    font-size: 1.2em;
}
i.icon.md-color-lens {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-color-lens:after {
    content: 'color_lens';
    font-size: 1.2em;
}
i.icon.md-colorize {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-colorize:after {
    content: 'colorize';
    font-size: 1.2em;
}
i.icon.md-comment {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-comment:after {
    content: 'comment';
    font-size: 1.2em;
}
i.icon.md-comment-bank {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-comment-bank:after {
    content: 'comment_bank';
    font-size: 1.2em;
}
i.icon.md-comments-disabled {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-comments-disabled:after {
    content: 'comments_disabled';
    font-size: 1.2em;
}
i.icon.md-commit {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-commit:after {
    content: 'commit';
    font-size: 1.2em;
}
i.icon.md-commute {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-commute:after {
    content: 'commute';
    font-size: 1.2em;
}
i.icon.md-compare {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-compare:after {
    content: 'compare';
    font-size: 1.2em;
}
i.icon.md-compare-arrows {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-compare-arrows:after {
    content: 'compare_arrows';
    font-size: 1.2em;
}
i.icon.md-compass-calibration {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-compass-calibration:after {
    content: 'compass_calibration';
    font-size: 1.2em;
}
i.icon.md-compost {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-compost:after {
    content: 'compost';
    font-size: 1.2em;
}
i.icon.md-compress {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-compress:after {
    content: 'compress';
    font-size: 1.2em;
}
i.icon.md-computer {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-computer:after {
    content: 'computer';
    font-size: 1.2em;
}
i.icon.md-confirmation-number {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-confirmation-number:after {
    content: 'confirmation_number';
    font-size: 1.2em;
}
i.icon.md-connect-without-contact {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-connect-without-contact:after {
    content: 'connect_without_contact';
    font-size: 1.2em;
}
i.icon.md-connected-tv {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-connected-tv:after {
    content: 'connected_tv';
    font-size: 1.2em;
}
i.icon.md-connecting-airports {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-connecting-airports:after {
    content: 'connecting_airports';
    font-size: 1.2em;
}
i.icon.md-construction {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-construction:after {
    content: 'construction';
    font-size: 1.2em;
}
i.icon.md-contact-emergency {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-contact-emergency:after {
    content: 'contact_emergency';
    font-size: 1.2em;
}
i.icon.md-contact-mail {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-contact-mail:after {
    content: 'contact_mail';
    font-size: 1.2em;
}
i.icon.md-contact-page {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-contact-page:after {
    content: 'contact_page';
    font-size: 1.2em;
}
i.icon.md-contact-phone {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-contact-phone:after {
    content: 'contact_phone';
    font-size: 1.2em;
}
i.icon.md-contact-support {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-contact-support:after {
    content: 'contact_support';
    font-size: 1.2em;
}
i.icon.md-contactless {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-contactless:after {
    content: 'contactless';
    font-size: 1.2em;
}
i.icon.md-contacts {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-contacts:after {
    content: 'contacts';
    font-size: 1.2em;
}
i.icon.md-content-copy {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-content-copy:after {
    content: 'content_copy';
    font-size: 1.2em;
}
i.icon.md-content-cut {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-content-cut:after {
    content: 'content_cut';
    font-size: 1.2em;
}
i.icon.md-content-paste {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-content-paste:after {
    content: 'content_paste';
    font-size: 1.2em;
}
i.icon.md-content-paste-go {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-content-paste-go:after {
    content: 'content_paste_go';
    font-size: 1.2em;
}
i.icon.md-content-paste-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-content-paste-off:after {
    content: 'content_paste_off';
    font-size: 1.2em;
}
i.icon.md-content-paste-search {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-content-paste-search:after {
    content: 'content_paste_search';
    font-size: 1.2em;
}
i.icon.md-contrast {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-contrast:after {
    content: 'contrast';
    font-size: 1.2em;
}
i.icon.md-control-camera {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-control-camera:after {
    content: 'control_camera';
    font-size: 1.2em;
}
i.icon.md-control-point {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-control-point:after {
    content: 'control_point';
    font-size: 1.2em;
}
i.icon.md-control-point-duplicate {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-control-point-duplicate:after {
    content: 'control_point_duplicate';
    font-size: 1.2em;
}
i.icon.md-cookie {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-cookie:after {
    content: 'cookie';
    font-size: 1.2em;
}
i.icon.md-copy-all {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-copy-all:after {
    content: 'copy_all';
    font-size: 1.2em;
}
i.icon.md-copyright {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-copyright:after {
    content: 'copyright';
    font-size: 1.2em;
}
i.icon.md-coronavirus {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-coronavirus:after {
    content: 'coronavirus';
    font-size: 1.2em;
}
i.icon.md-corporate-fare {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-corporate-fare:after {
    content: 'corporate_fare';
    font-size: 1.2em;
}
i.icon.md-cottage {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-cottage:after {
    content: 'cottage';
    font-size: 1.2em;
}
i.icon.md-countertops {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-countertops:after {
    content: 'countertops';
    font-size: 1.2em;
}
i.icon.md-create {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-create:after {
    content: 'create';
    font-size: 1.2em;
}
i.icon.md-create-new-folder {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-create-new-folder:after {
    content: 'create_new_folder';
    font-size: 1.2em;
}
i.icon.md-credit-card {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-credit-card:after {
    content: 'credit_card';
    font-size: 1.2em;
}
i.icon.md-credit-card-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-credit-card-off:after {
    content: 'credit_card_off';
    font-size: 1.2em;
}
i.icon.md-credit-score {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-credit-score:after {
    content: 'credit_score';
    font-size: 1.2em;
}
i.icon.md-crib {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-crib:after {
    content: 'crib';
    font-size: 1.2em;
}
i.icon.md-crisis-alert {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-crisis-alert:after {
    content: 'crisis_alert';
    font-size: 1.2em;
}
i.icon.md-crop {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-crop:after {
    content: 'crop';
    font-size: 1.2em;
}
i.icon.md-crop-16-9 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-crop-16-9:after {
    content: 'crop_16_9';
    font-size: 1.2em;
}
i.icon.md-crop-3-2 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-crop-3-2:after {
    content: 'crop_3_2';
    font-size: 1.2em;
}
i.icon.md-crop-5-4 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-crop-5-4:after {
    content: 'crop_5_4';
    font-size: 1.2em;
}
i.icon.md-crop-7-5 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-crop-7-5:after {
    content: 'crop_7_5';
    font-size: 1.2em;
}
i.icon.md-crop-din {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-crop-din:after {
    content: 'crop_din';
    font-size: 1.2em;
}
i.icon.md-crop-free {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-crop-free:after {
    content: 'crop_free';
    font-size: 1.2em;
}
i.icon.md-crop-landscape {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-crop-landscape:after {
    content: 'crop_landscape';
    font-size: 1.2em;
}
i.icon.md-crop-original {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-crop-original:after {
    content: 'crop_original';
    font-size: 1.2em;
}
i.icon.md-crop-portrait {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-crop-portrait:after {
    content: 'crop_portrait';
    font-size: 1.2em;
}
i.icon.md-crop-rotate {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-crop-rotate:after {
    content: 'crop_rotate';
    font-size: 1.2em;
}
i.icon.md-crop-square {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-crop-square:after {
    content: 'crop_square';
    font-size: 1.2em;
}
i.icon.md-cruelty-free {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-cruelty-free:after {
    content: 'cruelty_free';
    font-size: 1.2em;
}
i.icon.md-css {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-css:after {
    content: 'css';
    font-size: 1.2em;
}
i.icon.md-currency-bitcoin {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-currency-bitcoin:after {
    content: 'currency_bitcoin';
    font-size: 1.2em;
}
i.icon.md-currency-exchange {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-currency-exchange:after {
    content: 'currency_exchange';
    font-size: 1.2em;
}
i.icon.md-currency-franc {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-currency-franc:after {
    content: 'currency_franc';
    font-size: 1.2em;
}
i.icon.md-currency-lira {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-currency-lira:after {
    content: 'currency_lira';
    font-size: 1.2em;
}
i.icon.md-currency-pound {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-currency-pound:after {
    content: 'currency_pound';
    font-size: 1.2em;
}
i.icon.md-currency-ruble {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-currency-ruble:after {
    content: 'currency_ruble';
    font-size: 1.2em;
}
i.icon.md-currency-rupee {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-currency-rupee:after {
    content: 'currency_rupee';
    font-size: 1.2em;
}
i.icon.md-currency-yen {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-currency-yen:after {
    content: 'currency_yen';
    font-size: 1.2em;
}
i.icon.md-currency-yuan {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-currency-yuan:after {
    content: 'currency_yuan';
    font-size: 1.2em;
}
i.icon.md-curtains {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-curtains:after {
    content: 'curtains';
    font-size: 1.2em;
}
i.icon.md-curtains-closed {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-curtains-closed:after {
    content: 'curtains_closed';
    font-size: 1.2em;
}
i.icon.md-cyclone {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-cyclone:after {
    content: 'cyclone';
    font-size: 1.2em;
}
i.icon.md-dangerous {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-dangerous:after {
    content: 'dangerous';
    font-size: 1.2em;
}
i.icon.md-dark-mode {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-dark-mode:after {
    content: 'dark_mode';
    font-size: 1.2em;
}
i.icon.md-dashboard {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-dashboard:after {
    content: 'dashboard';
    font-size: 1.2em;
}
i.icon.md-dashboard-customize {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-dashboard-customize:after {
    content: 'dashboard_customize';
    font-size: 1.2em;
}
i.icon.md-data-array {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-data-array:after {
    content: 'data_array';
    font-size: 1.2em;
}
i.icon.md-data-exploration {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-data-exploration:after {
    content: 'data_exploration';
    font-size: 1.2em;
}
i.icon.md-data-object {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-data-object:after {
    content: 'data_object';
    font-size: 1.2em;
}
i.icon.md-data-saver-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-data-saver-off:after {
    content: 'data_saver_off';
    font-size: 1.2em;
}
i.icon.md-data-saver-on {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-data-saver-on:after {
    content: 'data_saver_on';
    font-size: 1.2em;
}
i.icon.md-data-thresholding {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-data-thresholding:after {
    content: 'data_thresholding';
    font-size: 1.2em;
}
i.icon.md-data-usage {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-data-usage:after {
    content: 'data_usage';
    font-size: 1.2em;
}
i.icon.md-dataset {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-dataset:after {
    content: 'dataset';
    font-size: 1.2em;
}
i.icon.md-dataset-linked {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-dataset-linked:after {
    content: 'dataset_linked';
    font-size: 1.2em;
}
i.icon.md-date-range {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-date-range:after {
    content: 'date_range';
    font-size: 1.2em;
}
i.icon.md-deblur {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-deblur:after {
    content: 'deblur';
    font-size: 1.2em;
}
i.icon.md-deck {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-deck:after {
    content: 'deck';
    font-size: 1.2em;
}
i.icon.md-dehaze {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-dehaze:after {
    content: 'dehaze';
    font-size: 1.2em;
}
i.icon.md-delete {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-delete:after {
    content: 'delete';
    font-size: 1.2em;
}
i.icon.md-delete-forever {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-delete-forever:after {
    content: 'delete_forever';
    font-size: 1.2em;
}
i.icon.md-delete-outline {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-delete-outline:after {
    content: 'delete_outline';
    font-size: 1.2em;
}
i.icon.md-delete-sweep {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-delete-sweep:after {
    content: 'delete_sweep';
    font-size: 1.2em;
}
i.icon.md-delivery-dining {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-delivery-dining:after {
    content: 'delivery_dining';
    font-size: 1.2em;
}
i.icon.md-density-large {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-density-large:after {
    content: 'density_large';
    font-size: 1.2em;
}
i.icon.md-density-medium {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-density-medium:after {
    content: 'density_medium';
    font-size: 1.2em;
}
i.icon.md-density-small {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-density-small:after {
    content: 'density_small';
    font-size: 1.2em;
}
i.icon.md-departure-board {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-departure-board:after {
    content: 'departure_board';
    font-size: 1.2em;
}
i.icon.md-description {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-description:after {
    content: 'description';
    font-size: 1.2em;
}
i.icon.md-deselect {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-deselect:after {
    content: 'deselect';
    font-size: 1.2em;
}
i.icon.md-design-services {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-design-services:after {
    content: 'design_services';
    font-size: 1.2em;
}
i.icon.md-desk {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-desk:after {
    content: 'desk';
    font-size: 1.2em;
}
i.icon.md-desktop-access-disabled {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-desktop-access-disabled:after {
    content: 'desktop_access_disabled';
    font-size: 1.2em;
}
i.icon.md-desktop-mac {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-desktop-mac:after {
    content: 'desktop_mac';
    font-size: 1.2em;
}
i.icon.md-desktop-windows {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-desktop-windows:after {
    content: 'desktop_windows';
    font-size: 1.2em;
}
i.icon.md-details {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-details:after {
    content: 'details';
    font-size: 1.2em;
}
i.icon.md-developer-board {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-developer-board:after {
    content: 'developer_board';
    font-size: 1.2em;
}
i.icon.md-developer-board-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-developer-board-off:after {
    content: 'developer_board_off';
    font-size: 1.2em;
}
i.icon.md-developer-mode {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-developer-mode:after {
    content: 'developer_mode';
    font-size: 1.2em;
}
i.icon.md-device-hub {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-device-hub:after {
    content: 'device_hub';
    font-size: 1.2em;
}
i.icon.md-device-thermostat {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-device-thermostat:after {
    content: 'device_thermostat';
    font-size: 1.2em;
}
i.icon.md-device-unknown {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-device-unknown:after {
    content: 'device_unknown';
    font-size: 1.2em;
}
i.icon.md-devices {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-devices:after {
    content: 'devices';
    font-size: 1.2em;
}
i.icon.md-devices-fold {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-devices-fold:after {
    content: 'devices_fold';
    font-size: 1.2em;
}
i.icon.md-devices-other {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-devices-other:after {
    content: 'devices_other';
    font-size: 1.2em;
}
i.icon.md-dialer-sip {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-dialer-sip:after {
    content: 'dialer_sip';
    font-size: 1.2em;
}
i.icon.md-dialpad {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-dialpad:after {
    content: 'dialpad';
    font-size: 1.2em;
}
i.icon.md-diamond {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-diamond:after {
    content: 'diamond';
    font-size: 1.2em;
}
i.icon.md-difference {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-difference:after {
    content: 'difference';
    font-size: 1.2em;
}
i.icon.md-dining {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-dining:after {
    content: 'dining';
    font-size: 1.2em;
}
i.icon.md-dinner-dining {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-dinner-dining:after {
    content: 'dinner_dining';
    font-size: 1.2em;
}
i.icon.md-directions {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-directions:after {
    content: 'directions';
    font-size: 1.2em;
}
i.icon.md-directions-bike {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-directions-bike:after {
    content: 'directions_bike';
    font-size: 1.2em;
}
i.icon.md-directions-boat {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-directions-boat:after {
    content: 'directions_boat';
    font-size: 1.2em;
}
i.icon.md-directions-boat-filled {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-directions-boat-filled:after {
    content: 'directions_boat_filled';
    font-size: 1.2em;
}
i.icon.md-directions-bus {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-directions-bus:after {
    content: 'directions_bus';
    font-size: 1.2em;
}
i.icon.md-directions-bus-filled {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-directions-bus-filled:after {
    content: 'directions_bus_filled';
    font-size: 1.2em;
}
i.icon.md-directions-car {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-directions-car:after {
    content: 'directions_car';
    font-size: 1.2em;
}
i.icon.md-directions-car-filled {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-directions-car-filled:after {
    content: 'directions_car_filled';
    font-size: 1.2em;
}
i.icon.md-directions-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-directions-off:after {
    content: 'directions_off';
    font-size: 1.2em;
}
i.icon.md-directions-railway {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-directions-railway:after {
    content: 'directions_railway';
    font-size: 1.2em;
}
i.icon.md-directions-railway-filled {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-directions-railway-filled:after {
    content: 'directions_railway_filled';
    font-size: 1.2em;
}
i.icon.md-directions-run {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-directions-run:after {
    content: 'directions_run';
    font-size: 1.2em;
}
i.icon.md-directions-subway {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-directions-subway:after {
    content: 'directions_subway';
    font-size: 1.2em;
}
i.icon.md-directions-subway-filled {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-directions-subway-filled:after {
    content: 'directions_subway_filled';
    font-size: 1.2em;
}
i.icon.md-directions-transit {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-directions-transit:after {
    content: 'directions_transit';
    font-size: 1.2em;
}
i.icon.md-directions-transit-filled {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-directions-transit-filled:after {
    content: 'directions_transit_filled';
    font-size: 1.2em;
}
i.icon.md-directions-walk {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-directions-walk:after {
    content: 'directions_walk';
    font-size: 1.2em;
}
i.icon.md-dirty-lens {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-dirty-lens:after {
    content: 'dirty_lens';
    font-size: 1.2em;
}
i.icon.md-disabled-by-default {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-disabled-by-default:after {
    content: 'disabled_by_default';
    font-size: 1.2em;
}
i.icon.md-disabled-visible {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-disabled-visible:after {
    content: 'disabled_visible';
    font-size: 1.2em;
}
i.icon.md-disc-full {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-disc-full:after {
    content: 'disc_full';
    font-size: 1.2em;
}
i.icon.md-discount {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-discount:after {
    content: 'discount';
    font-size: 1.2em;
}
i.icon.md-display-settings {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-display-settings:after {
    content: 'display_settings';
    font-size: 1.2em;
}
i.icon.md-diversity-1 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-diversity-1:after {
    content: 'diversity_1';
    font-size: 1.2em;
}
i.icon.md-diversity-2 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-diversity-2:after {
    content: 'diversity_2';
    font-size: 1.2em;
}
i.icon.md-diversity-3 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-diversity-3:after {
    content: 'diversity_3';
    font-size: 1.2em;
}
i.icon.md-dns {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-dns:after {
    content: 'dns';
    font-size: 1.2em;
}
i.icon.md-do-disturb {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-do-disturb:after {
    content: 'do_disturb';
    font-size: 1.2em;
}
i.icon.md-do-disturb-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-do-disturb-alt:after {
    content: 'do_disturb_alt';
    font-size: 1.2em;
}
i.icon.md-do-disturb-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-do-disturb-off:after {
    content: 'do_disturb_off';
    font-size: 1.2em;
}
i.icon.md-do-disturb-on {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-do-disturb-on:after {
    content: 'do_disturb_on';
    font-size: 1.2em;
}
i.icon.md-do-not-disturb {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-do-not-disturb:after {
    content: 'do_not_disturb';
    font-size: 1.2em;
}
i.icon.md-do-not-disturb-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-do-not-disturb-alt:after {
    content: 'do_not_disturb_alt';
    font-size: 1.2em;
}
i.icon.md-do-not-disturb-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-do-not-disturb-off:after {
    content: 'do_not_disturb_off';
    font-size: 1.2em;
}
i.icon.md-do-not-disturb-on {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-do-not-disturb-on:after {
    content: 'do_not_disturb_on';
    font-size: 1.2em;
}
i.icon.md-do-not-disturb-on-total-silence {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-do-not-disturb-on-total-silence:after {
    content: 'do_not_disturb_on_total_silence';
    font-size: 1.2em;
}
i.icon.md-do-not-step {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-do-not-step:after {
    content: 'do_not_step';
    font-size: 1.2em;
}
i.icon.md-do-not-touch {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-do-not-touch:after {
    content: 'do_not_touch';
    font-size: 1.2em;
}
i.icon.md-dock {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-dock:after {
    content: 'dock';
    font-size: 1.2em;
}
i.icon.md-document-scanner {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-document-scanner:after {
    content: 'document_scanner';
    font-size: 1.2em;
}
i.icon.md-domain {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-domain:after {
    content: 'domain';
    font-size: 1.2em;
}
i.icon.md-domain-add {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-domain-add:after {
    content: 'domain_add';
    font-size: 1.2em;
}
i.icon.md-domain-disabled {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-domain-disabled:after {
    content: 'domain_disabled';
    font-size: 1.2em;
}
i.icon.md-domain-verification {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-domain-verification:after {
    content: 'domain_verification';
    font-size: 1.2em;
}
i.icon.md-done {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-done:after {
    content: 'done';
    font-size: 1.2em;
}
i.icon.md-done-all {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-done-all:after {
    content: 'done_all';
    font-size: 1.2em;
}
i.icon.md-done-outline {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-done-outline:after {
    content: 'done_outline';
    font-size: 1.2em;
}
i.icon.md-donut-large {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-donut-large:after {
    content: 'donut_large';
    font-size: 1.2em;
}
i.icon.md-donut-small {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-donut-small:after {
    content: 'donut_small';
    font-size: 1.2em;
}
i.icon.md-door-back {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-door-back:after {
    content: 'door_back';
    font-size: 1.2em;
}
i.icon.md-door-front {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-door-front:after {
    content: 'door_front';
    font-size: 1.2em;
}
i.icon.md-door-sliding {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-door-sliding:after {
    content: 'door_sliding';
    font-size: 1.2em;
}
i.icon.md-doorbell {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-doorbell:after {
    content: 'doorbell';
    font-size: 1.2em;
}
i.icon.md-double-arrow {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-double-arrow:after {
    content: 'double_arrow';
    font-size: 1.2em;
}
i.icon.md-downhill-skiing {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-downhill-skiing:after {
    content: 'downhill_skiing';
    font-size: 1.2em;
}
i.icon.md-download {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-download:after {
    content: 'download';
    font-size: 1.2em;
}
i.icon.md-download-done {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-download-done:after {
    content: 'download_done';
    font-size: 1.2em;
}
i.icon.md-download-for-offline {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-download-for-offline:after {
    content: 'download_for_offline';
    font-size: 1.2em;
}
i.icon.md-downloading {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-downloading:after {
    content: 'downloading';
    font-size: 1.2em;
}
i.icon.md-drafts {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-drafts:after {
    content: 'drafts';
    font-size: 1.2em;
}
i.icon.md-drag-handle {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-drag-handle:after {
    content: 'drag_handle';
    font-size: 1.2em;
}
i.icon.md-drag-indicator {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-drag-indicator:after {
    content: 'drag_indicator';
    font-size: 1.2em;
}
i.icon.md-draw {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-draw:after {
    content: 'draw';
    font-size: 1.2em;
}
i.icon.md-drive-eta {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-drive-eta:after {
    content: 'drive_eta';
    font-size: 1.2em;
}
i.icon.md-drive-file-move {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-drive-file-move:after {
    content: 'drive_file_move';
    font-size: 1.2em;
}
i.icon.md-drive-file-move-rtl {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-drive-file-move-rtl:after {
    content: 'drive_file_move_rtl';
    font-size: 1.2em;
}
i.icon.md-drive-file-rename-outline {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-drive-file-rename-outline:after {
    content: 'drive_file_rename_outline';
    font-size: 1.2em;
}
i.icon.md-drive-folder-upload {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-drive-folder-upload:after {
    content: 'drive_folder_upload';
    font-size: 1.2em;
}
i.icon.md-dry {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-dry:after {
    content: 'dry';
    font-size: 1.2em;
}
i.icon.md-dry-cleaning {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-dry-cleaning:after {
    content: 'dry_cleaning';
    font-size: 1.2em;
}
i.icon.md-duo {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-duo:after {
    content: 'duo';
    font-size: 1.2em;
}
i.icon.md-dvr {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-dvr:after {
    content: 'dvr';
    font-size: 1.2em;
}
i.icon.md-dynamic-feed {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-dynamic-feed:after {
    content: 'dynamic_feed';
    font-size: 1.2em;
}
i.icon.md-dynamic-form {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-dynamic-form:after {
    content: 'dynamic_form';
    font-size: 1.2em;
}
i.icon.md-e-mobiledata {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-e-mobiledata:after {
    content: 'e_mobiledata';
    font-size: 1.2em;
}
i.icon.md-earbuds {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-earbuds:after {
    content: 'earbuds';
    font-size: 1.2em;
}
i.icon.md-earbuds-battery {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-earbuds-battery:after {
    content: 'earbuds_battery';
    font-size: 1.2em;
}
i.icon.md-east {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-east:after {
    content: 'east';
    font-size: 1.2em;
}
i.icon.md-edgesensor-high {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-edgesensor-high:after {
    content: 'edgesensor_high';
    font-size: 1.2em;
}
i.icon.md-edgesensor-low {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-edgesensor-low:after {
    content: 'edgesensor_low';
    font-size: 1.2em;
}
i.icon.md-edit {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-edit:after {
    content: 'edit';
    font-size: 1.2em;
}
i.icon.md-edit-attributes {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-edit-attributes:after {
    content: 'edit_attributes';
    font-size: 1.2em;
}
i.icon.md-edit-calendar {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-edit-calendar:after {
    content: 'edit_calendar';
    font-size: 1.2em;
}
i.icon.md-edit-location {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-edit-location:after {
    content: 'edit_location';
    font-size: 1.2em;
}
i.icon.md-edit-location-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-edit-location-alt:after {
    content: 'edit_location_alt';
    font-size: 1.2em;
}
i.icon.md-edit-note {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-edit-note:after {
    content: 'edit_note';
    font-size: 1.2em;
}
i.icon.md-edit-notifications {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-edit-notifications:after {
    content: 'edit_notifications';
    font-size: 1.2em;
}
i.icon.md-edit-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-edit-off:after {
    content: 'edit_off';
    font-size: 1.2em;
}
i.icon.md-edit-road {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-edit-road:after {
    content: 'edit_road';
    font-size: 1.2em;
}
i.icon.md-egg {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-egg:after {
    content: 'egg';
    font-size: 1.2em;
}
i.icon.md-egg-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-egg-alt:after {
    content: 'egg_alt';
    font-size: 1.2em;
}
i.icon.md-eject {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-eject:after {
    content: 'eject';
    font-size: 1.2em;
}
i.icon.md-elderly {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-elderly:after {
    content: 'elderly';
    font-size: 1.2em;
}
i.icon.md-elderly-woman {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-elderly-woman:after {
    content: 'elderly_woman';
    font-size: 1.2em;
}
i.icon.md-electric-bike {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-electric-bike:after {
    content: 'electric_bike';
    font-size: 1.2em;
}
i.icon.md-electric-bolt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-electric-bolt:after {
    content: 'electric_bolt';
    font-size: 1.2em;
}
i.icon.md-electric-car {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-electric-car:after {
    content: 'electric_car';
    font-size: 1.2em;
}
i.icon.md-electric-meter {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-electric-meter:after {
    content: 'electric_meter';
    font-size: 1.2em;
}
i.icon.md-electric-moped {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-electric-moped:after {
    content: 'electric_moped';
    font-size: 1.2em;
}
i.icon.md-electric-rickshaw {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-electric-rickshaw:after {
    content: 'electric_rickshaw';
    font-size: 1.2em;
}
i.icon.md-electric-scooter {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-electric-scooter:after {
    content: 'electric_scooter';
    font-size: 1.2em;
}
i.icon.md-electrical-services {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-electrical-services:after {
    content: 'electrical_services';
    font-size: 1.2em;
}
i.icon.md-elevator {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-elevator:after {
    content: 'elevator';
    font-size: 1.2em;
}
i.icon.md-email {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-email:after {
    content: 'email';
    font-size: 1.2em;
}
i.icon.md-emergency {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-emergency:after {
    content: 'emergency';
    font-size: 1.2em;
}
i.icon.md-emergency-recording {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-emergency-recording:after {
    content: 'emergency_recording';
    font-size: 1.2em;
}
i.icon.md-emergency-share {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-emergency-share:after {
    content: 'emergency_share';
    font-size: 1.2em;
}
i.icon.md-emoji-emotions {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-emoji-emotions:after {
    content: 'emoji_emotions';
    font-size: 1.2em;
}
i.icon.md-emoji-events {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-emoji-events:after {
    content: 'emoji_events';
    font-size: 1.2em;
}
i.icon.md-emoji-food-beverage {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-emoji-food-beverage:after {
    content: 'emoji_food_beverage';
    font-size: 1.2em;
}
i.icon.md-emoji-nature {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-emoji-nature:after {
    content: 'emoji_nature';
    font-size: 1.2em;
}
i.icon.md-emoji-objects {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-emoji-objects:after {
    content: 'emoji_objects';
    font-size: 1.2em;
}
i.icon.md-emoji-people {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-emoji-people:after {
    content: 'emoji_people';
    font-size: 1.2em;
}
i.icon.md-emoji-symbols {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-emoji-symbols:after {
    content: 'emoji_symbols';
    font-size: 1.2em;
}
i.icon.md-emoji-transportation {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-emoji-transportation:after {
    content: 'emoji_transportation';
    font-size: 1.2em;
}
i.icon.md-energy-savings-leaf {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-energy-savings-leaf:after {
    content: 'energy_savings_leaf';
    font-size: 1.2em;
}
i.icon.md-engineering {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-engineering:after {
    content: 'engineering';
    font-size: 1.2em;
}
i.icon.md-enhanced-encryption {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-enhanced-encryption:after {
    content: 'enhanced_encryption';
    font-size: 1.2em;
}
i.icon.md-equalizer {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-equalizer:after {
    content: 'equalizer';
    font-size: 1.2em;
}
i.icon.md-error {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-error:after {
    content: 'error';
    font-size: 1.2em;
}
i.icon.md-error-outline {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-error-outline:after {
    content: 'error_outline';
    font-size: 1.2em;
}
i.icon.md-escalator {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-escalator:after {
    content: 'escalator';
    font-size: 1.2em;
}
i.icon.md-escalator-warning {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-escalator-warning:after {
    content: 'escalator_warning';
    font-size: 1.2em;
}
i.icon.md-euro {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-euro:after {
    content: 'euro';
    font-size: 1.2em;
}
i.icon.md-euro-symbol {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-euro-symbol:after {
    content: 'euro_symbol';
    font-size: 1.2em;
}
i.icon.md-ev-station {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-ev-station:after {
    content: 'ev_station';
    font-size: 1.2em;
}
i.icon.md-event {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-event:after {
    content: 'event';
    font-size: 1.2em;
}
i.icon.md-event-available {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-event-available:after {
    content: 'event_available';
    font-size: 1.2em;
}
i.icon.md-event-busy {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-event-busy:after {
    content: 'event_busy';
    font-size: 1.2em;
}
i.icon.md-event-note {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-event-note:after {
    content: 'event_note';
    font-size: 1.2em;
}
i.icon.md-event-repeat {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-event-repeat:after {
    content: 'event_repeat';
    font-size: 1.2em;
}
i.icon.md-event-seat {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-event-seat:after {
    content: 'event_seat';
    font-size: 1.2em;
}
i.icon.md-exit-to-app {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-exit-to-app:after {
    content: 'exit_to_app';
    font-size: 1.2em;
}
i.icon.md-expand {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-expand:after {
    content: 'expand';
    font-size: 1.2em;
}
i.icon.md-expand-circle-down {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-expand-circle-down:after {
    content: 'expand_circle_down';
    font-size: 1.2em;
}
i.icon.md-expand-less {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-expand-less:after {
    content: 'expand_less';
    font-size: 1.2em;
}
i.icon.md-expand-more {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-expand-more:after {
    content: 'expand_more';
    font-size: 1.2em;
}
i.icon.md-explicit {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-explicit:after {
    content: 'explicit';
    font-size: 1.2em;
}
i.icon.md-explore {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-explore:after {
    content: 'explore';
    font-size: 1.2em;
}
i.icon.md-explore-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-explore-off:after {
    content: 'explore_off';
    font-size: 1.2em;
}
i.icon.md-exposure {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-exposure:after {
    content: 'exposure';
    font-size: 1.2em;
}
i.icon.md-exposure-neg-1 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-exposure-neg-1:after {
    content: 'exposure_neg_1';
    font-size: 1.2em;
}
i.icon.md-exposure-neg-2 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-exposure-neg-2:after {
    content: 'exposure_neg_2';
    font-size: 1.2em;
}
i.icon.md-exposure-plus-1 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-exposure-plus-1:after {
    content: 'exposure_plus_1';
    font-size: 1.2em;
}
i.icon.md-exposure-plus-2 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-exposure-plus-2:after {
    content: 'exposure_plus_2';
    font-size: 1.2em;
}
i.icon.md-exposure-zero {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-exposure-zero:after {
    content: 'exposure_zero';
    font-size: 1.2em;
}
i.icon.md-extension {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-extension:after {
    content: 'extension';
    font-size: 1.2em;
}
i.icon.md-extension-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-extension-off:after {
    content: 'extension_off';
    font-size: 1.2em;
}
i.icon.md-face {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-face:after {
    content: 'face';
    font-size: 1.2em;
}
i.icon.md-face-2 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-face-2:after {
    content: 'face_2';
    font-size: 1.2em;
}
i.icon.md-face-3 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-face-3:after {
    content: 'face_3';
    font-size: 1.2em;
}
i.icon.md-face-4 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-face-4:after {
    content: 'face_4';
    font-size: 1.2em;
}
i.icon.md-face-5 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-face-5:after {
    content: 'face_5';
    font-size: 1.2em;
}
i.icon.md-face-6 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-face-6:after {
    content: 'face_6';
    font-size: 1.2em;
}
i.icon.md-face-retouching-natural {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-face-retouching-natural:after {
    content: 'face_retouching_natural';
    font-size: 1.2em;
}
i.icon.md-face-retouching-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-face-retouching-off:after {
    content: 'face_retouching_off';
    font-size: 1.2em;
}
i.icon.md-fact-check {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-fact-check:after {
    content: 'fact_check';
    font-size: 1.2em;
}
i.icon.md-factory {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-factory:after {
    content: 'factory';
    font-size: 1.2em;
}
i.icon.md-family-restroom {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-family-restroom:after {
    content: 'family_restroom';
    font-size: 1.2em;
}
i.icon.md-fast-forward {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-fast-forward:after {
    content: 'fast_forward';
    font-size: 1.2em;
}
i.icon.md-fast-rewind {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-fast-rewind:after {
    content: 'fast_rewind';
    font-size: 1.2em;
}
i.icon.md-fastfood {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-fastfood:after {
    content: 'fastfood';
    font-size: 1.2em;
}
i.icon.md-favorite {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-favorite:after {
    content: 'favorite';
    font-size: 1.2em;
}
i.icon.md-favorite-border {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-favorite-border:after {
    content: 'favorite_border';
    font-size: 1.2em;
}
i.icon.md-fax {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-fax:after {
    content: 'fax';
    font-size: 1.2em;
}
i.icon.md-featured-play-list {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-featured-play-list:after {
    content: 'featured_play_list';
    font-size: 1.2em;
}
i.icon.md-featured-video {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-featured-video:after {
    content: 'featured_video';
    font-size: 1.2em;
}
i.icon.md-feed {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-feed:after {
    content: 'feed';
    font-size: 1.2em;
}
i.icon.md-feedback {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-feedback:after {
    content: 'feedback';
    font-size: 1.2em;
}
i.icon.md-female {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-female:after {
    content: 'female';
    font-size: 1.2em;
}
i.icon.md-fence {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-fence:after {
    content: 'fence';
    font-size: 1.2em;
}
i.icon.md-festival {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-festival:after {
    content: 'festival';
    font-size: 1.2em;
}
i.icon.md-fiber-dvr {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-fiber-dvr:after {
    content: 'fiber_dvr';
    font-size: 1.2em;
}
i.icon.md-fiber-manual-record {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-fiber-manual-record:after {
    content: 'fiber_manual_record';
    font-size: 1.2em;
}
i.icon.md-fiber-new {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-fiber-new:after {
    content: 'fiber_new';
    font-size: 1.2em;
}
i.icon.md-fiber-pin {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-fiber-pin:after {
    content: 'fiber_pin';
    font-size: 1.2em;
}
i.icon.md-fiber-smart-record {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-fiber-smart-record:after {
    content: 'fiber_smart_record';
    font-size: 1.2em;
}
i.icon.md-file-copy {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-file-copy:after {
    content: 'file_copy';
    font-size: 1.2em;
}
i.icon.md-file-download {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-file-download:after {
    content: 'file_download';
    font-size: 1.2em;
}
i.icon.md-file-download-done {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-file-download-done:after {
    content: 'file_download_done';
    font-size: 1.2em;
}
i.icon.md-file-download-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-file-download-off:after {
    content: 'file_download_off';
    font-size: 1.2em;
}
i.icon.md-file-open {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-file-open:after {
    content: 'file_open';
    font-size: 1.2em;
}
i.icon.md-file-present {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-file-present:after {
    content: 'file_present';
    font-size: 1.2em;
}
i.icon.md-file-upload {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-file-upload:after {
    content: 'file_upload';
    font-size: 1.2em;
}
i.icon.md-filter {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-filter:after {
    content: 'filter';
    font-size: 1.2em;
}
i.icon.md-filter-1 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-filter-1:after {
    content: 'filter_1';
    font-size: 1.2em;
}
i.icon.md-filter-2 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-filter-2:after {
    content: 'filter_2';
    font-size: 1.2em;
}
i.icon.md-filter-3 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-filter-3:after {
    content: 'filter_3';
    font-size: 1.2em;
}
i.icon.md-filter-4 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-filter-4:after {
    content: 'filter_4';
    font-size: 1.2em;
}
i.icon.md-filter-5 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-filter-5:after {
    content: 'filter_5';
    font-size: 1.2em;
}
i.icon.md-filter-6 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-filter-6:after {
    content: 'filter_6';
    font-size: 1.2em;
}
i.icon.md-filter-7 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-filter-7:after {
    content: 'filter_7';
    font-size: 1.2em;
}
i.icon.md-filter-8 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-filter-8:after {
    content: 'filter_8';
    font-size: 1.2em;
}
i.icon.md-filter-9 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-filter-9:after {
    content: 'filter_9';
    font-size: 1.2em;
}
i.icon.md-filter-9-plus {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-filter-9-plus:after {
    content: 'filter_9_plus';
    font-size: 1.2em;
}
i.icon.md-filter-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-filter-alt:after {
    content: 'filter_alt';
    font-size: 1.2em;
}
i.icon.md-filter-alt-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-filter-alt-off:after {
    content: 'filter_alt_off';
    font-size: 1.2em;
}
i.icon.md-filter-b-and-w {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-filter-b-and-w:after {
    content: 'filter_b_and_w';
    font-size: 1.2em;
}
i.icon.md-filter-center-focus {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-filter-center-focus:after {
    content: 'filter_center_focus';
    font-size: 1.2em;
}
i.icon.md-filter-drama {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-filter-drama:after {
    content: 'filter_drama';
    font-size: 1.2em;
}
i.icon.md-filter-frames {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-filter-frames:after {
    content: 'filter_frames';
    font-size: 1.2em;
}
i.icon.md-filter-hdr {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-filter-hdr:after {
    content: 'filter_hdr';
    font-size: 1.2em;
}
i.icon.md-filter-list {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-filter-list:after {
    content: 'filter_list';
    font-size: 1.2em;
}
i.icon.md-filter-list-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-filter-list-off:after {
    content: 'filter_list_off';
    font-size: 1.2em;
}
i.icon.md-filter-none {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-filter-none:after {
    content: 'filter_none';
    font-size: 1.2em;
}
i.icon.md-filter-tilt-shift {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-filter-tilt-shift:after {
    content: 'filter_tilt_shift';
    font-size: 1.2em;
}
i.icon.md-filter-vintage {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-filter-vintage:after {
    content: 'filter_vintage';
    font-size: 1.2em;
}
i.icon.md-find-in-page {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-find-in-page:after {
    content: 'find_in_page';
    font-size: 1.2em;
}
i.icon.md-find-replace {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-find-replace:after {
    content: 'find_replace';
    font-size: 1.2em;
}
i.icon.md-fingerprint {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-fingerprint:after {
    content: 'fingerprint';
    font-size: 1.2em;
}
i.icon.md-fire-extinguisher {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-fire-extinguisher:after {
    content: 'fire_extinguisher';
    font-size: 1.2em;
}
i.icon.md-fire-hydrant-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-fire-hydrant-alt:after {
    content: 'fire_hydrant_alt';
    font-size: 1.2em;
}
i.icon.md-fire-truck {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-fire-truck:after {
    content: 'fire_truck';
    font-size: 1.2em;
}
i.icon.md-fireplace {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-fireplace:after {
    content: 'fireplace';
    font-size: 1.2em;
}
i.icon.md-first-page {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-first-page:after {
    content: 'first_page';
    font-size: 1.2em;
}
i.icon.md-fit-screen {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-fit-screen:after {
    content: 'fit_screen';
    font-size: 1.2em;
}
i.icon.md-fitbit {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-fitbit:after {
    content: 'fitbit';
    font-size: 1.2em;
}
i.icon.md-fitness-center {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-fitness-center:after {
    content: 'fitness_center';
    font-size: 1.2em;
}
i.icon.md-flag {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-flag:after {
    content: 'flag';
    font-size: 1.2em;
}
i.icon.md-flag-circle {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-flag-circle:after {
    content: 'flag_circle';
    font-size: 1.2em;
}
i.icon.md-flaky {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-flaky:after {
    content: 'flaky';
    font-size: 1.2em;
}
i.icon.md-flare {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-flare:after {
    content: 'flare';
    font-size: 1.2em;
}
i.icon.md-flash-auto {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-flash-auto:after {
    content: 'flash_auto';
    font-size: 1.2em;
}
i.icon.md-flash-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-flash-off:after {
    content: 'flash_off';
    font-size: 1.2em;
}
i.icon.md-flash-on {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-flash-on:after {
    content: 'flash_on';
    font-size: 1.2em;
}
i.icon.md-flashlight-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-flashlight-off:after {
    content: 'flashlight_off';
    font-size: 1.2em;
}
i.icon.md-flashlight-on {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-flashlight-on:after {
    content: 'flashlight_on';
    font-size: 1.2em;
}
i.icon.md-flatware {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-flatware:after {
    content: 'flatware';
    font-size: 1.2em;
}
i.icon.md-flight {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-flight:after {
    content: 'flight';
    font-size: 1.2em;
}
i.icon.md-flight-class {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-flight-class:after {
    content: 'flight_class';
    font-size: 1.2em;
}
i.icon.md-flight-land {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-flight-land:after {
    content: 'flight_land';
    font-size: 1.2em;
}
i.icon.md-flight-takeoff {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-flight-takeoff:after {
    content: 'flight_takeoff';
    font-size: 1.2em;
}
i.icon.md-flip {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-flip:after {
    content: 'flip';
    font-size: 1.2em;
}
i.icon.md-flip-camera-android {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-flip-camera-android:after {
    content: 'flip_camera_android';
    font-size: 1.2em;
}
i.icon.md-flip-camera-ios {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-flip-camera-ios:after {
    content: 'flip_camera_ios';
    font-size: 1.2em;
}
i.icon.md-flip-to-back {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-flip-to-back:after {
    content: 'flip_to_back';
    font-size: 1.2em;
}
i.icon.md-flip-to-front {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-flip-to-front:after {
    content: 'flip_to_front';
    font-size: 1.2em;
}
i.icon.md-flood {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-flood:after {
    content: 'flood';
    font-size: 1.2em;
}
i.icon.md-fluorescent {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-fluorescent:after {
    content: 'fluorescent';
    font-size: 1.2em;
}
i.icon.md-flutter-dash {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-flutter-dash:after {
    content: 'flutter_dash';
    font-size: 1.2em;
}
i.icon.md-fmd-bad {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-fmd-bad:after {
    content: 'fmd_bad';
    font-size: 1.2em;
}
i.icon.md-fmd-good {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-fmd-good:after {
    content: 'fmd_good';
    font-size: 1.2em;
}
i.icon.md-folder {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-folder:after {
    content: 'folder';
    font-size: 1.2em;
}
i.icon.md-folder-copy {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-folder-copy:after {
    content: 'folder_copy';
    font-size: 1.2em;
}
i.icon.md-folder-delete {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-folder-delete:after {
    content: 'folder_delete';
    font-size: 1.2em;
}
i.icon.md-folder-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-folder-off:after {
    content: 'folder_off';
    font-size: 1.2em;
}
i.icon.md-folder-open {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-folder-open:after {
    content: 'folder_open';
    font-size: 1.2em;
}
i.icon.md-folder-shared {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-folder-shared:after {
    content: 'folder_shared';
    font-size: 1.2em;
}
i.icon.md-folder-special {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-folder-special:after {
    content: 'folder_special';
    font-size: 1.2em;
}
i.icon.md-folder-zip {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-folder-zip:after {
    content: 'folder_zip';
    font-size: 1.2em;
}
i.icon.md-follow-the-signs {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-follow-the-signs:after {
    content: 'follow_the_signs';
    font-size: 1.2em;
}
i.icon.md-font-download {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-font-download:after {
    content: 'font_download';
    font-size: 1.2em;
}
i.icon.md-font-download-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-font-download-off:after {
    content: 'font_download_off';
    font-size: 1.2em;
}
i.icon.md-food-bank {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-food-bank:after {
    content: 'food_bank';
    font-size: 1.2em;
}
i.icon.md-forest {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-forest:after {
    content: 'forest';
    font-size: 1.2em;
}
i.icon.md-fork-left {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-fork-left:after {
    content: 'fork_left';
    font-size: 1.2em;
}
i.icon.md-fork-right {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-fork-right:after {
    content: 'fork_right';
    font-size: 1.2em;
}
i.icon.md-format-align-center {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-format-align-center:after {
    content: 'format_align_center';
    font-size: 1.2em;
}
i.icon.md-format-align-justify {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-format-align-justify:after {
    content: 'format_align_justify';
    font-size: 1.2em;
}
i.icon.md-format-align-left {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-format-align-left:after {
    content: 'format_align_left';
    font-size: 1.2em;
}
i.icon.md-format-align-right {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-format-align-right:after {
    content: 'format_align_right';
    font-size: 1.2em;
}
i.icon.md-format-bold {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-format-bold:after {
    content: 'format_bold';
    font-size: 1.2em;
}
i.icon.md-format-clear {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-format-clear:after {
    content: 'format_clear';
    font-size: 1.2em;
}
i.icon.md-format-color-fill {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-format-color-fill:after {
    content: 'format_color_fill';
    font-size: 1.2em;
}
i.icon.md-format-color-reset {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-format-color-reset:after {
    content: 'format_color_reset';
    font-size: 1.2em;
}
i.icon.md-format-color-text {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-format-color-text:after {
    content: 'format_color_text';
    font-size: 1.2em;
}
i.icon.md-format-indent-decrease {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-format-indent-decrease:after {
    content: 'format_indent_decrease';
    font-size: 1.2em;
}
i.icon.md-format-indent-increase {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-format-indent-increase:after {
    content: 'format_indent_increase';
    font-size: 1.2em;
}
i.icon.md-format-italic {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-format-italic:after {
    content: 'format_italic';
    font-size: 1.2em;
}
i.icon.md-format-line-spacing {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-format-line-spacing:after {
    content: 'format_line_spacing';
    font-size: 1.2em;
}
i.icon.md-format-list-bulleted {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-format-list-bulleted:after {
    content: 'format_list_bulleted';
    font-size: 1.2em;
}
i.icon.md-format-list-numbered {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-format-list-numbered:after {
    content: 'format_list_numbered';
    font-size: 1.2em;
}
i.icon.md-format-list-numbered-rtl {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-format-list-numbered-rtl:after {
    content: 'format_list_numbered_rtl';
    font-size: 1.2em;
}
i.icon.md-format-overline {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-format-overline:after {
    content: 'format_overline';
    font-size: 1.2em;
}
i.icon.md-format-paint {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-format-paint:after {
    content: 'format_paint';
    font-size: 1.2em;
}
i.icon.md-format-quote {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-format-quote:after {
    content: 'format_quote';
    font-size: 1.2em;
}
i.icon.md-format-shapes {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-format-shapes:after {
    content: 'format_shapes';
    font-size: 1.2em;
}
i.icon.md-format-size {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-format-size:after {
    content: 'format_size';
    font-size: 1.2em;
}
i.icon.md-format-strikethrough {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-format-strikethrough:after {
    content: 'format_strikethrough';
    font-size: 1.2em;
}
i.icon.md-format-textdirection-l-to-r {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-format-textdirection-l-to-r:after {
    content: 'format_textdirection_l_to_r';
    font-size: 1.2em;
}
i.icon.md-format-textdirection-r-to-l {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-format-textdirection-r-to-l:after {
    content: 'format_textdirection_r_to_l';
    font-size: 1.2em;
}
i.icon.md-format-underlined {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-format-underlined:after {
    content: 'format_underlined';
    font-size: 1.2em;
}
i.icon.md-fort {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-fort:after {
    content: 'fort';
    font-size: 1.2em;
}
i.icon.md-forum {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-forum:after {
    content: 'forum';
    font-size: 1.2em;
}
i.icon.md-forward {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-forward:after {
    content: 'forward';
    font-size: 1.2em;
}
i.icon.md-forward-10 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-forward-10:after {
    content: 'forward_10';
    font-size: 1.2em;
}
i.icon.md-forward-30 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-forward-30:after {
    content: 'forward_30';
    font-size: 1.2em;
}
i.icon.md-forward-5 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-forward-5:after {
    content: 'forward_5';
    font-size: 1.2em;
}
i.icon.md-forward-to-inbox {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-forward-to-inbox:after {
    content: 'forward_to_inbox';
    font-size: 1.2em;
}
i.icon.md-foundation {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-foundation:after {
    content: 'foundation';
    font-size: 1.2em;
}
i.icon.md-free-breakfast {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-free-breakfast:after {
    content: 'free_breakfast';
    font-size: 1.2em;
}
i.icon.md-free-cancellation {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-free-cancellation:after {
    content: 'free_cancellation';
    font-size: 1.2em;
}
i.icon.md-front-hand {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-front-hand:after {
    content: 'front_hand';
    font-size: 1.2em;
}
i.icon.md-fullscreen {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-fullscreen:after {
    content: 'fullscreen';
    font-size: 1.2em;
}
i.icon.md-fullscreen-exit {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-fullscreen-exit:after {
    content: 'fullscreen_exit';
    font-size: 1.2em;
}
i.icon.md-functions {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-functions:after {
    content: 'functions';
    font-size: 1.2em;
}
i.icon.md-g-mobiledata {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-g-mobiledata:after {
    content: 'g_mobiledata';
    font-size: 1.2em;
}
i.icon.md-g-translate {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-g-translate:after {
    content: 'g_translate';
    font-size: 1.2em;
}
i.icon.md-gamepad {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-gamepad:after {
    content: 'gamepad';
    font-size: 1.2em;
}
i.icon.md-games {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-games:after {
    content: 'games';
    font-size: 1.2em;
}
i.icon.md-garage {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-garage:after {
    content: 'garage';
    font-size: 1.2em;
}
i.icon.md-gas-meter {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-gas-meter:after {
    content: 'gas_meter';
    font-size: 1.2em;
}
i.icon.md-gavel {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-gavel:after {
    content: 'gavel';
    font-size: 1.2em;
}
i.icon.md-generating-tokens {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-generating-tokens:after {
    content: 'generating_tokens';
    font-size: 1.2em;
}
i.icon.md-gesture {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-gesture:after {
    content: 'gesture';
    font-size: 1.2em;
}
i.icon.md-get-app {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-get-app:after {
    content: 'get_app';
    font-size: 1.2em;
}
i.icon.md-gif {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-gif:after {
    content: 'gif';
    font-size: 1.2em;
}
i.icon.md-gif-box {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-gif-box:after {
    content: 'gif_box';
    font-size: 1.2em;
}
i.icon.md-girl {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-girl:after {
    content: 'girl';
    font-size: 1.2em;
}
i.icon.md-gite {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-gite:after {
    content: 'gite';
    font-size: 1.2em;
}
i.icon.md-golf-course {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-golf-course:after {
    content: 'golf_course';
    font-size: 1.2em;
}
i.icon.md-gpp-bad {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-gpp-bad:after {
    content: 'gpp_bad';
    font-size: 1.2em;
}
i.icon.md-gpp-good {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-gpp-good:after {
    content: 'gpp_good';
    font-size: 1.2em;
}
i.icon.md-gpp-maybe {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-gpp-maybe:after {
    content: 'gpp_maybe';
    font-size: 1.2em;
}
i.icon.md-gps-fixed {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-gps-fixed:after {
    content: 'gps_fixed';
    font-size: 1.2em;
}
i.icon.md-gps-not-fixed {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-gps-not-fixed:after {
    content: 'gps_not_fixed';
    font-size: 1.2em;
}
i.icon.md-gps-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-gps-off:after {
    content: 'gps_off';
    font-size: 1.2em;
}
i.icon.md-grade {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-grade:after {
    content: 'grade';
    font-size: 1.2em;
}
i.icon.md-gradient {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-gradient:after {
    content: 'gradient';
    font-size: 1.2em;
}
i.icon.md-grading {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-grading:after {
    content: 'grading';
    font-size: 1.2em;
}
i.icon.md-grain {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-grain:after {
    content: 'grain';
    font-size: 1.2em;
}
i.icon.md-graphic-eq {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-graphic-eq:after {
    content: 'graphic_eq';
    font-size: 1.2em;
}
i.icon.md-grass {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-grass:after {
    content: 'grass';
    font-size: 1.2em;
}
i.icon.md-grid-3x3 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-grid-3x3:after {
    content: 'grid_3x3';
    font-size: 1.2em;
}
i.icon.md-grid-4x4 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-grid-4x4:after {
    content: 'grid_4x4';
    font-size: 1.2em;
}
i.icon.md-grid-goldenratio {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-grid-goldenratio:after {
    content: 'grid_goldenratio';
    font-size: 1.2em;
}
i.icon.md-grid-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-grid-off:after {
    content: 'grid_off';
    font-size: 1.2em;
}
i.icon.md-grid-on {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-grid-on:after {
    content: 'grid_on';
    font-size: 1.2em;
}
i.icon.md-grid-view {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-grid-view:after {
    content: 'grid_view';
    font-size: 1.2em;
}
i.icon.md-group {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-group:after {
    content: 'group';
    font-size: 1.2em;
}
i.icon.md-group-add {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-group-add:after {
    content: 'group_add';
    font-size: 1.2em;
}
i.icon.md-group-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-group-off:after {
    content: 'group_off';
    font-size: 1.2em;
}
i.icon.md-group-remove {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-group-remove:after {
    content: 'group_remove';
    font-size: 1.2em;
}
i.icon.md-group-work {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-group-work:after {
    content: 'group_work';
    font-size: 1.2em;
}
i.icon.md-groups {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-groups:after {
    content: 'groups';
    font-size: 1.2em;
}
i.icon.md-groups-2 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-groups-2:after {
    content: 'groups_2';
    font-size: 1.2em;
}
i.icon.md-groups-3 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-groups-3:after {
    content: 'groups_3';
    font-size: 1.2em;
}
i.icon.md-h-mobiledata {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-h-mobiledata:after {
    content: 'h_mobiledata';
    font-size: 1.2em;
}
i.icon.md-h-plus-mobiledata {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-h-plus-mobiledata:after {
    content: 'h_plus_mobiledata';
    font-size: 1.2em;
}
i.icon.md-hail {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-hail:after {
    content: 'hail';
    font-size: 1.2em;
}
i.icon.md-handshake {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-handshake:after {
    content: 'handshake';
    font-size: 1.2em;
}
i.icon.md-handyman {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-handyman:after {
    content: 'handyman';
    font-size: 1.2em;
}
i.icon.md-hardware {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-hardware:after {
    content: 'hardware';
    font-size: 1.2em;
}
i.icon.md-hd {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-hd:after {
    content: 'hd';
    font-size: 1.2em;
}
i.icon.md-hdr-auto {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-hdr-auto:after {
    content: 'hdr_auto';
    font-size: 1.2em;
}
i.icon.md-hdr-auto-select {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-hdr-auto-select:after {
    content: 'hdr_auto_select';
    font-size: 1.2em;
}
i.icon.md-hdr-enhanced-select {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-hdr-enhanced-select:after {
    content: 'hdr_enhanced_select';
    font-size: 1.2em;
}
i.icon.md-hdr-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-hdr-off:after {
    content: 'hdr_off';
    font-size: 1.2em;
}
i.icon.md-hdr-off-select {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-hdr-off-select:after {
    content: 'hdr_off_select';
    font-size: 1.2em;
}
i.icon.md-hdr-on {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-hdr-on:after {
    content: 'hdr_on';
    font-size: 1.2em;
}
i.icon.md-hdr-on-select {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-hdr-on-select:after {
    content: 'hdr_on_select';
    font-size: 1.2em;
}
i.icon.md-hdr-plus {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-hdr-plus:after {
    content: 'hdr_plus';
    font-size: 1.2em;
}
i.icon.md-hdr-strong {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-hdr-strong:after {
    content: 'hdr_strong';
    font-size: 1.2em;
}
i.icon.md-hdr-weak {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-hdr-weak:after {
    content: 'hdr_weak';
    font-size: 1.2em;
}
i.icon.md-headphones {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-headphones:after {
    content: 'headphones';
    font-size: 1.2em;
}
i.icon.md-headphones-battery {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-headphones-battery:after {
    content: 'headphones_battery';
    font-size: 1.2em;
}
i.icon.md-headset {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-headset:after {
    content: 'headset';
    font-size: 1.2em;
}
i.icon.md-headset-mic {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-headset-mic:after {
    content: 'headset_mic';
    font-size: 1.2em;
}
i.icon.md-headset-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-headset-off:after {
    content: 'headset_off';
    font-size: 1.2em;
}
i.icon.md-healing {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-healing:after {
    content: 'healing';
    font-size: 1.2em;
}
i.icon.md-health-and-safety {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-health-and-safety:after {
    content: 'health_and_safety';
    font-size: 1.2em;
}
i.icon.md-hearing {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-hearing:after {
    content: 'hearing';
    font-size: 1.2em;
}
i.icon.md-hearing-disabled {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-hearing-disabled:after {
    content: 'hearing_disabled';
    font-size: 1.2em;
}
i.icon.md-heart-broken {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-heart-broken:after {
    content: 'heart_broken';
    font-size: 1.2em;
}
i.icon.md-heat-pump {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-heat-pump:after {
    content: 'heat_pump';
    font-size: 1.2em;
}
i.icon.md-height {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-height:after {
    content: 'height';
    font-size: 1.2em;
}
i.icon.md-help {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-help:after {
    content: 'help';
    font-size: 1.2em;
}
i.icon.md-help-center {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-help-center:after {
    content: 'help_center';
    font-size: 1.2em;
}
i.icon.md-help-outline {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-help-outline:after {
    content: 'help_outline';
    font-size: 1.2em;
}
i.icon.md-hevc {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-hevc:after {
    content: 'hevc';
    font-size: 1.2em;
}
i.icon.md-hexagon {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-hexagon:after {
    content: 'hexagon';
    font-size: 1.2em;
}
i.icon.md-hide-image {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-hide-image:after {
    content: 'hide_image';
    font-size: 1.2em;
}
i.icon.md-hide-source {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-hide-source:after {
    content: 'hide_source';
    font-size: 1.2em;
}
i.icon.md-high-quality {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-high-quality:after {
    content: 'high_quality';
    font-size: 1.2em;
}
i.icon.md-highlight {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-highlight:after {
    content: 'highlight';
    font-size: 1.2em;
}
i.icon.md-highlight-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-highlight-alt:after {
    content: 'highlight_alt';
    font-size: 1.2em;
}
i.icon.md-highlight-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-highlight-off:after {
    content: 'highlight_off';
    font-size: 1.2em;
}
i.icon.md-hiking {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-hiking:after {
    content: 'hiking';
    font-size: 1.2em;
}
i.icon.md-history {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-history:after {
    content: 'history';
    font-size: 1.2em;
}
i.icon.md-history-edu {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-history-edu:after {
    content: 'history_edu';
    font-size: 1.2em;
}
i.icon.md-history-toggle-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-history-toggle-off:after {
    content: 'history_toggle_off';
    font-size: 1.2em;
}
i.icon.md-hive {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-hive:after {
    content: 'hive';
    font-size: 1.2em;
}
i.icon.md-hls {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-hls:after {
    content: 'hls';
    font-size: 1.2em;
}
i.icon.md-hls-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-hls-off:after {
    content: 'hls_off';
    font-size: 1.2em;
}
i.icon.md-holiday-village {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-holiday-village:after {
    content: 'holiday_village';
    font-size: 1.2em;
}
i.icon.md-home {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-home:after {
    content: 'home';
    font-size: 1.2em;
}
i.icon.md-home-max {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-home-max:after {
    content: 'home_max';
    font-size: 1.2em;
}
i.icon.md-home-mini {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-home-mini:after {
    content: 'home_mini';
    font-size: 1.2em;
}
i.icon.md-home-repair-service {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-home-repair-service:after {
    content: 'home_repair_service';
    font-size: 1.2em;
}
i.icon.md-home-work {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-home-work:after {
    content: 'home_work';
    font-size: 1.2em;
}
i.icon.md-horizontal-distribute {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-horizontal-distribute:after {
    content: 'horizontal_distribute';
    font-size: 1.2em;
}
i.icon.md-horizontal-rule {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-horizontal-rule:after {
    content: 'horizontal_rule';
    font-size: 1.2em;
}
i.icon.md-horizontal-split {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-horizontal-split:after {
    content: 'horizontal_split';
    font-size: 1.2em;
}
i.icon.md-hot-tub {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-hot-tub:after {
    content: 'hot_tub';
    font-size: 1.2em;
}
i.icon.md-hotel {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-hotel:after {
    content: 'hotel';
    font-size: 1.2em;
}
i.icon.md-hotel-class {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-hotel-class:after {
    content: 'hotel_class';
    font-size: 1.2em;
}
i.icon.md-hourglass-bottom {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-hourglass-bottom:after {
    content: 'hourglass_bottom';
    font-size: 1.2em;
}
i.icon.md-hourglass-disabled {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-hourglass-disabled:after {
    content: 'hourglass_disabled';
    font-size: 1.2em;
}
i.icon.md-hourglass-empty {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-hourglass-empty:after {
    content: 'hourglass_empty';
    font-size: 1.2em;
}
i.icon.md-hourglass-full {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-hourglass-full:after {
    content: 'hourglass_full';
    font-size: 1.2em;
}
i.icon.md-hourglass-top {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-hourglass-top:after {
    content: 'hourglass_top';
    font-size: 1.2em;
}
i.icon.md-house {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-house:after {
    content: 'house';
    font-size: 1.2em;
}
i.icon.md-house-siding {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-house-siding:after {
    content: 'house_siding';
    font-size: 1.2em;
}
i.icon.md-houseboat {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-houseboat:after {
    content: 'houseboat';
    font-size: 1.2em;
}
i.icon.md-how-to-reg {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-how-to-reg:after {
    content: 'how_to_reg';
    font-size: 1.2em;
}
i.icon.md-how-to-vote {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-how-to-vote:after {
    content: 'how_to_vote';
    font-size: 1.2em;
}
i.icon.md-html {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-html:after {
    content: 'html';
    font-size: 1.2em;
}
i.icon.md-http {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-http:after {
    content: 'http';
    font-size: 1.2em;
}
i.icon.md-https {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-https:after {
    content: 'https';
    font-size: 1.2em;
}
i.icon.md-hub {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-hub:after {
    content: 'hub';
    font-size: 1.2em;
}
i.icon.md-hvac {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-hvac:after {
    content: 'hvac';
    font-size: 1.2em;
}
i.icon.md-ice-skating {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-ice-skating:after {
    content: 'ice_skating';
    font-size: 1.2em;
}
i.icon.md-icecream {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-icecream:after {
    content: 'icecream';
    font-size: 1.2em;
}
i.icon.md-image {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-image:after {
    content: 'image';
    font-size: 1.2em;
}
i.icon.md-image-aspect-ratio {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-image-aspect-ratio:after {
    content: 'image_aspect_ratio';
    font-size: 1.2em;
}
i.icon.md-image-not-supported {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-image-not-supported:after {
    content: 'image_not_supported';
    font-size: 1.2em;
}
i.icon.md-image-search {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-image-search:after {
    content: 'image_search';
    font-size: 1.2em;
}
i.icon.md-imagesearch-roller {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-imagesearch-roller:after {
    content: 'imagesearch_roller';
    font-size: 1.2em;
}
i.icon.md-import-contacts {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-import-contacts:after {
    content: 'import_contacts';
    font-size: 1.2em;
}
i.icon.md-import-export {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-import-export:after {
    content: 'import_export';
    font-size: 1.2em;
}
i.icon.md-important-devices {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-important-devices:after {
    content: 'important_devices';
    font-size: 1.2em;
}
i.icon.md-inbox {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-inbox:after {
    content: 'inbox';
    font-size: 1.2em;
}
i.icon.md-incomplete-circle {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-incomplete-circle:after {
    content: 'incomplete_circle';
    font-size: 1.2em;
}
i.icon.md-indeterminate-check-box {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-indeterminate-check-box:after {
    content: 'indeterminate_check_box';
    font-size: 1.2em;
}
i.icon.md-info {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-info:after {
    content: 'info';
    font-size: 1.2em;
}
i.icon.md-input {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-input:after {
    content: 'input';
    font-size: 1.2em;
}
i.icon.md-insert-chart {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-insert-chart:after {
    content: 'insert_chart';
    font-size: 1.2em;
}
i.icon.md-insert-chart-outlined {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-insert-chart-outlined:after {
    content: 'insert_chart_outlined';
    font-size: 1.2em;
}
i.icon.md-insert-comment {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-insert-comment:after {
    content: 'insert_comment';
    font-size: 1.2em;
}
i.icon.md-insert-drive-file {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-insert-drive-file:after {
    content: 'insert_drive_file';
    font-size: 1.2em;
}
i.icon.md-insert-emoticon {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-insert-emoticon:after {
    content: 'insert_emoticon';
    font-size: 1.2em;
}
i.icon.md-insert-invitation {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-insert-invitation:after {
    content: 'insert_invitation';
    font-size: 1.2em;
}
i.icon.md-insert-link {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-insert-link:after {
    content: 'insert_link';
    font-size: 1.2em;
}
i.icon.md-insert-page-break {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-insert-page-break:after {
    content: 'insert_page_break';
    font-size: 1.2em;
}
i.icon.md-insert-photo {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-insert-photo:after {
    content: 'insert_photo';
    font-size: 1.2em;
}
i.icon.md-insights {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-insights:after {
    content: 'insights';
    font-size: 1.2em;
}
i.icon.md-install-desktop {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-install-desktop:after {
    content: 'install_desktop';
    font-size: 1.2em;
}
i.icon.md-install-mobile {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-install-mobile:after {
    content: 'install_mobile';
    font-size: 1.2em;
}
i.icon.md-integration-instructions {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-integration-instructions:after {
    content: 'integration_instructions';
    font-size: 1.2em;
}
i.icon.md-interests {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-interests:after {
    content: 'interests';
    font-size: 1.2em;
}
i.icon.md-interpreter-mode {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-interpreter-mode:after {
    content: 'interpreter_mode';
    font-size: 1.2em;
}
i.icon.md-inventory {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-inventory:after {
    content: 'inventory';
    font-size: 1.2em;
}
i.icon.md-inventory-2 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-inventory-2:after {
    content: 'inventory_2';
    font-size: 1.2em;
}
i.icon.md-invert-colors {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-invert-colors:after {
    content: 'invert_colors';
    font-size: 1.2em;
}
i.icon.md-invert-colors-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-invert-colors-off:after {
    content: 'invert_colors_off';
    font-size: 1.2em;
}
i.icon.md-ios-share {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-ios-share:after {
    content: 'ios_share';
    font-size: 1.2em;
}
i.icon.md-iron {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-iron:after {
    content: 'iron';
    font-size: 1.2em;
}
i.icon.md-iso {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-iso:after {
    content: 'iso';
    font-size: 1.2em;
}
i.icon.md-javascript {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-javascript:after {
    content: 'javascript';
    font-size: 1.2em;
}
i.icon.md-join-full {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-join-full:after {
    content: 'join_full';
    font-size: 1.2em;
}
i.icon.md-join-inner {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-join-inner:after {
    content: 'join_inner';
    font-size: 1.2em;
}
i.icon.md-join-left {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-join-left:after {
    content: 'join_left';
    font-size: 1.2em;
}
i.icon.md-join-right {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-join-right:after {
    content: 'join_right';
    font-size: 1.2em;
}
i.icon.md-kayaking {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-kayaking:after {
    content: 'kayaking';
    font-size: 1.2em;
}
i.icon.md-kebab-dining {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-kebab-dining:after {
    content: 'kebab_dining';
    font-size: 1.2em;
}
i.icon.md-key {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-key:after {
    content: 'key';
    font-size: 1.2em;
}
i.icon.md-key-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-key-off:after {
    content: 'key_off';
    font-size: 1.2em;
}
i.icon.md-keyboard {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-keyboard:after {
    content: 'keyboard';
    font-size: 1.2em;
}
i.icon.md-keyboard-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-keyboard-alt:after {
    content: 'keyboard_alt';
    font-size: 1.2em;
}
i.icon.md-keyboard-arrow-down {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-keyboard-arrow-down:after {
    content: 'keyboard_arrow_down';
    font-size: 1.2em;
}
i.icon.md-keyboard-arrow-left {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-keyboard-arrow-left:after {
    content: 'keyboard_arrow_left';
    font-size: 1.2em;
}
i.icon.md-keyboard-arrow-right {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-keyboard-arrow-right:after {
    content: 'keyboard_arrow_right';
    font-size: 1.2em;
}
i.icon.md-keyboard-arrow-up {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-keyboard-arrow-up:after {
    content: 'keyboard_arrow_up';
    font-size: 1.2em;
}
i.icon.md-keyboard-backspace {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-keyboard-backspace:after {
    content: 'keyboard_backspace';
    font-size: 1.2em;
}
i.icon.md-keyboard-capslock {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-keyboard-capslock:after {
    content: 'keyboard_capslock';
    font-size: 1.2em;
}
i.icon.md-keyboard-command-key {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-keyboard-command-key:after {
    content: 'keyboard_command_key';
    font-size: 1.2em;
}
i.icon.md-keyboard-control-key {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-keyboard-control-key:after {
    content: 'keyboard_control_key';
    font-size: 1.2em;
}
i.icon.md-keyboard-double-arrow-down {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-keyboard-double-arrow-down:after {
    content: 'keyboard_double_arrow_down';
    font-size: 1.2em;
}
i.icon.md-keyboard-double-arrow-left {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-keyboard-double-arrow-left:after {
    content: 'keyboard_double_arrow_left';
    font-size: 1.2em;
}
i.icon.md-keyboard-double-arrow-right {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-keyboard-double-arrow-right:after {
    content: 'keyboard_double_arrow_right';
    font-size: 1.2em;
}
i.icon.md-keyboard-double-arrow-up {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-keyboard-double-arrow-up:after {
    content: 'keyboard_double_arrow_up';
    font-size: 1.2em;
}
i.icon.md-keyboard-hide {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-keyboard-hide:after {
    content: 'keyboard_hide';
    font-size: 1.2em;
}
i.icon.md-keyboard-option-key {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-keyboard-option-key:after {
    content: 'keyboard_option_key';
    font-size: 1.2em;
}
i.icon.md-keyboard-return {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-keyboard-return:after {
    content: 'keyboard_return';
    font-size: 1.2em;
}
i.icon.md-keyboard-tab {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-keyboard-tab:after {
    content: 'keyboard_tab';
    font-size: 1.2em;
}
i.icon.md-keyboard-voice {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-keyboard-voice:after {
    content: 'keyboard_voice';
    font-size: 1.2em;
}
i.icon.md-king-bed {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-king-bed:after {
    content: 'king_bed';
    font-size: 1.2em;
}
i.icon.md-kitchen {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-kitchen:after {
    content: 'kitchen';
    font-size: 1.2em;
}
i.icon.md-kitesurfing {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-kitesurfing:after {
    content: 'kitesurfing';
    font-size: 1.2em;
}
i.icon.md-label {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-label:after {
    content: 'label';
    font-size: 1.2em;
}
i.icon.md-label-important {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-label-important:after {
    content: 'label_important';
    font-size: 1.2em;
}
i.icon.md-label-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-label-off:after {
    content: 'label_off';
    font-size: 1.2em;
}
i.icon.md-lan {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-lan:after {
    content: 'lan';
    font-size: 1.2em;
}
i.icon.md-landscape {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-landscape:after {
    content: 'landscape';
    font-size: 1.2em;
}
i.icon.md-landslide {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-landslide:after {
    content: 'landslide';
    font-size: 1.2em;
}
i.icon.md-language {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-language:after {
    content: 'language';
    font-size: 1.2em;
}
i.icon.md-laptop {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-laptop:after {
    content: 'laptop';
    font-size: 1.2em;
}
i.icon.md-laptop-chromebook {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-laptop-chromebook:after {
    content: 'laptop_chromebook';
    font-size: 1.2em;
}
i.icon.md-laptop-mac {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-laptop-mac:after {
    content: 'laptop_mac';
    font-size: 1.2em;
}
i.icon.md-laptop-windows {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-laptop-windows:after {
    content: 'laptop_windows';
    font-size: 1.2em;
}
i.icon.md-last-page {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-last-page:after {
    content: 'last_page';
    font-size: 1.2em;
}
i.icon.md-launch {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-launch:after {
    content: 'launch';
    font-size: 1.2em;
}
i.icon.md-layers {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-layers:after {
    content: 'layers';
    font-size: 1.2em;
}
i.icon.md-layers-clear {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-layers-clear:after {
    content: 'layers_clear';
    font-size: 1.2em;
}
i.icon.md-leaderboard {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-leaderboard:after {
    content: 'leaderboard';
    font-size: 1.2em;
}
i.icon.md-leak-add {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-leak-add:after {
    content: 'leak_add';
    font-size: 1.2em;
}
i.icon.md-leak-remove {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-leak-remove:after {
    content: 'leak_remove';
    font-size: 1.2em;
}
i.icon.md-legend-toggle {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-legend-toggle:after {
    content: 'legend_toggle';
    font-size: 1.2em;
}
i.icon.md-lens {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-lens:after {
    content: 'lens';
    font-size: 1.2em;
}
i.icon.md-lens-blur {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-lens-blur:after {
    content: 'lens_blur';
    font-size: 1.2em;
}
i.icon.md-library-add {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-library-add:after {
    content: 'library_add';
    font-size: 1.2em;
}
i.icon.md-library-add-check {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-library-add-check:after {
    content: 'library_add_check';
    font-size: 1.2em;
}
i.icon.md-library-books {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-library-books:after {
    content: 'library_books';
    font-size: 1.2em;
}
i.icon.md-library-music {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-library-music:after {
    content: 'library_music';
    font-size: 1.2em;
}
i.icon.md-light {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-light:after {
    content: 'light';
    font-size: 1.2em;
}
i.icon.md-light-mode {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-light-mode:after {
    content: 'light_mode';
    font-size: 1.2em;
}
i.icon.md-lightbulb {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-lightbulb:after {
    content: 'lightbulb';
    font-size: 1.2em;
}
i.icon.md-lightbulb-circle {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-lightbulb-circle:after {
    content: 'lightbulb_circle';
    font-size: 1.2em;
}
i.icon.md-line-axis {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-line-axis:after {
    content: 'line_axis';
    font-size: 1.2em;
}
i.icon.md-line-style {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-line-style:after {
    content: 'line_style';
    font-size: 1.2em;
}
i.icon.md-line-weight {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-line-weight:after {
    content: 'line_weight';
    font-size: 1.2em;
}
i.icon.md-linear-scale {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-linear-scale:after {
    content: 'linear_scale';
    font-size: 1.2em;
}
i.icon.md-link {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-link:after {
    content: 'link';
    font-size: 1.2em;
}
i.icon.md-link-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-link-off:after {
    content: 'link_off';
    font-size: 1.2em;
}
i.icon.md-linked-camera {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-linked-camera:after {
    content: 'linked_camera';
    font-size: 1.2em;
}
i.icon.md-liquor {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-liquor:after {
    content: 'liquor';
    font-size: 1.2em;
}
i.icon.md-list {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-list:after {
    content: 'list';
    font-size: 1.2em;
}
i.icon.md-list-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-list-alt:after {
    content: 'list_alt';
    font-size: 1.2em;
}
i.icon.md-live-help {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-live-help:after {
    content: 'live_help';
    font-size: 1.2em;
}
i.icon.md-live-tv {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-live-tv:after {
    content: 'live_tv';
    font-size: 1.2em;
}
i.icon.md-living {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-living:after {
    content: 'living';
    font-size: 1.2em;
}
i.icon.md-local-activity {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-local-activity:after {
    content: 'local_activity';
    font-size: 1.2em;
}
i.icon.md-local-airport {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-local-airport:after {
    content: 'local_airport';
    font-size: 1.2em;
}
i.icon.md-local-atm {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-local-atm:after {
    content: 'local_atm';
    font-size: 1.2em;
}
i.icon.md-local-bar {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-local-bar:after {
    content: 'local_bar';
    font-size: 1.2em;
}
i.icon.md-local-cafe {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-local-cafe:after {
    content: 'local_cafe';
    font-size: 1.2em;
}
i.icon.md-local-car-wash {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-local-car-wash:after {
    content: 'local_car_wash';
    font-size: 1.2em;
}
i.icon.md-local-convenience-store {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-local-convenience-store:after {
    content: 'local_convenience_store';
    font-size: 1.2em;
}
i.icon.md-local-dining {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-local-dining:after {
    content: 'local_dining';
    font-size: 1.2em;
}
i.icon.md-local-drink {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-local-drink:after {
    content: 'local_drink';
    font-size: 1.2em;
}
i.icon.md-local-fire-department {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-local-fire-department:after {
    content: 'local_fire_department';
    font-size: 1.2em;
}
i.icon.md-local-florist {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-local-florist:after {
    content: 'local_florist';
    font-size: 1.2em;
}
i.icon.md-local-gas-station {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-local-gas-station:after {
    content: 'local_gas_station';
    font-size: 1.2em;
}
i.icon.md-local-grocery-store {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-local-grocery-store:after {
    content: 'local_grocery_store';
    font-size: 1.2em;
}
i.icon.md-local-hospital {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-local-hospital:after {
    content: 'local_hospital';
    font-size: 1.2em;
}
i.icon.md-local-hotel {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-local-hotel:after {
    content: 'local_hotel';
    font-size: 1.2em;
}
i.icon.md-local-laundry-service {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-local-laundry-service:after {
    content: 'local_laundry_service';
    font-size: 1.2em;
}
i.icon.md-local-library {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-local-library:after {
    content: 'local_library';
    font-size: 1.2em;
}
i.icon.md-local-mall {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-local-mall:after {
    content: 'local_mall';
    font-size: 1.2em;
}
i.icon.md-local-movies {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-local-movies:after {
    content: 'local_movies';
    font-size: 1.2em;
}
i.icon.md-local-offer {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-local-offer:after {
    content: 'local_offer';
    font-size: 1.2em;
}
i.icon.md-local-parking {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-local-parking:after {
    content: 'local_parking';
    font-size: 1.2em;
}
i.icon.md-local-pharmacy {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-local-pharmacy:after {
    content: 'local_pharmacy';
    font-size: 1.2em;
}
i.icon.md-local-phone {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-local-phone:after {
    content: 'local_phone';
    font-size: 1.2em;
}
i.icon.md-local-pizza {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-local-pizza:after {
    content: 'local_pizza';
    font-size: 1.2em;
}
i.icon.md-local-play {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-local-play:after {
    content: 'local_play';
    font-size: 1.2em;
}
i.icon.md-local-police {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-local-police:after {
    content: 'local_police';
    font-size: 1.2em;
}
i.icon.md-local-post-office {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-local-post-office:after {
    content: 'local_post_office';
    font-size: 1.2em;
}
i.icon.md-local-printshop {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-local-printshop:after {
    content: 'local_printshop';
    font-size: 1.2em;
}
i.icon.md-local-see {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-local-see:after {
    content: 'local_see';
    font-size: 1.2em;
}
i.icon.md-local-shipping {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-local-shipping:after {
    content: 'local_shipping';
    font-size: 1.2em;
}
i.icon.md-local-taxi {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-local-taxi:after {
    content: 'local_taxi';
    font-size: 1.2em;
}
i.icon.md-location-city {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-location-city:after {
    content: 'location_city';
    font-size: 1.2em;
}
i.icon.md-location-disabled {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-location-disabled:after {
    content: 'location_disabled';
    font-size: 1.2em;
}
i.icon.md-location-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-location-off:after {
    content: 'location_off';
    font-size: 1.2em;
}
i.icon.md-location-on {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-location-on:after {
    content: 'location_on';
    font-size: 1.2em;
}
i.icon.md-location-searching {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-location-searching:after {
    content: 'location_searching';
    font-size: 1.2em;
}
i.icon.md-lock {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-lock:after {
    content: 'lock';
    font-size: 1.2em;
}
i.icon.md-lock-clock {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-lock-clock:after {
    content: 'lock_clock';
    font-size: 1.2em;
}
i.icon.md-lock-open {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-lock-open:after {
    content: 'lock_open';
    font-size: 1.2em;
}
i.icon.md-lock-person {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-lock-person:after {
    content: 'lock_person';
    font-size: 1.2em;
}
i.icon.md-lock-reset {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-lock-reset:after {
    content: 'lock_reset';
    font-size: 1.2em;
}
i.icon.md-login {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-login:after {
    content: 'login';
    font-size: 1.2em;
}
i.icon.md-logo-dev {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-logo-dev:after {
    content: 'logo_dev';
    font-size: 1.2em;
}
i.icon.md-logout {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-logout:after {
    content: 'logout';
    font-size: 1.2em;
}
i.icon.md-looks {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-looks:after {
    content: 'looks';
    font-size: 1.2em;
}
i.icon.md-looks-3 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-looks-3:after {
    content: 'looks_3';
    font-size: 1.2em;
}
i.icon.md-looks-4 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-looks-4:after {
    content: 'looks_4';
    font-size: 1.2em;
}
i.icon.md-looks-5 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-looks-5:after {
    content: 'looks_5';
    font-size: 1.2em;
}
i.icon.md-looks-6 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-looks-6:after {
    content: 'looks_6';
    font-size: 1.2em;
}
i.icon.md-looks-one {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-looks-one:after {
    content: 'looks_one';
    font-size: 1.2em;
}
i.icon.md-looks-two {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-looks-two:after {
    content: 'looks_two';
    font-size: 1.2em;
}
i.icon.md-loop {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-loop:after {
    content: 'loop';
    font-size: 1.2em;
}
i.icon.md-loupe {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-loupe:after {
    content: 'loupe';
    font-size: 1.2em;
}
i.icon.md-low-priority {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-low-priority:after {
    content: 'low_priority';
    font-size: 1.2em;
}
i.icon.md-loyalty {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-loyalty:after {
    content: 'loyalty';
    font-size: 1.2em;
}
i.icon.md-lte-mobiledata {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-lte-mobiledata:after {
    content: 'lte_mobiledata';
    font-size: 1.2em;
}
i.icon.md-lte-plus-mobiledata {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-lte-plus-mobiledata:after {
    content: 'lte_plus_mobiledata';
    font-size: 1.2em;
}
i.icon.md-luggage {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-luggage:after {
    content: 'luggage';
    font-size: 1.2em;
}
i.icon.md-lunch-dining {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-lunch-dining:after {
    content: 'lunch_dining';
    font-size: 1.2em;
}
i.icon.md-lyrics {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-lyrics:after {
    content: 'lyrics';
    font-size: 1.2em;
}
i.icon.md-macro-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-macro-off:after {
    content: 'macro_off';
    font-size: 1.2em;
}
i.icon.md-mail {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-mail:after {
    content: 'mail';
    font-size: 1.2em;
}
i.icon.md-mail-lock {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-mail-lock:after {
    content: 'mail_lock';
    font-size: 1.2em;
}
i.icon.md-mail-outline {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-mail-outline:after {
    content: 'mail_outline';
    font-size: 1.2em;
}
i.icon.md-male {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-male:after {
    content: 'male';
    font-size: 1.2em;
}
i.icon.md-man {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-man:after {
    content: 'man';
    font-size: 1.2em;
}
i.icon.md-man-2 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-man-2:after {
    content: 'man_2';
    font-size: 1.2em;
}
i.icon.md-man-3 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-man-3:after {
    content: 'man_3';
    font-size: 1.2em;
}
i.icon.md-man-4 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-man-4:after {
    content: 'man_4';
    font-size: 1.2em;
}
i.icon.md-manage-accounts {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-manage-accounts:after {
    content: 'manage_accounts';
    font-size: 1.2em;
}
i.icon.md-manage-history {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-manage-history:after {
    content: 'manage_history';
    font-size: 1.2em;
}
i.icon.md-manage-search {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-manage-search:after {
    content: 'manage_search';
    font-size: 1.2em;
}
i.icon.md-map {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-map:after {
    content: 'map';
    font-size: 1.2em;
}
i.icon.md-maps-home-work {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-maps-home-work:after {
    content: 'maps_home_work';
    font-size: 1.2em;
}
i.icon.md-maps-ugc {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-maps-ugc:after {
    content: 'maps_ugc';
    font-size: 1.2em;
}
i.icon.md-margin {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-margin:after {
    content: 'margin';
    font-size: 1.2em;
}
i.icon.md-mark-as-unread {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-mark-as-unread:after {
    content: 'mark_as_unread';
    font-size: 1.2em;
}
i.icon.md-mark-chat-read {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-mark-chat-read:after {
    content: 'mark_chat_read';
    font-size: 1.2em;
}
i.icon.md-mark-chat-unread {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-mark-chat-unread:after {
    content: 'mark_chat_unread';
    font-size: 1.2em;
}
i.icon.md-mark-email-read {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-mark-email-read:after {
    content: 'mark_email_read';
    font-size: 1.2em;
}
i.icon.md-mark-email-unread {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-mark-email-unread:after {
    content: 'mark_email_unread';
    font-size: 1.2em;
}
i.icon.md-mark-unread-chat-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-mark-unread-chat-alt:after {
    content: 'mark_unread_chat_alt';
    font-size: 1.2em;
}
i.icon.md-markunread {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-markunread:after {
    content: 'markunread';
    font-size: 1.2em;
}
i.icon.md-markunread-mailbox {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-markunread-mailbox:after {
    content: 'markunread_mailbox';
    font-size: 1.2em;
}
i.icon.md-masks {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-masks:after {
    content: 'masks';
    font-size: 1.2em;
}
i.icon.md-maximize {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-maximize:after {
    content: 'maximize';
    font-size: 1.2em;
}
i.icon.md-media-bluetooth-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-media-bluetooth-off:after {
    content: 'media_bluetooth_off';
    font-size: 1.2em;
}
i.icon.md-media-bluetooth-on {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-media-bluetooth-on:after {
    content: 'media_bluetooth_on';
    font-size: 1.2em;
}
i.icon.md-mediation {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-mediation:after {
    content: 'mediation';
    font-size: 1.2em;
}
i.icon.md-medical-information {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-medical-information:after {
    content: 'medical_information';
    font-size: 1.2em;
}
i.icon.md-medical-services {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-medical-services:after {
    content: 'medical_services';
    font-size: 1.2em;
}
i.icon.md-medication {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-medication:after {
    content: 'medication';
    font-size: 1.2em;
}
i.icon.md-medication-liquid {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-medication-liquid:after {
    content: 'medication_liquid';
    font-size: 1.2em;
}
i.icon.md-meeting-room {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-meeting-room:after {
    content: 'meeting_room';
    font-size: 1.2em;
}
i.icon.md-memory {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-memory:after {
    content: 'memory';
    font-size: 1.2em;
}
i.icon.md-menu {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-menu:after {
    content: 'menu';
    font-size: 1.2em;
}
i.icon.md-menu-book {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-menu-book:after {
    content: 'menu_book';
    font-size: 1.2em;
}
i.icon.md-menu-open {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-menu-open:after {
    content: 'menu_open';
    font-size: 1.2em;
}
i.icon.md-merge {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-merge:after {
    content: 'merge';
    font-size: 1.2em;
}
i.icon.md-merge-type {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-merge-type:after {
    content: 'merge_type';
    font-size: 1.2em;
}
i.icon.md-message {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-message:after {
    content: 'message';
    font-size: 1.2em;
}
i.icon.md-mic {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-mic:after {
    content: 'mic';
    font-size: 1.2em;
}
i.icon.md-mic-external-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-mic-external-off:after {
    content: 'mic_external_off';
    font-size: 1.2em;
}
i.icon.md-mic-external-on {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-mic-external-on:after {
    content: 'mic_external_on';
    font-size: 1.2em;
}
i.icon.md-mic-none {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-mic-none:after {
    content: 'mic_none';
    font-size: 1.2em;
}
i.icon.md-mic-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-mic-off:after {
    content: 'mic_off';
    font-size: 1.2em;
}
i.icon.md-microwave {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-microwave:after {
    content: 'microwave';
    font-size: 1.2em;
}
i.icon.md-military-tech {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-military-tech:after {
    content: 'military_tech';
    font-size: 1.2em;
}
i.icon.md-minimize {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-minimize:after {
    content: 'minimize';
    font-size: 1.2em;
}
i.icon.md-minor-crash {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-minor-crash:after {
    content: 'minor_crash';
    font-size: 1.2em;
}
i.icon.md-miscellaneous-services {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-miscellaneous-services:after {
    content: 'miscellaneous_services';
    font-size: 1.2em;
}
i.icon.md-missed-video-call {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-missed-video-call:after {
    content: 'missed_video_call';
    font-size: 1.2em;
}
i.icon.md-mms {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-mms:after {
    content: 'mms';
    font-size: 1.2em;
}
i.icon.md-mobile-friendly {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-mobile-friendly:after {
    content: 'mobile_friendly';
    font-size: 1.2em;
}
i.icon.md-mobile-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-mobile-off:after {
    content: 'mobile_off';
    font-size: 1.2em;
}
i.icon.md-mobile-screen-share {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-mobile-screen-share:after {
    content: 'mobile_screen_share';
    font-size: 1.2em;
}
i.icon.md-mobiledata-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-mobiledata-off:after {
    content: 'mobiledata_off';
    font-size: 1.2em;
}
i.icon.md-mode {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-mode:after {
    content: 'mode';
    font-size: 1.2em;
}
i.icon.md-mode-comment {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-mode-comment:after {
    content: 'mode_comment';
    font-size: 1.2em;
}
i.icon.md-mode-edit {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-mode-edit:after {
    content: 'mode_edit';
    font-size: 1.2em;
}
i.icon.md-mode-edit-outline {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-mode-edit-outline:after {
    content: 'mode_edit_outline';
    font-size: 1.2em;
}
i.icon.md-mode-fan-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-mode-fan-off:after {
    content: 'mode_fan_off';
    font-size: 1.2em;
}
i.icon.md-mode-night {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-mode-night:after {
    content: 'mode_night';
    font-size: 1.2em;
}
i.icon.md-mode-of-travel {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-mode-of-travel:after {
    content: 'mode_of_travel';
    font-size: 1.2em;
}
i.icon.md-mode-standby {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-mode-standby:after {
    content: 'mode_standby';
    font-size: 1.2em;
}
i.icon.md-model-training {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-model-training:after {
    content: 'model_training';
    font-size: 1.2em;
}
i.icon.md-monetization-on {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-monetization-on:after {
    content: 'monetization_on';
    font-size: 1.2em;
}
i.icon.md-money {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-money:after {
    content: 'money';
    font-size: 1.2em;
}
i.icon.md-money-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-money-off:after {
    content: 'money_off';
    font-size: 1.2em;
}
i.icon.md-money-off-csred {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-money-off-csred:after {
    content: 'money_off_csred';
    font-size: 1.2em;
}
i.icon.md-monitor {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-monitor:after {
    content: 'monitor';
    font-size: 1.2em;
}
i.icon.md-monitor-heart {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-monitor-heart:after {
    content: 'monitor_heart';
    font-size: 1.2em;
}
i.icon.md-monitor-weight {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-monitor-weight:after {
    content: 'monitor_weight';
    font-size: 1.2em;
}
i.icon.md-monochrome-photos {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-monochrome-photos:after {
    content: 'monochrome_photos';
    font-size: 1.2em;
}
i.icon.md-mood {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-mood:after {
    content: 'mood';
    font-size: 1.2em;
}
i.icon.md-mood-bad {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-mood-bad:after {
    content: 'mood_bad';
    font-size: 1.2em;
}
i.icon.md-moped {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-moped:after {
    content: 'moped';
    font-size: 1.2em;
}
i.icon.md-more {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-more:after {
    content: 'more';
    font-size: 1.2em;
}
i.icon.md-more-horiz {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-more-horiz:after {
    content: 'more_horiz';
    font-size: 1.2em;
}
i.icon.md-more-time {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-more-time:after {
    content: 'more_time';
    font-size: 1.2em;
}
i.icon.md-more-vert {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-more-vert:after {
    content: 'more_vert';
    font-size: 1.2em;
}
i.icon.md-mosque {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-mosque:after {
    content: 'mosque';
    font-size: 1.2em;
}
i.icon.md-motion-photos-auto {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-motion-photos-auto:after {
    content: 'motion_photos_auto';
    font-size: 1.2em;
}
i.icon.md-motion-photos-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-motion-photos-off:after {
    content: 'motion_photos_off';
    font-size: 1.2em;
}
i.icon.md-motion-photos-on {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-motion-photos-on:after {
    content: 'motion_photos_on';
    font-size: 1.2em;
}
i.icon.md-motion-photos-pause {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-motion-photos-pause:after {
    content: 'motion_photos_pause';
    font-size: 1.2em;
}
i.icon.md-motion-photos-paused {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-motion-photos-paused:after {
    content: 'motion_photos_paused';
    font-size: 1.2em;
}
i.icon.md-mouse {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-mouse:after {
    content: 'mouse';
    font-size: 1.2em;
}
i.icon.md-move-down {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-move-down:after {
    content: 'move_down';
    font-size: 1.2em;
}
i.icon.md-move-to-inbox {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-move-to-inbox:after {
    content: 'move_to_inbox';
    font-size: 1.2em;
}
i.icon.md-move-up {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-move-up:after {
    content: 'move_up';
    font-size: 1.2em;
}
i.icon.md-movie {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-movie:after {
    content: 'movie';
    font-size: 1.2em;
}
i.icon.md-movie-creation {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-movie-creation:after {
    content: 'movie_creation';
    font-size: 1.2em;
}
i.icon.md-movie-filter {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-movie-filter:after {
    content: 'movie_filter';
    font-size: 1.2em;
}
i.icon.md-moving {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-moving:after {
    content: 'moving';
    font-size: 1.2em;
}
i.icon.md-mp {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-mp:after {
    content: 'mp';
    font-size: 1.2em;
}
i.icon.md-multiline-chart {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-multiline-chart:after {
    content: 'multiline_chart';
    font-size: 1.2em;
}
i.icon.md-multiple-stop {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-multiple-stop:after {
    content: 'multiple_stop';
    font-size: 1.2em;
}
i.icon.md-museum {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-museum:after {
    content: 'museum';
    font-size: 1.2em;
}
i.icon.md-music-note {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-music-note:after {
    content: 'music_note';
    font-size: 1.2em;
}
i.icon.md-music-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-music-off:after {
    content: 'music_off';
    font-size: 1.2em;
}
i.icon.md-music-video {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-music-video:after {
    content: 'music_video';
    font-size: 1.2em;
}
i.icon.md-my-location {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-my-location:after {
    content: 'my_location';
    font-size: 1.2em;
}
i.icon.md-nat {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-nat:after {
    content: 'nat';
    font-size: 1.2em;
}
i.icon.md-nature {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-nature:after {
    content: 'nature';
    font-size: 1.2em;
}
i.icon.md-nature-people {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-nature-people:after {
    content: 'nature_people';
    font-size: 1.2em;
}
i.icon.md-navigate-before {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-navigate-before:after {
    content: 'navigate_before';
    font-size: 1.2em;
}
i.icon.md-navigate-next {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-navigate-next:after {
    content: 'navigate_next';
    font-size: 1.2em;
}
i.icon.md-navigation {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-navigation:after {
    content: 'navigation';
    font-size: 1.2em;
}
i.icon.md-near-me {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-near-me:after {
    content: 'near_me';
    font-size: 1.2em;
}
i.icon.md-near-me-disabled {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-near-me-disabled:after {
    content: 'near_me_disabled';
    font-size: 1.2em;
}
i.icon.md-nearby-error {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-nearby-error:after {
    content: 'nearby_error';
    font-size: 1.2em;
}
i.icon.md-nearby-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-nearby-off:after {
    content: 'nearby_off';
    font-size: 1.2em;
}
i.icon.md-nest-cam-wired-stand {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-nest-cam-wired-stand:after {
    content: 'nest_cam_wired_stand';
    font-size: 1.2em;
}
i.icon.md-network-cell {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-network-cell:after {
    content: 'network_cell';
    font-size: 1.2em;
}
i.icon.md-network-check {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-network-check:after {
    content: 'network_check';
    font-size: 1.2em;
}
i.icon.md-network-locked {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-network-locked:after {
    content: 'network_locked';
    font-size: 1.2em;
}
i.icon.md-network-ping {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-network-ping:after {
    content: 'network_ping';
    font-size: 1.2em;
}
i.icon.md-network-wifi {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-network-wifi:after {
    content: 'network_wifi';
    font-size: 1.2em;
}
i.icon.md-network-wifi-1-bar {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-network-wifi-1-bar:after {
    content: 'network_wifi_1_bar';
    font-size: 1.2em;
}
i.icon.md-network-wifi-2-bar {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-network-wifi-2-bar:after {
    content: 'network_wifi_2_bar';
    font-size: 1.2em;
}
i.icon.md-network-wifi-3-bar {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-network-wifi-3-bar:after {
    content: 'network_wifi_3_bar';
    font-size: 1.2em;
}
i.icon.md-new-label {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-new-label:after {
    content: 'new_label';
    font-size: 1.2em;
}
i.icon.md-new-releases {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-new-releases:after {
    content: 'new_releases';
    font-size: 1.2em;
}
i.icon.md-newspaper {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-newspaper:after {
    content: 'newspaper';
    font-size: 1.2em;
}
i.icon.md-next-plan {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-next-plan:after {
    content: 'next_plan';
    font-size: 1.2em;
}
i.icon.md-next-week {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-next-week:after {
    content: 'next_week';
    font-size: 1.2em;
}
i.icon.md-nfc {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-nfc:after {
    content: 'nfc';
    font-size: 1.2em;
}
i.icon.md-night-shelter {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-night-shelter:after {
    content: 'night_shelter';
    font-size: 1.2em;
}
i.icon.md-nightlife {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-nightlife:after {
    content: 'nightlife';
    font-size: 1.2em;
}
i.icon.md-nightlight {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-nightlight:after {
    content: 'nightlight';
    font-size: 1.2em;
}
i.icon.md-nightlight-round {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-nightlight-round:after {
    content: 'nightlight_round';
    font-size: 1.2em;
}
i.icon.md-nights-stay {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-nights-stay:after {
    content: 'nights_stay';
    font-size: 1.2em;
}
i.icon.md-no-accounts {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-no-accounts:after {
    content: 'no_accounts';
    font-size: 1.2em;
}
i.icon.md-no-adult-content {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-no-adult-content:after {
    content: 'no_adult_content';
    font-size: 1.2em;
}
i.icon.md-no-backpack {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-no-backpack:after {
    content: 'no_backpack';
    font-size: 1.2em;
}
i.icon.md-no-cell {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-no-cell:after {
    content: 'no_cell';
    font-size: 1.2em;
}
i.icon.md-no-crash {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-no-crash:after {
    content: 'no_crash';
    font-size: 1.2em;
}
i.icon.md-no-drinks {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-no-drinks:after {
    content: 'no_drinks';
    font-size: 1.2em;
}
i.icon.md-no-encryption {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-no-encryption:after {
    content: 'no_encryption';
    font-size: 1.2em;
}
i.icon.md-no-encryption-gmailerrorred {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-no-encryption-gmailerrorred:after {
    content: 'no_encryption_gmailerrorred';
    font-size: 1.2em;
}
i.icon.md-no-flash {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-no-flash:after {
    content: 'no_flash';
    font-size: 1.2em;
}
i.icon.md-no-food {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-no-food:after {
    content: 'no_food';
    font-size: 1.2em;
}
i.icon.md-no-luggage {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-no-luggage:after {
    content: 'no_luggage';
    font-size: 1.2em;
}
i.icon.md-no-meals {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-no-meals:after {
    content: 'no_meals';
    font-size: 1.2em;
}
i.icon.md-no-meeting-room {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-no-meeting-room:after {
    content: 'no_meeting_room';
    font-size: 1.2em;
}
i.icon.md-no-photography {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-no-photography:after {
    content: 'no_photography';
    font-size: 1.2em;
}
i.icon.md-no-sim {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-no-sim:after {
    content: 'no_sim';
    font-size: 1.2em;
}
i.icon.md-no-stroller {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-no-stroller:after {
    content: 'no_stroller';
    font-size: 1.2em;
}
i.icon.md-no-transfer {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-no-transfer:after {
    content: 'no_transfer';
    font-size: 1.2em;
}
i.icon.md-noise-aware {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-noise-aware:after {
    content: 'noise_aware';
    font-size: 1.2em;
}
i.icon.md-noise-control-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-noise-control-off:after {
    content: 'noise_control_off';
    font-size: 1.2em;
}
i.icon.md-nordic-walking {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-nordic-walking:after {
    content: 'nordic_walking';
    font-size: 1.2em;
}
i.icon.md-north {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-north:after {
    content: 'north';
    font-size: 1.2em;
}
i.icon.md-north-east {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-north-east:after {
    content: 'north_east';
    font-size: 1.2em;
}
i.icon.md-north-west {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-north-west:after {
    content: 'north_west';
    font-size: 1.2em;
}
i.icon.md-not-accessible {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-not-accessible:after {
    content: 'not_accessible';
    font-size: 1.2em;
}
i.icon.md-not-interested {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-not-interested:after {
    content: 'not_interested';
    font-size: 1.2em;
}
i.icon.md-not-listed-location {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-not-listed-location:after {
    content: 'not_listed_location';
    font-size: 1.2em;
}
i.icon.md-not-started {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-not-started:after {
    content: 'not_started';
    font-size: 1.2em;
}
i.icon.md-note {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-note:after {
    content: 'note';
    font-size: 1.2em;
}
i.icon.md-note-add {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-note-add:after {
    content: 'note_add';
    font-size: 1.2em;
}
i.icon.md-note-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-note-alt:after {
    content: 'note_alt';
    font-size: 1.2em;
}
i.icon.md-notes {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-notes:after {
    content: 'notes';
    font-size: 1.2em;
}
i.icon.md-notification-add {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-notification-add:after {
    content: 'notification_add';
    font-size: 1.2em;
}
i.icon.md-notification-important {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-notification-important:after {
    content: 'notification_important';
    font-size: 1.2em;
}
i.icon.md-notifications {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-notifications:after {
    content: 'notifications';
    font-size: 1.2em;
}
i.icon.md-notifications-active {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-notifications-active:after {
    content: 'notifications_active';
    font-size: 1.2em;
}
i.icon.md-notifications-none {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-notifications-none:after {
    content: 'notifications_none';
    font-size: 1.2em;
}
i.icon.md-notifications-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-notifications-off:after {
    content: 'notifications_off';
    font-size: 1.2em;
}
i.icon.md-notifications-paused {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-notifications-paused:after {
    content: 'notifications_paused';
    font-size: 1.2em;
}
i.icon.md-numbers {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-numbers:after {
    content: 'numbers';
    font-size: 1.2em;
}
i.icon.md-offline-bolt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-offline-bolt:after {
    content: 'offline_bolt';
    font-size: 1.2em;
}
i.icon.md-offline-pin {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-offline-pin:after {
    content: 'offline_pin';
    font-size: 1.2em;
}
i.icon.md-offline-share {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-offline-share:after {
    content: 'offline_share';
    font-size: 1.2em;
}
i.icon.md-oil-barrel {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-oil-barrel:after {
    content: 'oil_barrel';
    font-size: 1.2em;
}
i.icon.md-on-device-training {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-on-device-training:after {
    content: 'on_device_training';
    font-size: 1.2em;
}
i.icon.md-ondemand-video {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-ondemand-video:after {
    content: 'ondemand_video';
    font-size: 1.2em;
}
i.icon.md-online-prediction {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-online-prediction:after {
    content: 'online_prediction';
    font-size: 1.2em;
}
i.icon.md-opacity {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-opacity:after {
    content: 'opacity';
    font-size: 1.2em;
}
i.icon.md-open-in-browser {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-open-in-browser:after {
    content: 'open_in_browser';
    font-size: 1.2em;
}
i.icon.md-open-in-full {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-open-in-full:after {
    content: 'open_in_full';
    font-size: 1.2em;
}
i.icon.md-open-in-new {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-open-in-new:after {
    content: 'open_in_new';
    font-size: 1.2em;
}
i.icon.md-open-in-new-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-open-in-new-off:after {
    content: 'open_in_new_off';
    font-size: 1.2em;
}
i.icon.md-open-with {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-open-with:after {
    content: 'open_with';
    font-size: 1.2em;
}
i.icon.md-other-houses {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-other-houses:after {
    content: 'other_houses';
    font-size: 1.2em;
}
i.icon.md-outbound {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-outbound:after {
    content: 'outbound';
    font-size: 1.2em;
}
i.icon.md-outbox {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-outbox:after {
    content: 'outbox';
    font-size: 1.2em;
}
i.icon.md-outdoor-grill {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-outdoor-grill:after {
    content: 'outdoor_grill';
    font-size: 1.2em;
}
i.icon.md-outlet {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-outlet:after {
    content: 'outlet';
    font-size: 1.2em;
}
i.icon.md-outlined-flag {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-outlined-flag:after {
    content: 'outlined_flag';
    font-size: 1.2em;
}
i.icon.md-output {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-output:after {
    content: 'output';
    font-size: 1.2em;
}
i.icon.md-padding {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-padding:after {
    content: 'padding';
    font-size: 1.2em;
}
i.icon.md-pages {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-pages:after {
    content: 'pages';
    font-size: 1.2em;
}
i.icon.md-pageview {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-pageview:after {
    content: 'pageview';
    font-size: 1.2em;
}
i.icon.md-paid {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-paid:after {
    content: 'paid';
    font-size: 1.2em;
}
i.icon.md-palette {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-palette:after {
    content: 'palette';
    font-size: 1.2em;
}
i.icon.md-pan-tool {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-pan-tool:after {
    content: 'pan_tool';
    font-size: 1.2em;
}
i.icon.md-pan-tool-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-pan-tool-alt:after {
    content: 'pan_tool_alt';
    font-size: 1.2em;
}
i.icon.md-panorama {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-panorama:after {
    content: 'panorama';
    font-size: 1.2em;
}
i.icon.md-panorama-fish-eye {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-panorama-fish-eye:after {
    content: 'panorama_fish_eye';
    font-size: 1.2em;
}
i.icon.md-panorama-horizontal {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-panorama-horizontal:after {
    content: 'panorama_horizontal';
    font-size: 1.2em;
}
i.icon.md-panorama-horizontal-select {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-panorama-horizontal-select:after {
    content: 'panorama_horizontal_select';
    font-size: 1.2em;
}
i.icon.md-panorama-photosphere {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-panorama-photosphere:after {
    content: 'panorama_photosphere';
    font-size: 1.2em;
}
i.icon.md-panorama-photosphere-select {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-panorama-photosphere-select:after {
    content: 'panorama_photosphere_select';
    font-size: 1.2em;
}
i.icon.md-panorama-vertical {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-panorama-vertical:after {
    content: 'panorama_vertical';
    font-size: 1.2em;
}
i.icon.md-panorama-vertical-select {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-panorama-vertical-select:after {
    content: 'panorama_vertical_select';
    font-size: 1.2em;
}
i.icon.md-panorama-wide-angle {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-panorama-wide-angle:after {
    content: 'panorama_wide_angle';
    font-size: 1.2em;
}
i.icon.md-panorama-wide-angle-select {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-panorama-wide-angle-select:after {
    content: 'panorama_wide_angle_select';
    font-size: 1.2em;
}
i.icon.md-paragliding {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-paragliding:after {
    content: 'paragliding';
    font-size: 1.2em;
}
i.icon.md-park {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-park:after {
    content: 'park';
    font-size: 1.2em;
}
i.icon.md-party-mode {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-party-mode:after {
    content: 'party_mode';
    font-size: 1.2em;
}
i.icon.md-password {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-password:after {
    content: 'password';
    font-size: 1.2em;
}
i.icon.md-pattern {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-pattern:after {
    content: 'pattern';
    font-size: 1.2em;
}
i.icon.md-pause {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-pause:after {
    content: 'pause';
    font-size: 1.2em;
}
i.icon.md-pause-circle {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-pause-circle:after {
    content: 'pause_circle';
    font-size: 1.2em;
}
i.icon.md-pause-circle-filled {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-pause-circle-filled:after {
    content: 'pause_circle_filled';
    font-size: 1.2em;
}
i.icon.md-pause-circle-outline {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-pause-circle-outline:after {
    content: 'pause_circle_outline';
    font-size: 1.2em;
}
i.icon.md-pause-presentation {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-pause-presentation:after {
    content: 'pause_presentation';
    font-size: 1.2em;
}
i.icon.md-payment {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-payment:after {
    content: 'payment';
    font-size: 1.2em;
}
i.icon.md-payments {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-payments:after {
    content: 'payments';
    font-size: 1.2em;
}
i.icon.md-pedal-bike {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-pedal-bike:after {
    content: 'pedal_bike';
    font-size: 1.2em;
}
i.icon.md-pending {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-pending:after {
    content: 'pending';
    font-size: 1.2em;
}
i.icon.md-pending-actions {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-pending-actions:after {
    content: 'pending_actions';
    font-size: 1.2em;
}
i.icon.md-pentagon {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-pentagon:after {
    content: 'pentagon';
    font-size: 1.2em;
}
i.icon.md-people {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-people:after {
    content: 'people';
    font-size: 1.2em;
}
i.icon.md-people-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-people-alt:after {
    content: 'people_alt';
    font-size: 1.2em;
}
i.icon.md-people-outline {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-people-outline:after {
    content: 'people_outline';
    font-size: 1.2em;
}
i.icon.md-percent {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-percent:after {
    content: 'percent';
    font-size: 1.2em;
}
i.icon.md-perm-camera-mic {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-perm-camera-mic:after {
    content: 'perm_camera_mic';
    font-size: 1.2em;
}
i.icon.md-perm-contact-calendar {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-perm-contact-calendar:after {
    content: 'perm_contact_calendar';
    font-size: 1.2em;
}
i.icon.md-perm-data-setting {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-perm-data-setting:after {
    content: 'perm_data_setting';
    font-size: 1.2em;
}
i.icon.md-perm-device-information {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-perm-device-information:after {
    content: 'perm_device_information';
    font-size: 1.2em;
}
i.icon.md-perm-identity {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-perm-identity:after {
    content: 'perm_identity';
    font-size: 1.2em;
}
i.icon.md-perm-media {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-perm-media:after {
    content: 'perm_media';
    font-size: 1.2em;
}
i.icon.md-perm-phone-msg {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-perm-phone-msg:after {
    content: 'perm_phone_msg';
    font-size: 1.2em;
}
i.icon.md-perm-scan-wifi {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-perm-scan-wifi:after {
    content: 'perm_scan_wifi';
    font-size: 1.2em;
}
i.icon.md-person {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-person:after {
    content: 'person';
    font-size: 1.2em;
}
i.icon.md-person-2 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-person-2:after {
    content: 'person_2';
    font-size: 1.2em;
}
i.icon.md-person-3 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-person-3:after {
    content: 'person_3';
    font-size: 1.2em;
}
i.icon.md-person-4 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-person-4:after {
    content: 'person_4';
    font-size: 1.2em;
}
i.icon.md-person-add {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-person-add:after {
    content: 'person_add';
    font-size: 1.2em;
}
i.icon.md-person-add-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-person-add-alt:after {
    content: 'person_add_alt';
    font-size: 1.2em;
}
i.icon.md-person-add-alt-1 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-person-add-alt-1:after {
    content: 'person_add_alt_1';
    font-size: 1.2em;
}
i.icon.md-person-add-disabled {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-person-add-disabled:after {
    content: 'person_add_disabled';
    font-size: 1.2em;
}
i.icon.md-person-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-person-off:after {
    content: 'person_off';
    font-size: 1.2em;
}
i.icon.md-person-outline {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-person-outline:after {
    content: 'person_outline';
    font-size: 1.2em;
}
i.icon.md-person-pin {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-person-pin:after {
    content: 'person_pin';
    font-size: 1.2em;
}
i.icon.md-person-pin-circle {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-person-pin-circle:after {
    content: 'person_pin_circle';
    font-size: 1.2em;
}
i.icon.md-person-remove {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-person-remove:after {
    content: 'person_remove';
    font-size: 1.2em;
}
i.icon.md-person-remove-alt-1 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-person-remove-alt-1:after {
    content: 'person_remove_alt_1';
    font-size: 1.2em;
}
i.icon.md-person-search {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-person-search:after {
    content: 'person_search';
    font-size: 1.2em;
}
i.icon.md-personal-injury {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-personal-injury:after {
    content: 'personal_injury';
    font-size: 1.2em;
}
i.icon.md-personal-video {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-personal-video:after {
    content: 'personal_video';
    font-size: 1.2em;
}
i.icon.md-pest-control {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-pest-control:after {
    content: 'pest_control';
    font-size: 1.2em;
}
i.icon.md-pest-control-rodent {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-pest-control-rodent:after {
    content: 'pest_control_rodent';
    font-size: 1.2em;
}
i.icon.md-pets {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-pets:after {
    content: 'pets';
    font-size: 1.2em;
}
i.icon.md-phishing {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-phishing:after {
    content: 'phishing';
    font-size: 1.2em;
}
i.icon.md-phone {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-phone:after {
    content: 'phone';
    font-size: 1.2em;
}
i.icon.md-phone-android {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-phone-android:after {
    content: 'phone_android';
    font-size: 1.2em;
}
i.icon.md-phone-bluetooth-speaker {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-phone-bluetooth-speaker:after {
    content: 'phone_bluetooth_speaker';
    font-size: 1.2em;
}
i.icon.md-phone-callback {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-phone-callback:after {
    content: 'phone_callback';
    font-size: 1.2em;
}
i.icon.md-phone-disabled {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-phone-disabled:after {
    content: 'phone_disabled';
    font-size: 1.2em;
}
i.icon.md-phone-enabled {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-phone-enabled:after {
    content: 'phone_enabled';
    font-size: 1.2em;
}
i.icon.md-phone-forwarded {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-phone-forwarded:after {
    content: 'phone_forwarded';
    font-size: 1.2em;
}
i.icon.md-phone-iphone {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-phone-iphone:after {
    content: 'phone_iphone';
    font-size: 1.2em;
}
i.icon.md-phone-locked {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-phone-locked:after {
    content: 'phone_locked';
    font-size: 1.2em;
}
i.icon.md-phone-missed {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-phone-missed:after {
    content: 'phone_missed';
    font-size: 1.2em;
}
i.icon.md-phone-paused {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-phone-paused:after {
    content: 'phone_paused';
    font-size: 1.2em;
}
i.icon.md-phonelink {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-phonelink:after {
    content: 'phonelink';
    font-size: 1.2em;
}
i.icon.md-phonelink-erase {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-phonelink-erase:after {
    content: 'phonelink_erase';
    font-size: 1.2em;
}
i.icon.md-phonelink-lock {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-phonelink-lock:after {
    content: 'phonelink_lock';
    font-size: 1.2em;
}
i.icon.md-phonelink-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-phonelink-off:after {
    content: 'phonelink_off';
    font-size: 1.2em;
}
i.icon.md-phonelink-ring {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-phonelink-ring:after {
    content: 'phonelink_ring';
    font-size: 1.2em;
}
i.icon.md-phonelink-setup {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-phonelink-setup:after {
    content: 'phonelink_setup';
    font-size: 1.2em;
}
i.icon.md-photo {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-photo:after {
    content: 'photo';
    font-size: 1.2em;
}
i.icon.md-photo-album {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-photo-album:after {
    content: 'photo_album';
    font-size: 1.2em;
}
i.icon.md-photo-camera {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-photo-camera:after {
    content: 'photo_camera';
    font-size: 1.2em;
}
i.icon.md-photo-camera-back {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-photo-camera-back:after {
    content: 'photo_camera_back';
    font-size: 1.2em;
}
i.icon.md-photo-camera-front {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-photo-camera-front:after {
    content: 'photo_camera_front';
    font-size: 1.2em;
}
i.icon.md-photo-filter {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-photo-filter:after {
    content: 'photo_filter';
    font-size: 1.2em;
}
i.icon.md-photo-library {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-photo-library:after {
    content: 'photo_library';
    font-size: 1.2em;
}
i.icon.md-photo-size-select-actual {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-photo-size-select-actual:after {
    content: 'photo_size_select_actual';
    font-size: 1.2em;
}
i.icon.md-photo-size-select-large {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-photo-size-select-large:after {
    content: 'photo_size_select_large';
    font-size: 1.2em;
}
i.icon.md-photo-size-select-small {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-photo-size-select-small:after {
    content: 'photo_size_select_small';
    font-size: 1.2em;
}
i.icon.md-php {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-php:after {
    content: 'php';
    font-size: 1.2em;
}
i.icon.md-piano {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-piano:after {
    content: 'piano';
    font-size: 1.2em;
}
i.icon.md-piano-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-piano-off:after {
    content: 'piano_off';
    font-size: 1.2em;
}
i.icon.md-picture-as-pdf {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-picture-as-pdf:after {
    content: 'picture_as_pdf';
    font-size: 1.2em;
}
i.icon.md-picture-in-picture {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-picture-in-picture:after {
    content: 'picture_in_picture';
    font-size: 1.2em;
}
i.icon.md-picture-in-picture-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-picture-in-picture-alt:after {
    content: 'picture_in_picture_alt';
    font-size: 1.2em;
}
i.icon.md-pie-chart {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-pie-chart:after {
    content: 'pie_chart';
    font-size: 1.2em;
}
i.icon.md-pie-chart-outline {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-pie-chart-outline:after {
    content: 'pie_chart_outline';
    font-size: 1.2em;
}
i.icon.md-pin {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-pin:after {
    content: 'pin';
    font-size: 1.2em;
}
i.icon.md-pin-drop {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-pin-drop:after {
    content: 'pin_drop';
    font-size: 1.2em;
}
i.icon.md-pin-end {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-pin-end:after {
    content: 'pin_end';
    font-size: 1.2em;
}
i.icon.md-pin-invoke {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-pin-invoke:after {
    content: 'pin_invoke';
    font-size: 1.2em;
}
i.icon.md-pinch {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-pinch:after {
    content: 'pinch';
    font-size: 1.2em;
}
i.icon.md-pivot-table-chart {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-pivot-table-chart:after {
    content: 'pivot_table_chart';
    font-size: 1.2em;
}
i.icon.md-pix {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-pix:after {
    content: 'pix';
    font-size: 1.2em;
}
i.icon.md-place {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-place:after {
    content: 'place';
    font-size: 1.2em;
}
i.icon.md-plagiarism {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-plagiarism:after {
    content: 'plagiarism';
    font-size: 1.2em;
}
i.icon.md-play-arrow {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-play-arrow:after {
    content: 'play_arrow';
    font-size: 1.2em;
}
i.icon.md-play-circle {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-play-circle:after {
    content: 'play_circle';
    font-size: 1.2em;
}
i.icon.md-play-circle-filled {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-play-circle-filled:after {
    content: 'play_circle_filled';
    font-size: 1.2em;
}
i.icon.md-play-circle-outline {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-play-circle-outline:after {
    content: 'play_circle_outline';
    font-size: 1.2em;
}
i.icon.md-play-disabled {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-play-disabled:after {
    content: 'play_disabled';
    font-size: 1.2em;
}
i.icon.md-play-for-work {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-play-for-work:after {
    content: 'play_for_work';
    font-size: 1.2em;
}
i.icon.md-play-lesson {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-play-lesson:after {
    content: 'play_lesson';
    font-size: 1.2em;
}
i.icon.md-playlist-add {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-playlist-add:after {
    content: 'playlist_add';
    font-size: 1.2em;
}
i.icon.md-playlist-add-check {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-playlist-add-check:after {
    content: 'playlist_add_check';
    font-size: 1.2em;
}
i.icon.md-playlist-add-check-circle {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-playlist-add-check-circle:after {
    content: 'playlist_add_check_circle';
    font-size: 1.2em;
}
i.icon.md-playlist-add-circle {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-playlist-add-circle:after {
    content: 'playlist_add_circle';
    font-size: 1.2em;
}
i.icon.md-playlist-play {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-playlist-play:after {
    content: 'playlist_play';
    font-size: 1.2em;
}
i.icon.md-playlist-remove {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-playlist-remove:after {
    content: 'playlist_remove';
    font-size: 1.2em;
}
i.icon.md-plumbing {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-plumbing:after {
    content: 'plumbing';
    font-size: 1.2em;
}
i.icon.md-plus-one {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-plus-one:after {
    content: 'plus_one';
    font-size: 1.2em;
}
i.icon.md-podcasts {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-podcasts:after {
    content: 'podcasts';
    font-size: 1.2em;
}
i.icon.md-point-of-sale {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-point-of-sale:after {
    content: 'point_of_sale';
    font-size: 1.2em;
}
i.icon.md-policy {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-policy:after {
    content: 'policy';
    font-size: 1.2em;
}
i.icon.md-poll {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-poll:after {
    content: 'poll';
    font-size: 1.2em;
}
i.icon.md-polyline {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-polyline:after {
    content: 'polyline';
    font-size: 1.2em;
}
i.icon.md-polymer {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-polymer:after {
    content: 'polymer';
    font-size: 1.2em;
}
i.icon.md-pool {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-pool:after {
    content: 'pool';
    font-size: 1.2em;
}
i.icon.md-portable-wifi-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-portable-wifi-off:after {
    content: 'portable_wifi_off';
    font-size: 1.2em;
}
i.icon.md-portrait {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-portrait:after {
    content: 'portrait';
    font-size: 1.2em;
}
i.icon.md-post-add {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-post-add:after {
    content: 'post_add';
    font-size: 1.2em;
}
i.icon.md-power {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-power:after {
    content: 'power';
    font-size: 1.2em;
}
i.icon.md-power-input {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-power-input:after {
    content: 'power_input';
    font-size: 1.2em;
}
i.icon.md-power-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-power-off:after {
    content: 'power_off';
    font-size: 1.2em;
}
i.icon.md-power-settings-new {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-power-settings-new:after {
    content: 'power_settings_new';
    font-size: 1.2em;
}
i.icon.md-precision-manufacturing {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-precision-manufacturing:after {
    content: 'precision_manufacturing';
    font-size: 1.2em;
}
i.icon.md-pregnant-woman {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-pregnant-woman:after {
    content: 'pregnant_woman';
    font-size: 1.2em;
}
i.icon.md-present-to-all {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-present-to-all:after {
    content: 'present_to_all';
    font-size: 1.2em;
}
i.icon.md-preview {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-preview:after {
    content: 'preview';
    font-size: 1.2em;
}
i.icon.md-price-change {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-price-change:after {
    content: 'price_change';
    font-size: 1.2em;
}
i.icon.md-price-check {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-price-check:after {
    content: 'price_check';
    font-size: 1.2em;
}
i.icon.md-print {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-print:after {
    content: 'print';
    font-size: 1.2em;
}
i.icon.md-print-disabled {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-print-disabled:after {
    content: 'print_disabled';
    font-size: 1.2em;
}
i.icon.md-priority-high {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-priority-high:after {
    content: 'priority_high';
    font-size: 1.2em;
}
i.icon.md-privacy-tip {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-privacy-tip:after {
    content: 'privacy_tip';
    font-size: 1.2em;
}
i.icon.md-private-connectivity {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-private-connectivity:after {
    content: 'private_connectivity';
    font-size: 1.2em;
}
i.icon.md-production-quantity-limits {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-production-quantity-limits:after {
    content: 'production_quantity_limits';
    font-size: 1.2em;
}
i.icon.md-propane {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-propane:after {
    content: 'propane';
    font-size: 1.2em;
}
i.icon.md-propane-tank {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-propane-tank:after {
    content: 'propane_tank';
    font-size: 1.2em;
}
i.icon.md-psychology {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-psychology:after {
    content: 'psychology';
    font-size: 1.2em;
}
i.icon.md-psychology-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-psychology-alt:after {
    content: 'psychology_alt';
    font-size: 1.2em;
}
i.icon.md-public {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-public:after {
    content: 'public';
    font-size: 1.2em;
}
i.icon.md-public-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-public-off:after {
    content: 'public_off';
    font-size: 1.2em;
}
i.icon.md-publish {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-publish:after {
    content: 'publish';
    font-size: 1.2em;
}
i.icon.md-published-with-changes {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-published-with-changes:after {
    content: 'published_with_changes';
    font-size: 1.2em;
}
i.icon.md-punch-clock {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-punch-clock:after {
    content: 'punch_clock';
    font-size: 1.2em;
}
i.icon.md-push-pin {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-push-pin:after {
    content: 'push_pin';
    font-size: 1.2em;
}
i.icon.md-qr-code {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-qr-code:after {
    content: 'qr_code';
    font-size: 1.2em;
}
i.icon.md-qr-code-2 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-qr-code-2:after {
    content: 'qr_code_2';
    font-size: 1.2em;
}
i.icon.md-qr-code-scanner {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-qr-code-scanner:after {
    content: 'qr_code_scanner';
    font-size: 1.2em;
}
i.icon.md-query-builder {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-query-builder:after {
    content: 'query_builder';
    font-size: 1.2em;
}
i.icon.md-query-stats {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-query-stats:after {
    content: 'query_stats';
    font-size: 1.2em;
}
i.icon.md-question-answer {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-question-answer:after {
    content: 'question_answer';
    font-size: 1.2em;
}
i.icon.md-question-mark {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-question-mark:after {
    content: 'question_mark';
    font-size: 1.2em;
}
i.icon.md-queue {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-queue:after {
    content: 'queue';
    font-size: 1.2em;
}
i.icon.md-queue-music {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-queue-music:after {
    content: 'queue_music';
    font-size: 1.2em;
}
i.icon.md-queue-play-next {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-queue-play-next:after {
    content: 'queue_play_next';
    font-size: 1.2em;
}
i.icon.md-quickreply {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-quickreply:after {
    content: 'quickreply';
    font-size: 1.2em;
}
i.icon.md-quiz {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-quiz:after {
    content: 'quiz';
    font-size: 1.2em;
}
i.icon.md-r-mobiledata {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-r-mobiledata:after {
    content: 'r_mobiledata';
    font-size: 1.2em;
}
i.icon.md-radar {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-radar:after {
    content: 'radar';
    font-size: 1.2em;
}
i.icon.md-radio {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-radio:after {
    content: 'radio';
    font-size: 1.2em;
}
i.icon.md-radio-button-checked {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-radio-button-checked:after {
    content: 'radio_button_checked';
    font-size: 1.2em;
}
i.icon.md-radio-button-unchecked {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-radio-button-unchecked:after {
    content: 'radio_button_unchecked';
    font-size: 1.2em;
}
i.icon.md-railway-alert {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-railway-alert:after {
    content: 'railway_alert';
    font-size: 1.2em;
}
i.icon.md-ramen-dining {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-ramen-dining:after {
    content: 'ramen_dining';
    font-size: 1.2em;
}
i.icon.md-ramp-left {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-ramp-left:after {
    content: 'ramp_left';
    font-size: 1.2em;
}
i.icon.md-ramp-right {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-ramp-right:after {
    content: 'ramp_right';
    font-size: 1.2em;
}
i.icon.md-rate-review {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-rate-review:after {
    content: 'rate_review';
    font-size: 1.2em;
}
i.icon.md-raw-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-raw-off:after {
    content: 'raw_off';
    font-size: 1.2em;
}
i.icon.md-raw-on {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-raw-on:after {
    content: 'raw_on';
    font-size: 1.2em;
}
i.icon.md-read-more {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-read-more:after {
    content: 'read_more';
    font-size: 1.2em;
}
i.icon.md-real-estate-agent {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-real-estate-agent:after {
    content: 'real_estate_agent';
    font-size: 1.2em;
}
i.icon.md-receipt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-receipt:after {
    content: 'receipt';
    font-size: 1.2em;
}
i.icon.md-receipt-long {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-receipt-long:after {
    content: 'receipt_long';
    font-size: 1.2em;
}
i.icon.md-recent-actors {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-recent-actors:after {
    content: 'recent_actors';
    font-size: 1.2em;
}
i.icon.md-recommend {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-recommend:after {
    content: 'recommend';
    font-size: 1.2em;
}
i.icon.md-record-voice-over {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-record-voice-over:after {
    content: 'record_voice_over';
    font-size: 1.2em;
}
i.icon.md-rectangle {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-rectangle:after {
    content: 'rectangle';
    font-size: 1.2em;
}
i.icon.md-recycling {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-recycling:after {
    content: 'recycling';
    font-size: 1.2em;
}
i.icon.md-redeem {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-redeem:after {
    content: 'redeem';
    font-size: 1.2em;
}
i.icon.md-redo {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-redo:after {
    content: 'redo';
    font-size: 1.2em;
}
i.icon.md-reduce-capacity {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-reduce-capacity:after {
    content: 'reduce_capacity';
    font-size: 1.2em;
}
i.icon.md-refresh {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-refresh:after {
    content: 'refresh';
    font-size: 1.2em;
}
i.icon.md-remember-me {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-remember-me:after {
    content: 'remember_me';
    font-size: 1.2em;
}
i.icon.md-remove {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-remove:after {
    content: 'remove';
    font-size: 1.2em;
}
i.icon.md-remove-circle {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-remove-circle:after {
    content: 'remove_circle';
    font-size: 1.2em;
}
i.icon.md-remove-circle-outline {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-remove-circle-outline:after {
    content: 'remove_circle_outline';
    font-size: 1.2em;
}
i.icon.md-remove-done {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-remove-done:after {
    content: 'remove_done';
    font-size: 1.2em;
}
i.icon.md-remove-from-queue {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-remove-from-queue:after {
    content: 'remove_from_queue';
    font-size: 1.2em;
}
i.icon.md-remove-moderator {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-remove-moderator:after {
    content: 'remove_moderator';
    font-size: 1.2em;
}
i.icon.md-remove-red-eye {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-remove-red-eye:after {
    content: 'remove_red_eye';
    font-size: 1.2em;
}
i.icon.md-remove-road {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-remove-road:after {
    content: 'remove_road';
    font-size: 1.2em;
}
i.icon.md-remove-shopping-cart {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-remove-shopping-cart:after {
    content: 'remove_shopping_cart';
    font-size: 1.2em;
}
i.icon.md-reorder {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-reorder:after {
    content: 'reorder';
    font-size: 1.2em;
}
i.icon.md-repartition {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-repartition:after {
    content: 'repartition';
    font-size: 1.2em;
}
i.icon.md-repeat {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-repeat:after {
    content: 'repeat';
    font-size: 1.2em;
}
i.icon.md-repeat-on {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-repeat-on:after {
    content: 'repeat_on';
    font-size: 1.2em;
}
i.icon.md-repeat-one {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-repeat-one:after {
    content: 'repeat_one';
    font-size: 1.2em;
}
i.icon.md-repeat-one-on {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-repeat-one-on:after {
    content: 'repeat_one_on';
    font-size: 1.2em;
}
i.icon.md-replay {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-replay:after {
    content: 'replay';
    font-size: 1.2em;
}
i.icon.md-replay-10 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-replay-10:after {
    content: 'replay_10';
    font-size: 1.2em;
}
i.icon.md-replay-30 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-replay-30:after {
    content: 'replay_30';
    font-size: 1.2em;
}
i.icon.md-replay-5 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-replay-5:after {
    content: 'replay_5';
    font-size: 1.2em;
}
i.icon.md-replay-circle-filled {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-replay-circle-filled:after {
    content: 'replay_circle_filled';
    font-size: 1.2em;
}
i.icon.md-reply {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-reply:after {
    content: 'reply';
    font-size: 1.2em;
}
i.icon.md-reply-all {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-reply-all:after {
    content: 'reply_all';
    font-size: 1.2em;
}
i.icon.md-report {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-report:after {
    content: 'report';
    font-size: 1.2em;
}
i.icon.md-report-gmailerrorred {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-report-gmailerrorred:after {
    content: 'report_gmailerrorred';
    font-size: 1.2em;
}
i.icon.md-report-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-report-off:after {
    content: 'report_off';
    font-size: 1.2em;
}
i.icon.md-report-problem {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-report-problem:after {
    content: 'report_problem';
    font-size: 1.2em;
}
i.icon.md-request-page {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-request-page:after {
    content: 'request_page';
    font-size: 1.2em;
}
i.icon.md-request-quote {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-request-quote:after {
    content: 'request_quote';
    font-size: 1.2em;
}
i.icon.md-reset-tv {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-reset-tv:after {
    content: 'reset_tv';
    font-size: 1.2em;
}
i.icon.md-restart-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-restart-alt:after {
    content: 'restart_alt';
    font-size: 1.2em;
}
i.icon.md-restaurant {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-restaurant:after {
    content: 'restaurant';
    font-size: 1.2em;
}
i.icon.md-restaurant-menu {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-restaurant-menu:after {
    content: 'restaurant_menu';
    font-size: 1.2em;
}
i.icon.md-restore {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-restore:after {
    content: 'restore';
    font-size: 1.2em;
}
i.icon.md-restore-from-trash {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-restore-from-trash:after {
    content: 'restore_from_trash';
    font-size: 1.2em;
}
i.icon.md-restore-page {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-restore-page:after {
    content: 'restore_page';
    font-size: 1.2em;
}
i.icon.md-reviews {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-reviews:after {
    content: 'reviews';
    font-size: 1.2em;
}
i.icon.md-rice-bowl {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-rice-bowl:after {
    content: 'rice_bowl';
    font-size: 1.2em;
}
i.icon.md-ring-volume {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-ring-volume:after {
    content: 'ring_volume';
    font-size: 1.2em;
}
i.icon.md-rocket {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-rocket:after {
    content: 'rocket';
    font-size: 1.2em;
}
i.icon.md-rocket-launch {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-rocket-launch:after {
    content: 'rocket_launch';
    font-size: 1.2em;
}
i.icon.md-roller-shades {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-roller-shades:after {
    content: 'roller_shades';
    font-size: 1.2em;
}
i.icon.md-roller-shades-closed {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-roller-shades-closed:after {
    content: 'roller_shades_closed';
    font-size: 1.2em;
}
i.icon.md-roller-skating {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-roller-skating:after {
    content: 'roller_skating';
    font-size: 1.2em;
}
i.icon.md-roofing {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-roofing:after {
    content: 'roofing';
    font-size: 1.2em;
}
i.icon.md-room {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-room:after {
    content: 'room';
    font-size: 1.2em;
}
i.icon.md-room-preferences {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-room-preferences:after {
    content: 'room_preferences';
    font-size: 1.2em;
}
i.icon.md-room-service {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-room-service:after {
    content: 'room_service';
    font-size: 1.2em;
}
i.icon.md-rotate-90-degrees-ccw {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-rotate-90-degrees-ccw:after {
    content: 'rotate_90_degrees_ccw';
    font-size: 1.2em;
}
i.icon.md-rotate-90-degrees-cw {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-rotate-90-degrees-cw:after {
    content: 'rotate_90_degrees_cw';
    font-size: 1.2em;
}
i.icon.md-rotate-left {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-rotate-left:after {
    content: 'rotate_left';
    font-size: 1.2em;
}
i.icon.md-rotate-right {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-rotate-right:after {
    content: 'rotate_right';
    font-size: 1.2em;
}
i.icon.md-roundabout-left {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-roundabout-left:after {
    content: 'roundabout_left';
    font-size: 1.2em;
}
i.icon.md-roundabout-right {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-roundabout-right:after {
    content: 'roundabout_right';
    font-size: 1.2em;
}
i.icon.md-rounded-corner {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-rounded-corner:after {
    content: 'rounded_corner';
    font-size: 1.2em;
}
i.icon.md-route {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-route:after {
    content: 'route';
    font-size: 1.2em;
}
i.icon.md-router {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-router:after {
    content: 'router';
    font-size: 1.2em;
}
i.icon.md-rowing {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-rowing:after {
    content: 'rowing';
    font-size: 1.2em;
}
i.icon.md-rss-feed {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-rss-feed:after {
    content: 'rss_feed';
    font-size: 1.2em;
}
i.icon.md-rsvp {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-rsvp:after {
    content: 'rsvp';
    font-size: 1.2em;
}
i.icon.md-rtt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-rtt:after {
    content: 'rtt';
    font-size: 1.2em;
}
i.icon.md-rule {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-rule:after {
    content: 'rule';
    font-size: 1.2em;
}
i.icon.md-rule-folder {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-rule-folder:after {
    content: 'rule_folder';
    font-size: 1.2em;
}
i.icon.md-run-circle {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-run-circle:after {
    content: 'run_circle';
    font-size: 1.2em;
}
i.icon.md-running-with-errors {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-running-with-errors:after {
    content: 'running_with_errors';
    font-size: 1.2em;
}
i.icon.md-rv-hookup {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-rv-hookup:after {
    content: 'rv_hookup';
    font-size: 1.2em;
}
i.icon.md-safety-check {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-safety-check:after {
    content: 'safety_check';
    font-size: 1.2em;
}
i.icon.md-safety-divider {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-safety-divider:after {
    content: 'safety_divider';
    font-size: 1.2em;
}
i.icon.md-sailing {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sailing:after {
    content: 'sailing';
    font-size: 1.2em;
}
i.icon.md-sanitizer {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sanitizer:after {
    content: 'sanitizer';
    font-size: 1.2em;
}
i.icon.md-satellite {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-satellite:after {
    content: 'satellite';
    font-size: 1.2em;
}
i.icon.md-satellite-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-satellite-alt:after {
    content: 'satellite_alt';
    font-size: 1.2em;
}
i.icon.md-save {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-save:after {
    content: 'save';
    font-size: 1.2em;
}
i.icon.md-save-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-save-alt:after {
    content: 'save_alt';
    font-size: 1.2em;
}
i.icon.md-save-as {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-save-as:after {
    content: 'save_as';
    font-size: 1.2em;
}
i.icon.md-saved-search {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-saved-search:after {
    content: 'saved_search';
    font-size: 1.2em;
}
i.icon.md-savings {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-savings:after {
    content: 'savings';
    font-size: 1.2em;
}
i.icon.md-scale {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-scale:after {
    content: 'scale';
    font-size: 1.2em;
}
i.icon.md-scanner {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-scanner:after {
    content: 'scanner';
    font-size: 1.2em;
}
i.icon.md-scatter-plot {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-scatter-plot:after {
    content: 'scatter_plot';
    font-size: 1.2em;
}
i.icon.md-schedule {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-schedule:after {
    content: 'schedule';
    font-size: 1.2em;
}
i.icon.md-schedule-send {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-schedule-send:after {
    content: 'schedule_send';
    font-size: 1.2em;
}
i.icon.md-schema {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-schema:after {
    content: 'schema';
    font-size: 1.2em;
}
i.icon.md-school {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-school:after {
    content: 'school';
    font-size: 1.2em;
}
i.icon.md-science {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-science:after {
    content: 'science';
    font-size: 1.2em;
}
i.icon.md-score {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-score:after {
    content: 'score';
    font-size: 1.2em;
}
i.icon.md-scoreboard {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-scoreboard:after {
    content: 'scoreboard';
    font-size: 1.2em;
}
i.icon.md-screen-lock-landscape {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-screen-lock-landscape:after {
    content: 'screen_lock_landscape';
    font-size: 1.2em;
}
i.icon.md-screen-lock-portrait {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-screen-lock-portrait:after {
    content: 'screen_lock_portrait';
    font-size: 1.2em;
}
i.icon.md-screen-lock-rotation {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-screen-lock-rotation:after {
    content: 'screen_lock_rotation';
    font-size: 1.2em;
}
i.icon.md-screen-rotation {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-screen-rotation:after {
    content: 'screen_rotation';
    font-size: 1.2em;
}
i.icon.md-screen-rotation-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-screen-rotation-alt:after {
    content: 'screen_rotation_alt';
    font-size: 1.2em;
}
i.icon.md-screen-search-desktop {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-screen-search-desktop:after {
    content: 'screen_search_desktop';
    font-size: 1.2em;
}
i.icon.md-screen-share {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-screen-share:after {
    content: 'screen_share';
    font-size: 1.2em;
}
i.icon.md-screenshot {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-screenshot:after {
    content: 'screenshot';
    font-size: 1.2em;
}
i.icon.md-screenshot-monitor {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-screenshot-monitor:after {
    content: 'screenshot_monitor';
    font-size: 1.2em;
}
i.icon.md-scuba-diving {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-scuba-diving:after {
    content: 'scuba_diving';
    font-size: 1.2em;
}
i.icon.md-sd {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sd:after {
    content: 'sd';
    font-size: 1.2em;
}
i.icon.md-sd-card {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sd-card:after {
    content: 'sd_card';
    font-size: 1.2em;
}
i.icon.md-sd-card-alert {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sd-card-alert:after {
    content: 'sd_card_alert';
    font-size: 1.2em;
}
i.icon.md-sd-storage {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sd-storage:after {
    content: 'sd_storage';
    font-size: 1.2em;
}
i.icon.md-search {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-search:after {
    content: 'search';
    font-size: 1.2em;
}
i.icon.md-search-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-search-off:after {
    content: 'search_off';
    font-size: 1.2em;
}
i.icon.md-security {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-security:after {
    content: 'security';
    font-size: 1.2em;
}
i.icon.md-security-update {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-security-update:after {
    content: 'security_update';
    font-size: 1.2em;
}
i.icon.md-security-update-good {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-security-update-good:after {
    content: 'security_update_good';
    font-size: 1.2em;
}
i.icon.md-security-update-warning {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-security-update-warning:after {
    content: 'security_update_warning';
    font-size: 1.2em;
}
i.icon.md-segment {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-segment:after {
    content: 'segment';
    font-size: 1.2em;
}
i.icon.md-select-all {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-select-all:after {
    content: 'select_all';
    font-size: 1.2em;
}
i.icon.md-self-improvement {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-self-improvement:after {
    content: 'self_improvement';
    font-size: 1.2em;
}
i.icon.md-sell {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sell:after {
    content: 'sell';
    font-size: 1.2em;
}
i.icon.md-send {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-send:after {
    content: 'send';
    font-size: 1.2em;
}
i.icon.md-send-and-archive {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-send-and-archive:after {
    content: 'send_and_archive';
    font-size: 1.2em;
}
i.icon.md-send-time-extension {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-send-time-extension:after {
    content: 'send_time_extension';
    font-size: 1.2em;
}
i.icon.md-send-to-mobile {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-send-to-mobile:after {
    content: 'send_to_mobile';
    font-size: 1.2em;
}
i.icon.md-sensor-door {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sensor-door:after {
    content: 'sensor_door';
    font-size: 1.2em;
}
i.icon.md-sensor-occupied {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sensor-occupied:after {
    content: 'sensor_occupied';
    font-size: 1.2em;
}
i.icon.md-sensor-window {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sensor-window:after {
    content: 'sensor_window';
    font-size: 1.2em;
}
i.icon.md-sensors {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sensors:after {
    content: 'sensors';
    font-size: 1.2em;
}
i.icon.md-sensors-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sensors-off:after {
    content: 'sensors_off';
    font-size: 1.2em;
}
i.icon.md-sentiment-dissatisfied {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sentiment-dissatisfied:after {
    content: 'sentiment_dissatisfied';
    font-size: 1.2em;
}
i.icon.md-sentiment-neutral {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sentiment-neutral:after {
    content: 'sentiment_neutral';
    font-size: 1.2em;
}
i.icon.md-sentiment-satisfied {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sentiment-satisfied:after {
    content: 'sentiment_satisfied';
    font-size: 1.2em;
}
i.icon.md-sentiment-satisfied-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sentiment-satisfied-alt:after {
    content: 'sentiment_satisfied_alt';
    font-size: 1.2em;
}
i.icon.md-sentiment-very-dissatisfied {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sentiment-very-dissatisfied:after {
    content: 'sentiment_very_dissatisfied';
    font-size: 1.2em;
}
i.icon.md-sentiment-very-satisfied {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sentiment-very-satisfied:after {
    content: 'sentiment_very_satisfied';
    font-size: 1.2em;
}
i.icon.md-set-meal {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-set-meal:after {
    content: 'set_meal';
    font-size: 1.2em;
}
i.icon.md-settings {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-settings:after {
    content: 'settings';
    font-size: 1.2em;
}
i.icon.md-settings-accessibility {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-settings-accessibility:after {
    content: 'settings_accessibility';
    font-size: 1.2em;
}
i.icon.md-settings-applications {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-settings-applications:after {
    content: 'settings_applications';
    font-size: 1.2em;
}
i.icon.md-settings-backup-restore {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-settings-backup-restore:after {
    content: 'settings_backup_restore';
    font-size: 1.2em;
}
i.icon.md-settings-bluetooth {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-settings-bluetooth:after {
    content: 'settings_bluetooth';
    font-size: 1.2em;
}
i.icon.md-settings-brightness {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-settings-brightness:after {
    content: 'settings_brightness';
    font-size: 1.2em;
}
i.icon.md-settings-cell {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-settings-cell:after {
    content: 'settings_cell';
    font-size: 1.2em;
}
i.icon.md-settings-ethernet {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-settings-ethernet:after {
    content: 'settings_ethernet';
    font-size: 1.2em;
}
i.icon.md-settings-input-antenna {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-settings-input-antenna:after {
    content: 'settings_input_antenna';
    font-size: 1.2em;
}
i.icon.md-settings-input-component {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-settings-input-component:after {
    content: 'settings_input_component';
    font-size: 1.2em;
}
i.icon.md-settings-input-composite {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-settings-input-composite:after {
    content: 'settings_input_composite';
    font-size: 1.2em;
}
i.icon.md-settings-input-hdmi {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-settings-input-hdmi:after {
    content: 'settings_input_hdmi';
    font-size: 1.2em;
}
i.icon.md-settings-input-svideo {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-settings-input-svideo:after {
    content: 'settings_input_svideo';
    font-size: 1.2em;
}
i.icon.md-settings-overscan {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-settings-overscan:after {
    content: 'settings_overscan';
    font-size: 1.2em;
}
i.icon.md-settings-phone {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-settings-phone:after {
    content: 'settings_phone';
    font-size: 1.2em;
}
i.icon.md-settings-power {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-settings-power:after {
    content: 'settings_power';
    font-size: 1.2em;
}
i.icon.md-settings-remote {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-settings-remote:after {
    content: 'settings_remote';
    font-size: 1.2em;
}
i.icon.md-settings-suggest {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-settings-suggest:after {
    content: 'settings_suggest';
    font-size: 1.2em;
}
i.icon.md-settings-system-daydream {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-settings-system-daydream:after {
    content: 'settings_system_daydream';
    font-size: 1.2em;
}
i.icon.md-settings-voice {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-settings-voice:after {
    content: 'settings_voice';
    font-size: 1.2em;
}
i.icon.md-severe-cold {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-severe-cold:after {
    content: 'severe_cold';
    font-size: 1.2em;
}
i.icon.md-shape-line {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-shape-line:after {
    content: 'shape_line';
    font-size: 1.2em;
}
i.icon.md-share {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-share:after {
    content: 'share';
    font-size: 1.2em;
}
i.icon.md-share-location {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-share-location:after {
    content: 'share_location';
    font-size: 1.2em;
}
i.icon.md-shield {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-shield:after {
    content: 'shield';
    font-size: 1.2em;
}
i.icon.md-shield-moon {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-shield-moon:after {
    content: 'shield_moon';
    font-size: 1.2em;
}
i.icon.md-shop {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-shop:after {
    content: 'shop';
    font-size: 1.2em;
}
i.icon.md-shop-2 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-shop-2:after {
    content: 'shop_2';
    font-size: 1.2em;
}
i.icon.md-shop-two {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-shop-two:after {
    content: 'shop_two';
    font-size: 1.2em;
}
i.icon.md-shopping-bag {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-shopping-bag:after {
    content: 'shopping_bag';
    font-size: 1.2em;
}
i.icon.md-shopping-basket {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-shopping-basket:after {
    content: 'shopping_basket';
    font-size: 1.2em;
}
i.icon.md-shopping-cart {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-shopping-cart:after {
    content: 'shopping_cart';
    font-size: 1.2em;
}
i.icon.md-shopping-cart-checkout {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-shopping-cart-checkout:after {
    content: 'shopping_cart_checkout';
    font-size: 1.2em;
}
i.icon.md-short-text {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-short-text:after {
    content: 'short_text';
    font-size: 1.2em;
}
i.icon.md-shortcut {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-shortcut:after {
    content: 'shortcut';
    font-size: 1.2em;
}
i.icon.md-show-chart {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-show-chart:after {
    content: 'show_chart';
    font-size: 1.2em;
}
i.icon.md-shower {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-shower:after {
    content: 'shower';
    font-size: 1.2em;
}
i.icon.md-shuffle {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-shuffle:after {
    content: 'shuffle';
    font-size: 1.2em;
}
i.icon.md-shuffle-on {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-shuffle-on:after {
    content: 'shuffle_on';
    font-size: 1.2em;
}
i.icon.md-shutter-speed {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-shutter-speed:after {
    content: 'shutter_speed';
    font-size: 1.2em;
}
i.icon.md-sick {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sick:after {
    content: 'sick';
    font-size: 1.2em;
}
i.icon.md-sign-language {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sign-language:after {
    content: 'sign_language';
    font-size: 1.2em;
}
i.icon.md-signal-cellular-0-bar {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-signal-cellular-0-bar:after {
    content: 'signal_cellular_0_bar';
    font-size: 1.2em;
}
i.icon.md-signal-cellular-4-bar {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-signal-cellular-4-bar:after {
    content: 'signal_cellular_4_bar';
    font-size: 1.2em;
}
i.icon.md-signal-cellular-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-signal-cellular-alt:after {
    content: 'signal_cellular_alt';
    font-size: 1.2em;
}
i.icon.md-signal-cellular-alt-1-bar {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-signal-cellular-alt-1-bar:after {
    content: 'signal_cellular_alt_1_bar';
    font-size: 1.2em;
}
i.icon.md-signal-cellular-alt-2-bar {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-signal-cellular-alt-2-bar:after {
    content: 'signal_cellular_alt_2_bar';
    font-size: 1.2em;
}
i.icon.md-signal-cellular-connected-no-internet-0-bar {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-signal-cellular-connected-no-internet-0-bar:after {
    content: 'signal_cellular_connected_no_internet_0_bar';
    font-size: 1.2em;
}
i.icon.md-signal-cellular-connected-no-internet-4-bar {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-signal-cellular-connected-no-internet-4-bar:after {
    content: 'signal_cellular_connected_no_internet_4_bar';
    font-size: 1.2em;
}
i.icon.md-signal-cellular-no-sim {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-signal-cellular-no-sim:after {
    content: 'signal_cellular_no_sim';
    font-size: 1.2em;
}
i.icon.md-signal-cellular-nodata {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-signal-cellular-nodata:after {
    content: 'signal_cellular_nodata';
    font-size: 1.2em;
}
i.icon.md-signal-cellular-null {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-signal-cellular-null:after {
    content: 'signal_cellular_null';
    font-size: 1.2em;
}
i.icon.md-signal-cellular-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-signal-cellular-off:after {
    content: 'signal_cellular_off';
    font-size: 1.2em;
}
i.icon.md-signal-wifi-0-bar {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-signal-wifi-0-bar:after {
    content: 'signal_wifi_0_bar';
    font-size: 1.2em;
}
i.icon.md-signal-wifi-4-bar {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-signal-wifi-4-bar:after {
    content: 'signal_wifi_4_bar';
    font-size: 1.2em;
}
i.icon.md-signal-wifi-4-bar-lock {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-signal-wifi-4-bar-lock:after {
    content: 'signal_wifi_4_bar_lock';
    font-size: 1.2em;
}
i.icon.md-signal-wifi-bad {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-signal-wifi-bad:after {
    content: 'signal_wifi_bad';
    font-size: 1.2em;
}
i.icon.md-signal-wifi-connected-no-internet-4 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-signal-wifi-connected-no-internet-4:after {
    content: 'signal_wifi_connected_no_internet_4';
    font-size: 1.2em;
}
i.icon.md-signal-wifi-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-signal-wifi-off:after {
    content: 'signal_wifi_off';
    font-size: 1.2em;
}
i.icon.md-signal-wifi-statusbar-4-bar {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-signal-wifi-statusbar-4-bar:after {
    content: 'signal_wifi_statusbar_4_bar';
    font-size: 1.2em;
}
i.icon.md-signal-wifi-statusbar-connected-no-internet-4 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-signal-wifi-statusbar-connected-no-internet-4:after {
    content: 'signal_wifi_statusbar_connected_no_internet_4';
    font-size: 1.2em;
}
i.icon.md-signal-wifi-statusbar-null {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-signal-wifi-statusbar-null:after {
    content: 'signal_wifi_statusbar_null';
    font-size: 1.2em;
}
i.icon.md-signpost {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-signpost:after {
    content: 'signpost';
    font-size: 1.2em;
}
i.icon.md-sim-card {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sim-card:after {
    content: 'sim_card';
    font-size: 1.2em;
}
i.icon.md-sim-card-alert {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sim-card-alert:after {
    content: 'sim_card_alert';
    font-size: 1.2em;
}
i.icon.md-sim-card-download {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sim-card-download:after {
    content: 'sim_card_download';
    font-size: 1.2em;
}
i.icon.md-single-bed {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-single-bed:after {
    content: 'single_bed';
    font-size: 1.2em;
}
i.icon.md-sip {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sip:after {
    content: 'sip';
    font-size: 1.2em;
}
i.icon.md-skateboarding {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-skateboarding:after {
    content: 'skateboarding';
    font-size: 1.2em;
}
i.icon.md-skip-next {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-skip-next:after {
    content: 'skip_next';
    font-size: 1.2em;
}
i.icon.md-skip-previous {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-skip-previous:after {
    content: 'skip_previous';
    font-size: 1.2em;
}
i.icon.md-sledding {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sledding:after {
    content: 'sledding';
    font-size: 1.2em;
}
i.icon.md-slideshow {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-slideshow:after {
    content: 'slideshow';
    font-size: 1.2em;
}
i.icon.md-slow-motion-video {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-slow-motion-video:after {
    content: 'slow_motion_video';
    font-size: 1.2em;
}
i.icon.md-smart-button {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-smart-button:after {
    content: 'smart_button';
    font-size: 1.2em;
}
i.icon.md-smart-display {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-smart-display:after {
    content: 'smart_display';
    font-size: 1.2em;
}
i.icon.md-smart-screen {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-smart-screen:after {
    content: 'smart_screen';
    font-size: 1.2em;
}
i.icon.md-smart-toy {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-smart-toy:after {
    content: 'smart_toy';
    font-size: 1.2em;
}
i.icon.md-smartphone {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-smartphone:after {
    content: 'smartphone';
    font-size: 1.2em;
}
i.icon.md-smoke-free {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-smoke-free:after {
    content: 'smoke_free';
    font-size: 1.2em;
}
i.icon.md-smoking-rooms {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-smoking-rooms:after {
    content: 'smoking_rooms';
    font-size: 1.2em;
}
i.icon.md-sms {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sms:after {
    content: 'sms';
    font-size: 1.2em;
}
i.icon.md-sms-failed {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sms-failed:after {
    content: 'sms_failed';
    font-size: 1.2em;
}
i.icon.md-snippet-folder {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-snippet-folder:after {
    content: 'snippet_folder';
    font-size: 1.2em;
}
i.icon.md-snooze {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-snooze:after {
    content: 'snooze';
    font-size: 1.2em;
}
i.icon.md-snowboarding {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-snowboarding:after {
    content: 'snowboarding';
    font-size: 1.2em;
}
i.icon.md-snowmobile {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-snowmobile:after {
    content: 'snowmobile';
    font-size: 1.2em;
}
i.icon.md-snowshoeing {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-snowshoeing:after {
    content: 'snowshoeing';
    font-size: 1.2em;
}
i.icon.md-soap {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-soap:after {
    content: 'soap';
    font-size: 1.2em;
}
i.icon.md-social-distance {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-social-distance:after {
    content: 'social_distance';
    font-size: 1.2em;
}
i.icon.md-solar-power {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-solar-power:after {
    content: 'solar_power';
    font-size: 1.2em;
}
i.icon.md-sort {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sort:after {
    content: 'sort';
    font-size: 1.2em;
}
i.icon.md-sort-by-alpha {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sort-by-alpha:after {
    content: 'sort_by_alpha';
    font-size: 1.2em;
}
i.icon.md-sos {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sos:after {
    content: 'sos';
    font-size: 1.2em;
}
i.icon.md-soup-kitchen {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-soup-kitchen:after {
    content: 'soup_kitchen';
    font-size: 1.2em;
}
i.icon.md-source {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-source:after {
    content: 'source';
    font-size: 1.2em;
}
i.icon.md-south {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-south:after {
    content: 'south';
    font-size: 1.2em;
}
i.icon.md-south-america {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-south-america:after {
    content: 'south_america';
    font-size: 1.2em;
}
i.icon.md-south-east {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-south-east:after {
    content: 'south_east';
    font-size: 1.2em;
}
i.icon.md-south-west {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-south-west:after {
    content: 'south_west';
    font-size: 1.2em;
}
i.icon.md-spa {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-spa:after {
    content: 'spa';
    font-size: 1.2em;
}
i.icon.md-space-bar {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-space-bar:after {
    content: 'space_bar';
    font-size: 1.2em;
}
i.icon.md-space-dashboard {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-space-dashboard:after {
    content: 'space_dashboard';
    font-size: 1.2em;
}
i.icon.md-spatial-audio {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-spatial-audio:after {
    content: 'spatial_audio';
    font-size: 1.2em;
}
i.icon.md-spatial-audio-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-spatial-audio-off:after {
    content: 'spatial_audio_off';
    font-size: 1.2em;
}
i.icon.md-spatial-tracking {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-spatial-tracking:after {
    content: 'spatial_tracking';
    font-size: 1.2em;
}
i.icon.md-speaker {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-speaker:after {
    content: 'speaker';
    font-size: 1.2em;
}
i.icon.md-speaker-group {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-speaker-group:after {
    content: 'speaker_group';
    font-size: 1.2em;
}
i.icon.md-speaker-notes {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-speaker-notes:after {
    content: 'speaker_notes';
    font-size: 1.2em;
}
i.icon.md-speaker-notes-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-speaker-notes-off:after {
    content: 'speaker_notes_off';
    font-size: 1.2em;
}
i.icon.md-speaker-phone {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-speaker-phone:after {
    content: 'speaker_phone';
    font-size: 1.2em;
}
i.icon.md-speed {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-speed:after {
    content: 'speed';
    font-size: 1.2em;
}
i.icon.md-spellcheck {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-spellcheck:after {
    content: 'spellcheck';
    font-size: 1.2em;
}
i.icon.md-splitscreen {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-splitscreen:after {
    content: 'splitscreen';
    font-size: 1.2em;
}
i.icon.md-spoke {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-spoke:after {
    content: 'spoke';
    font-size: 1.2em;
}
i.icon.md-sports {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sports:after {
    content: 'sports';
    font-size: 1.2em;
}
i.icon.md-sports-bar {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sports-bar:after {
    content: 'sports_bar';
    font-size: 1.2em;
}
i.icon.md-sports-baseball {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sports-baseball:after {
    content: 'sports_baseball';
    font-size: 1.2em;
}
i.icon.md-sports-basketball {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sports-basketball:after {
    content: 'sports_basketball';
    font-size: 1.2em;
}
i.icon.md-sports-cricket {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sports-cricket:after {
    content: 'sports_cricket';
    font-size: 1.2em;
}
i.icon.md-sports-esports {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sports-esports:after {
    content: 'sports_esports';
    font-size: 1.2em;
}
i.icon.md-sports-football {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sports-football:after {
    content: 'sports_football';
    font-size: 1.2em;
}
i.icon.md-sports-golf {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sports-golf:after {
    content: 'sports_golf';
    font-size: 1.2em;
}
i.icon.md-sports-gymnastics {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sports-gymnastics:after {
    content: 'sports_gymnastics';
    font-size: 1.2em;
}
i.icon.md-sports-handball {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sports-handball:after {
    content: 'sports_handball';
    font-size: 1.2em;
}
i.icon.md-sports-hockey {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sports-hockey:after {
    content: 'sports_hockey';
    font-size: 1.2em;
}
i.icon.md-sports-kabaddi {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sports-kabaddi:after {
    content: 'sports_kabaddi';
    font-size: 1.2em;
}
i.icon.md-sports-martial-arts {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sports-martial-arts:after {
    content: 'sports_martial_arts';
    font-size: 1.2em;
}
i.icon.md-sports-mma {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sports-mma:after {
    content: 'sports_mma';
    font-size: 1.2em;
}
i.icon.md-sports-motorsports {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sports-motorsports:after {
    content: 'sports_motorsports';
    font-size: 1.2em;
}
i.icon.md-sports-rugby {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sports-rugby:after {
    content: 'sports_rugby';
    font-size: 1.2em;
}
i.icon.md-sports-score {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sports-score:after {
    content: 'sports_score';
    font-size: 1.2em;
}
i.icon.md-sports-soccer {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sports-soccer:after {
    content: 'sports_soccer';
    font-size: 1.2em;
}
i.icon.md-sports-tennis {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sports-tennis:after {
    content: 'sports_tennis';
    font-size: 1.2em;
}
i.icon.md-sports-volleyball {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sports-volleyball:after {
    content: 'sports_volleyball';
    font-size: 1.2em;
}
i.icon.md-square {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-square:after {
    content: 'square';
    font-size: 1.2em;
}
i.icon.md-square-foot {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-square-foot:after {
    content: 'square_foot';
    font-size: 1.2em;
}
i.icon.md-ssid-chart {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-ssid-chart:after {
    content: 'ssid_chart';
    font-size: 1.2em;
}
i.icon.md-stacked-bar-chart {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-stacked-bar-chart:after {
    content: 'stacked_bar_chart';
    font-size: 1.2em;
}
i.icon.md-stacked-line-chart {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-stacked-line-chart:after {
    content: 'stacked_line_chart';
    font-size: 1.2em;
}
i.icon.md-stadium {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-stadium:after {
    content: 'stadium';
    font-size: 1.2em;
}
i.icon.md-stairs {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-stairs:after {
    content: 'stairs';
    font-size: 1.2em;
}
i.icon.md-star {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-star:after {
    content: 'star';
    font-size: 1.2em;
}
i.icon.md-star-border {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-star-border:after {
    content: 'star_border';
    font-size: 1.2em;
}
i.icon.md-star-border-purple500 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-star-border-purple500:after {
    content: 'star_border_purple500';
    font-size: 1.2em;
}
i.icon.md-star-half {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-star-half:after {
    content: 'star_half';
    font-size: 1.2em;
}
i.icon.md-star-outline {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-star-outline:after {
    content: 'star_outline';
    font-size: 1.2em;
}
i.icon.md-star-purple500 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-star-purple500:after {
    content: 'star_purple500';
    font-size: 1.2em;
}
i.icon.md-star-rate {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-star-rate:after {
    content: 'star_rate';
    font-size: 1.2em;
}
i.icon.md-stars {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-stars:after {
    content: 'stars';
    font-size: 1.2em;
}
i.icon.md-start {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-start:after {
    content: 'start';
    font-size: 1.2em;
}
i.icon.md-stay-current-landscape {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-stay-current-landscape:after {
    content: 'stay_current_landscape';
    font-size: 1.2em;
}
i.icon.md-stay-current-portrait {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-stay-current-portrait:after {
    content: 'stay_current_portrait';
    font-size: 1.2em;
}
i.icon.md-stay-primary-landscape {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-stay-primary-landscape:after {
    content: 'stay_primary_landscape';
    font-size: 1.2em;
}
i.icon.md-stay-primary-portrait {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-stay-primary-portrait:after {
    content: 'stay_primary_portrait';
    font-size: 1.2em;
}
i.icon.md-sticky-note-2 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sticky-note-2:after {
    content: 'sticky_note_2';
    font-size: 1.2em;
}
i.icon.md-stop {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-stop:after {
    content: 'stop';
    font-size: 1.2em;
}
i.icon.md-stop-circle {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-stop-circle:after {
    content: 'stop_circle';
    font-size: 1.2em;
}
i.icon.md-stop-screen-share {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-stop-screen-share:after {
    content: 'stop_screen_share';
    font-size: 1.2em;
}
i.icon.md-storage {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-storage:after {
    content: 'storage';
    font-size: 1.2em;
}
i.icon.md-store {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-store:after {
    content: 'store';
    font-size: 1.2em;
}
i.icon.md-store-mall-directory {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-store-mall-directory:after {
    content: 'store_mall_directory';
    font-size: 1.2em;
}
i.icon.md-storefront {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-storefront:after {
    content: 'storefront';
    font-size: 1.2em;
}
i.icon.md-storm {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-storm:after {
    content: 'storm';
    font-size: 1.2em;
}
i.icon.md-straight {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-straight:after {
    content: 'straight';
    font-size: 1.2em;
}
i.icon.md-straighten {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-straighten:after {
    content: 'straighten';
    font-size: 1.2em;
}
i.icon.md-stream {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-stream:after {
    content: 'stream';
    font-size: 1.2em;
}
i.icon.md-streetview {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-streetview:after {
    content: 'streetview';
    font-size: 1.2em;
}
i.icon.md-strikethrough-s {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-strikethrough-s:after {
    content: 'strikethrough_s';
    font-size: 1.2em;
}
i.icon.md-stroller {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-stroller:after {
    content: 'stroller';
    font-size: 1.2em;
}
i.icon.md-style {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-style:after {
    content: 'style';
    font-size: 1.2em;
}
i.icon.md-subdirectory-arrow-left {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-subdirectory-arrow-left:after {
    content: 'subdirectory_arrow_left';
    font-size: 1.2em;
}
i.icon.md-subdirectory-arrow-right {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-subdirectory-arrow-right:after {
    content: 'subdirectory_arrow_right';
    font-size: 1.2em;
}
i.icon.md-subject {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-subject:after {
    content: 'subject';
    font-size: 1.2em;
}
i.icon.md-subscript {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-subscript:after {
    content: 'subscript';
    font-size: 1.2em;
}
i.icon.md-subscriptions {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-subscriptions:after {
    content: 'subscriptions';
    font-size: 1.2em;
}
i.icon.md-subtitles {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-subtitles:after {
    content: 'subtitles';
    font-size: 1.2em;
}
i.icon.md-subtitles-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-subtitles-off:after {
    content: 'subtitles_off';
    font-size: 1.2em;
}
i.icon.md-subway {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-subway:after {
    content: 'subway';
    font-size: 1.2em;
}
i.icon.md-summarize {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-summarize:after {
    content: 'summarize';
    font-size: 1.2em;
}
i.icon.md-superscript {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-superscript:after {
    content: 'superscript';
    font-size: 1.2em;
}
i.icon.md-supervised-user-circle {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-supervised-user-circle:after {
    content: 'supervised_user_circle';
    font-size: 1.2em;
}
i.icon.md-supervisor-account {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-supervisor-account:after {
    content: 'supervisor_account';
    font-size: 1.2em;
}
i.icon.md-support {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-support:after {
    content: 'support';
    font-size: 1.2em;
}
i.icon.md-support-agent {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-support-agent:after {
    content: 'support_agent';
    font-size: 1.2em;
}
i.icon.md-surfing {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-surfing:after {
    content: 'surfing';
    font-size: 1.2em;
}
i.icon.md-surround-sound {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-surround-sound:after {
    content: 'surround_sound';
    font-size: 1.2em;
}
i.icon.md-swap-calls {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-swap-calls:after {
    content: 'swap_calls';
    font-size: 1.2em;
}
i.icon.md-swap-horiz {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-swap-horiz:after {
    content: 'swap_horiz';
    font-size: 1.2em;
}
i.icon.md-swap-horizontal-circle {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-swap-horizontal-circle:after {
    content: 'swap_horizontal_circle';
    font-size: 1.2em;
}
i.icon.md-swap-vert {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-swap-vert:after {
    content: 'swap_vert';
    font-size: 1.2em;
}
i.icon.md-swap-vertical-circle {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-swap-vertical-circle:after {
    content: 'swap_vertical_circle';
    font-size: 1.2em;
}
i.icon.md-swipe {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-swipe:after {
    content: 'swipe';
    font-size: 1.2em;
}
i.icon.md-swipe-down {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-swipe-down:after {
    content: 'swipe_down';
    font-size: 1.2em;
}
i.icon.md-swipe-down-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-swipe-down-alt:after {
    content: 'swipe_down_alt';
    font-size: 1.2em;
}
i.icon.md-swipe-left {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-swipe-left:after {
    content: 'swipe_left';
    font-size: 1.2em;
}
i.icon.md-swipe-left-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-swipe-left-alt:after {
    content: 'swipe_left_alt';
    font-size: 1.2em;
}
i.icon.md-swipe-right {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-swipe-right:after {
    content: 'swipe_right';
    font-size: 1.2em;
}
i.icon.md-swipe-right-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-swipe-right-alt:after {
    content: 'swipe_right_alt';
    font-size: 1.2em;
}
i.icon.md-swipe-up {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-swipe-up:after {
    content: 'swipe_up';
    font-size: 1.2em;
}
i.icon.md-swipe-up-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-swipe-up-alt:after {
    content: 'swipe_up_alt';
    font-size: 1.2em;
}
i.icon.md-swipe-vertical {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-swipe-vertical:after {
    content: 'swipe_vertical';
    font-size: 1.2em;
}
i.icon.md-switch-access-shortcut {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-switch-access-shortcut:after {
    content: 'switch_access_shortcut';
    font-size: 1.2em;
}
i.icon.md-switch-access-shortcut-add {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-switch-access-shortcut-add:after {
    content: 'switch_access_shortcut_add';
    font-size: 1.2em;
}
i.icon.md-switch-account {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-switch-account:after {
    content: 'switch_account';
    font-size: 1.2em;
}
i.icon.md-switch-camera {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-switch-camera:after {
    content: 'switch_camera';
    font-size: 1.2em;
}
i.icon.md-switch-left {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-switch-left:after {
    content: 'switch_left';
    font-size: 1.2em;
}
i.icon.md-switch-right {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-switch-right:after {
    content: 'switch_right';
    font-size: 1.2em;
}
i.icon.md-switch-video {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-switch-video:after {
    content: 'switch_video';
    font-size: 1.2em;
}
i.icon.md-synagogue {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-synagogue:after {
    content: 'synagogue';
    font-size: 1.2em;
}
i.icon.md-sync {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sync:after {
    content: 'sync';
    font-size: 1.2em;
}
i.icon.md-sync-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sync-alt:after {
    content: 'sync_alt';
    font-size: 1.2em;
}
i.icon.md-sync-disabled {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sync-disabled:after {
    content: 'sync_disabled';
    font-size: 1.2em;
}
i.icon.md-sync-lock {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sync-lock:after {
    content: 'sync_lock';
    font-size: 1.2em;
}
i.icon.md-sync-problem {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-sync-problem:after {
    content: 'sync_problem';
    font-size: 1.2em;
}
i.icon.md-system-security-update {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-system-security-update:after {
    content: 'system_security_update';
    font-size: 1.2em;
}
i.icon.md-system-security-update-good {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-system-security-update-good:after {
    content: 'system_security_update_good';
    font-size: 1.2em;
}
i.icon.md-system-security-update-warning {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-system-security-update-warning:after {
    content: 'system_security_update_warning';
    font-size: 1.2em;
}
i.icon.md-system-update {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-system-update:after {
    content: 'system_update';
    font-size: 1.2em;
}
i.icon.md-system-update-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-system-update-alt:after {
    content: 'system_update_alt';
    font-size: 1.2em;
}
i.icon.md-tab {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-tab:after {
    content: 'tab';
    font-size: 1.2em;
}
i.icon.md-tab-unselected {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-tab-unselected:after {
    content: 'tab_unselected';
    font-size: 1.2em;
}
i.icon.md-table-bar {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-table-bar:after {
    content: 'table_bar';
    font-size: 1.2em;
}
i.icon.md-table-chart {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-table-chart:after {
    content: 'table_chart';
    font-size: 1.2em;
}
i.icon.md-table-restaurant {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-table-restaurant:after {
    content: 'table_restaurant';
    font-size: 1.2em;
}
i.icon.md-table-rows {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-table-rows:after {
    content: 'table_rows';
    font-size: 1.2em;
}
i.icon.md-table-view {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-table-view:after {
    content: 'table_view';
    font-size: 1.2em;
}
i.icon.md-tablet {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-tablet:after {
    content: 'tablet';
    font-size: 1.2em;
}
i.icon.md-tablet-android {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-tablet-android:after {
    content: 'tablet_android';
    font-size: 1.2em;
}
i.icon.md-tablet-mac {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-tablet-mac:after {
    content: 'tablet_mac';
    font-size: 1.2em;
}
i.icon.md-tag {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-tag:after {
    content: 'tag';
    font-size: 1.2em;
}
i.icon.md-tag-faces {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-tag-faces:after {
    content: 'tag_faces';
    font-size: 1.2em;
}
i.icon.md-takeout-dining {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-takeout-dining:after {
    content: 'takeout_dining';
    font-size: 1.2em;
}
i.icon.md-tap-and-play {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-tap-and-play:after {
    content: 'tap_and_play';
    font-size: 1.2em;
}
i.icon.md-tapas {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-tapas:after {
    content: 'tapas';
    font-size: 1.2em;
}
i.icon.md-task {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-task:after {
    content: 'task';
    font-size: 1.2em;
}
i.icon.md-task-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-task-alt:after {
    content: 'task_alt';
    font-size: 1.2em;
}
i.icon.md-taxi-alert {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-taxi-alert:after {
    content: 'taxi_alert';
    font-size: 1.2em;
}
i.icon.md-temple-buddhist {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-temple-buddhist:after {
    content: 'temple_buddhist';
    font-size: 1.2em;
}
i.icon.md-temple-hindu {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-temple-hindu:after {
    content: 'temple_hindu';
    font-size: 1.2em;
}
i.icon.md-terminal {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-terminal:after {
    content: 'terminal';
    font-size: 1.2em;
}
i.icon.md-terrain {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-terrain:after {
    content: 'terrain';
    font-size: 1.2em;
}
i.icon.md-text-decrease {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-text-decrease:after {
    content: 'text_decrease';
    font-size: 1.2em;
}
i.icon.md-text-fields {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-text-fields:after {
    content: 'text_fields';
    font-size: 1.2em;
}
i.icon.md-text-format {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-text-format:after {
    content: 'text_format';
    font-size: 1.2em;
}
i.icon.md-text-increase {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-text-increase:after {
    content: 'text_increase';
    font-size: 1.2em;
}
i.icon.md-text-rotate-up {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-text-rotate-up:after {
    content: 'text_rotate_up';
    font-size: 1.2em;
}
i.icon.md-text-rotate-vertical {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-text-rotate-vertical:after {
    content: 'text_rotate_vertical';
    font-size: 1.2em;
}
i.icon.md-text-rotation-angledown {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-text-rotation-angledown:after {
    content: 'text_rotation_angledown';
    font-size: 1.2em;
}
i.icon.md-text-rotation-angleup {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-text-rotation-angleup:after {
    content: 'text_rotation_angleup';
    font-size: 1.2em;
}
i.icon.md-text-rotation-down {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-text-rotation-down:after {
    content: 'text_rotation_down';
    font-size: 1.2em;
}
i.icon.md-text-rotation-none {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-text-rotation-none:after {
    content: 'text_rotation_none';
    font-size: 1.2em;
}
i.icon.md-text-snippet {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-text-snippet:after {
    content: 'text_snippet';
    font-size: 1.2em;
}
i.icon.md-textsms {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-textsms:after {
    content: 'textsms';
    font-size: 1.2em;
}
i.icon.md-texture {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-texture:after {
    content: 'texture';
    font-size: 1.2em;
}
i.icon.md-theater-comedy {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-theater-comedy:after {
    content: 'theater_comedy';
    font-size: 1.2em;
}
i.icon.md-theaters {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-theaters:after {
    content: 'theaters';
    font-size: 1.2em;
}
i.icon.md-thermostat {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-thermostat:after {
    content: 'thermostat';
    font-size: 1.2em;
}
i.icon.md-thermostat-auto {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-thermostat-auto:after {
    content: 'thermostat_auto';
    font-size: 1.2em;
}
i.icon.md-thumb-down {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-thumb-down:after {
    content: 'thumb_down';
    font-size: 1.2em;
}
i.icon.md-thumb-down-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-thumb-down-alt:after {
    content: 'thumb_down_alt';
    font-size: 1.2em;
}
i.icon.md-thumb-down-off-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-thumb-down-off-alt:after {
    content: 'thumb_down_off_alt';
    font-size: 1.2em;
}
i.icon.md-thumb-up {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-thumb-up:after {
    content: 'thumb_up';
    font-size: 1.2em;
}
i.icon.md-thumb-up-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-thumb-up-alt:after {
    content: 'thumb_up_alt';
    font-size: 1.2em;
}
i.icon.md-thumb-up-off-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-thumb-up-off-alt:after {
    content: 'thumb_up_off_alt';
    font-size: 1.2em;
}
i.icon.md-thumbs-up-down {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-thumbs-up-down:after {
    content: 'thumbs_up_down';
    font-size: 1.2em;
}
i.icon.md-thunderstorm {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-thunderstorm:after {
    content: 'thunderstorm';
    font-size: 1.2em;
}
i.icon.md-time-to-leave {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-time-to-leave:after {
    content: 'time_to_leave';
    font-size: 1.2em;
}
i.icon.md-timelapse {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-timelapse:after {
    content: 'timelapse';
    font-size: 1.2em;
}
i.icon.md-timeline {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-timeline:after {
    content: 'timeline';
    font-size: 1.2em;
}
i.icon.md-timer {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-timer:after {
    content: 'timer';
    font-size: 1.2em;
}
i.icon.md-timer-10 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-timer-10:after {
    content: 'timer_10';
    font-size: 1.2em;
}
i.icon.md-timer-10-select {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-timer-10-select:after {
    content: 'timer_10_select';
    font-size: 1.2em;
}
i.icon.md-timer-3 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-timer-3:after {
    content: 'timer_3';
    font-size: 1.2em;
}
i.icon.md-timer-3-select {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-timer-3-select:after {
    content: 'timer_3_select';
    font-size: 1.2em;
}
i.icon.md-timer-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-timer-off:after {
    content: 'timer_off';
    font-size: 1.2em;
}
i.icon.md-tips-and-updates {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-tips-and-updates:after {
    content: 'tips_and_updates';
    font-size: 1.2em;
}
i.icon.md-tire-repair {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-tire-repair:after {
    content: 'tire_repair';
    font-size: 1.2em;
}
i.icon.md-title {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-title:after {
    content: 'title';
    font-size: 1.2em;
}
i.icon.md-toc {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-toc:after {
    content: 'toc';
    font-size: 1.2em;
}
i.icon.md-today {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-today:after {
    content: 'today';
    font-size: 1.2em;
}
i.icon.md-toggle-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-toggle-off:after {
    content: 'toggle_off';
    font-size: 1.2em;
}
i.icon.md-toggle-on {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-toggle-on:after {
    content: 'toggle_on';
    font-size: 1.2em;
}
i.icon.md-token {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-token:after {
    content: 'token';
    font-size: 1.2em;
}
i.icon.md-toll {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-toll:after {
    content: 'toll';
    font-size: 1.2em;
}
i.icon.md-tonality {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-tonality:after {
    content: 'tonality';
    font-size: 1.2em;
}
i.icon.md-topic {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-topic:after {
    content: 'topic';
    font-size: 1.2em;
}
i.icon.md-tornado {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-tornado:after {
    content: 'tornado';
    font-size: 1.2em;
}
i.icon.md-touch-app {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-touch-app:after {
    content: 'touch_app';
    font-size: 1.2em;
}
i.icon.md-tour {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-tour:after {
    content: 'tour';
    font-size: 1.2em;
}
i.icon.md-toys {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-toys:after {
    content: 'toys';
    font-size: 1.2em;
}
i.icon.md-track-changes {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-track-changes:after {
    content: 'track_changes';
    font-size: 1.2em;
}
i.icon.md-traffic {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-traffic:after {
    content: 'traffic';
    font-size: 1.2em;
}
i.icon.md-train {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-train:after {
    content: 'train';
    font-size: 1.2em;
}
i.icon.md-tram {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-tram:after {
    content: 'tram';
    font-size: 1.2em;
}
i.icon.md-transcribe {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-transcribe:after {
    content: 'transcribe';
    font-size: 1.2em;
}
i.icon.md-transfer-within-a-station {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-transfer-within-a-station:after {
    content: 'transfer_within_a_station';
    font-size: 1.2em;
}
i.icon.md-transform {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-transform:after {
    content: 'transform';
    font-size: 1.2em;
}
i.icon.md-transgender {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-transgender:after {
    content: 'transgender';
    font-size: 1.2em;
}
i.icon.md-transit-enterexit {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-transit-enterexit:after {
    content: 'transit_enterexit';
    font-size: 1.2em;
}
i.icon.md-translate {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-translate:after {
    content: 'translate';
    font-size: 1.2em;
}
i.icon.md-travel-explore {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-travel-explore:after {
    content: 'travel_explore';
    font-size: 1.2em;
}
i.icon.md-trending-down {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-trending-down:after {
    content: 'trending_down';
    font-size: 1.2em;
}
i.icon.md-trending-flat {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-trending-flat:after {
    content: 'trending_flat';
    font-size: 1.2em;
}
i.icon.md-trending-up {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-trending-up:after {
    content: 'trending_up';
    font-size: 1.2em;
}
i.icon.md-trip-origin {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-trip-origin:after {
    content: 'trip_origin';
    font-size: 1.2em;
}
i.icon.md-troubleshoot {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-troubleshoot:after {
    content: 'troubleshoot';
    font-size: 1.2em;
}
i.icon.md-try {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-try:after {
    content: 'try';
    font-size: 1.2em;
}
i.icon.md-tsunami {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-tsunami:after {
    content: 'tsunami';
    font-size: 1.2em;
}
i.icon.md-tty {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-tty:after {
    content: 'tty';
    font-size: 1.2em;
}
i.icon.md-tune {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-tune:after {
    content: 'tune';
    font-size: 1.2em;
}
i.icon.md-tungsten {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-tungsten:after {
    content: 'tungsten';
    font-size: 1.2em;
}
i.icon.md-turn-left {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-turn-left:after {
    content: 'turn_left';
    font-size: 1.2em;
}
i.icon.md-turn-right {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-turn-right:after {
    content: 'turn_right';
    font-size: 1.2em;
}
i.icon.md-turn-sharp-left {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-turn-sharp-left:after {
    content: 'turn_sharp_left';
    font-size: 1.2em;
}
i.icon.md-turn-sharp-right {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-turn-sharp-right:after {
    content: 'turn_sharp_right';
    font-size: 1.2em;
}
i.icon.md-turn-slight-left {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-turn-slight-left:after {
    content: 'turn_slight_left';
    font-size: 1.2em;
}
i.icon.md-turn-slight-right {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-turn-slight-right:after {
    content: 'turn_slight_right';
    font-size: 1.2em;
}
i.icon.md-turned-in {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-turned-in:after {
    content: 'turned_in';
    font-size: 1.2em;
}
i.icon.md-turned-in-not {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-turned-in-not:after {
    content: 'turned_in_not';
    font-size: 1.2em;
}
i.icon.md-tv {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-tv:after {
    content: 'tv';
    font-size: 1.2em;
}
i.icon.md-tv-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-tv-off:after {
    content: 'tv_off';
    font-size: 1.2em;
}
i.icon.md-two-wheeler {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-two-wheeler:after {
    content: 'two_wheeler';
    font-size: 1.2em;
}
i.icon.md-type-specimen {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-type-specimen:after {
    content: 'type_specimen';
    font-size: 1.2em;
}
i.icon.md-u-turn-left {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-u-turn-left:after {
    content: 'u_turn_left';
    font-size: 1.2em;
}
i.icon.md-u-turn-right {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-u-turn-right:after {
    content: 'u_turn_right';
    font-size: 1.2em;
}
i.icon.md-umbrella {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-umbrella:after {
    content: 'umbrella';
    font-size: 1.2em;
}
i.icon.md-unarchive {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-unarchive:after {
    content: 'unarchive';
    font-size: 1.2em;
}
i.icon.md-undo {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-undo:after {
    content: 'undo';
    font-size: 1.2em;
}
i.icon.md-unfold-less {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-unfold-less:after {
    content: 'unfold_less';
    font-size: 1.2em;
}
i.icon.md-unfold-less-double {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-unfold-less-double:after {
    content: 'unfold_less_double';
    font-size: 1.2em;
}
i.icon.md-unfold-more {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-unfold-more:after {
    content: 'unfold_more';
    font-size: 1.2em;
}
i.icon.md-unfold-more-double {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-unfold-more-double:after {
    content: 'unfold_more_double';
    font-size: 1.2em;
}
i.icon.md-unpublished {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-unpublished:after {
    content: 'unpublished';
    font-size: 1.2em;
}
i.icon.md-unsubscribe {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-unsubscribe:after {
    content: 'unsubscribe';
    font-size: 1.2em;
}
i.icon.md-upcoming {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-upcoming:after {
    content: 'upcoming';
    font-size: 1.2em;
}
i.icon.md-update {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-update:after {
    content: 'update';
    font-size: 1.2em;
}
i.icon.md-update-disabled {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-update-disabled:after {
    content: 'update_disabled';
    font-size: 1.2em;
}
i.icon.md-upgrade {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-upgrade:after {
    content: 'upgrade';
    font-size: 1.2em;
}
i.icon.md-upload {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-upload:after {
    content: 'upload';
    font-size: 1.2em;
}
i.icon.md-upload-file {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-upload-file:after {
    content: 'upload_file';
    font-size: 1.2em;
}
i.icon.md-usb {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-usb:after {
    content: 'usb';
    font-size: 1.2em;
}
i.icon.md-usb-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-usb-off:after {
    content: 'usb_off';
    font-size: 1.2em;
}
i.icon.md-vaccines {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-vaccines:after {
    content: 'vaccines';
    font-size: 1.2em;
}
i.icon.md-vape-free {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-vape-free:after {
    content: 'vape_free';
    font-size: 1.2em;
}
i.icon.md-vaping-rooms {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-vaping-rooms:after {
    content: 'vaping_rooms';
    font-size: 1.2em;
}
i.icon.md-verified {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-verified:after {
    content: 'verified';
    font-size: 1.2em;
}
i.icon.md-verified-user {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-verified-user:after {
    content: 'verified_user';
    font-size: 1.2em;
}
i.icon.md-vertical-align-bottom {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-vertical-align-bottom:after {
    content: 'vertical_align_bottom';
    font-size: 1.2em;
}
i.icon.md-vertical-align-center {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-vertical-align-center:after {
    content: 'vertical_align_center';
    font-size: 1.2em;
}
i.icon.md-vertical-align-top {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-vertical-align-top:after {
    content: 'vertical_align_top';
    font-size: 1.2em;
}
i.icon.md-vertical-distribute {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-vertical-distribute:after {
    content: 'vertical_distribute';
    font-size: 1.2em;
}
i.icon.md-vertical-shades {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-vertical-shades:after {
    content: 'vertical_shades';
    font-size: 1.2em;
}
i.icon.md-vertical-shades-closed {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-vertical-shades-closed:after {
    content: 'vertical_shades_closed';
    font-size: 1.2em;
}
i.icon.md-vertical-split {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-vertical-split:after {
    content: 'vertical_split';
    font-size: 1.2em;
}
i.icon.md-vibration {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-vibration:after {
    content: 'vibration';
    font-size: 1.2em;
}
i.icon.md-video-call {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-video-call:after {
    content: 'video_call';
    font-size: 1.2em;
}
i.icon.md-video-camera-back {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-video-camera-back:after {
    content: 'video_camera_back';
    font-size: 1.2em;
}
i.icon.md-video-camera-front {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-video-camera-front:after {
    content: 'video_camera_front';
    font-size: 1.2em;
}
i.icon.md-video-chat {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-video-chat:after {
    content: 'video_chat';
    font-size: 1.2em;
}
i.icon.md-video-file {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-video-file:after {
    content: 'video_file';
    font-size: 1.2em;
}
i.icon.md-video-label {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-video-label:after {
    content: 'video_label';
    font-size: 1.2em;
}
i.icon.md-video-library {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-video-library:after {
    content: 'video_library';
    font-size: 1.2em;
}
i.icon.md-video-settings {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-video-settings:after {
    content: 'video_settings';
    font-size: 1.2em;
}
i.icon.md-video-stable {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-video-stable:after {
    content: 'video_stable';
    font-size: 1.2em;
}
i.icon.md-videocam {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-videocam:after {
    content: 'videocam';
    font-size: 1.2em;
}
i.icon.md-videocam-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-videocam-off:after {
    content: 'videocam_off';
    font-size: 1.2em;
}
i.icon.md-videogame-asset {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-videogame-asset:after {
    content: 'videogame_asset';
    font-size: 1.2em;
}
i.icon.md-videogame-asset-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-videogame-asset-off:after {
    content: 'videogame_asset_off';
    font-size: 1.2em;
}
i.icon.md-view-agenda {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-view-agenda:after {
    content: 'view_agenda';
    font-size: 1.2em;
}
i.icon.md-view-array {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-view-array:after {
    content: 'view_array';
    font-size: 1.2em;
}
i.icon.md-view-carousel {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-view-carousel:after {
    content: 'view_carousel';
    font-size: 1.2em;
}
i.icon.md-view-column {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-view-column:after {
    content: 'view_column';
    font-size: 1.2em;
}
i.icon.md-view-comfy {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-view-comfy:after {
    content: 'view_comfy';
    font-size: 1.2em;
}
i.icon.md-view-comfy-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-view-comfy-alt:after {
    content: 'view_comfy_alt';
    font-size: 1.2em;
}
i.icon.md-view-compact {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-view-compact:after {
    content: 'view_compact';
    font-size: 1.2em;
}
i.icon.md-view-compact-alt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-view-compact-alt:after {
    content: 'view_compact_alt';
    font-size: 1.2em;
}
i.icon.md-view-cozy {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-view-cozy:after {
    content: 'view_cozy';
    font-size: 1.2em;
}
i.icon.md-view-day {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-view-day:after {
    content: 'view_day';
    font-size: 1.2em;
}
i.icon.md-view-headline {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-view-headline:after {
    content: 'view_headline';
    font-size: 1.2em;
}
i.icon.md-view-in-ar {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-view-in-ar:after {
    content: 'view_in_ar';
    font-size: 1.2em;
}
i.icon.md-view-kanban {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-view-kanban:after {
    content: 'view_kanban';
    font-size: 1.2em;
}
i.icon.md-view-list {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-view-list:after {
    content: 'view_list';
    font-size: 1.2em;
}
i.icon.md-view-module {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-view-module:after {
    content: 'view_module';
    font-size: 1.2em;
}
i.icon.md-view-quilt {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-view-quilt:after {
    content: 'view_quilt';
    font-size: 1.2em;
}
i.icon.md-view-sidebar {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-view-sidebar:after {
    content: 'view_sidebar';
    font-size: 1.2em;
}
i.icon.md-view-stream {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-view-stream:after {
    content: 'view_stream';
    font-size: 1.2em;
}
i.icon.md-view-timeline {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-view-timeline:after {
    content: 'view_timeline';
    font-size: 1.2em;
}
i.icon.md-view-week {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-view-week:after {
    content: 'view_week';
    font-size: 1.2em;
}
i.icon.md-vignette {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-vignette:after {
    content: 'vignette';
    font-size: 1.2em;
}
i.icon.md-villa {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-villa:after {
    content: 'villa';
    font-size: 1.2em;
}
i.icon.md-visibility {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-visibility:after {
    content: 'visibility';
    font-size: 1.2em;
}
i.icon.md-visibility-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-visibility-off:after {
    content: 'visibility_off';
    font-size: 1.2em;
}
i.icon.md-voice-chat {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-voice-chat:after {
    content: 'voice_chat';
    font-size: 1.2em;
}
i.icon.md-voice-over-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-voice-over-off:after {
    content: 'voice_over_off';
    font-size: 1.2em;
}
i.icon.md-voicemail {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-voicemail:after {
    content: 'voicemail';
    font-size: 1.2em;
}
i.icon.md-volcano {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-volcano:after {
    content: 'volcano';
    font-size: 1.2em;
}
i.icon.md-volume-down {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-volume-down:after {
    content: 'volume_down';
    font-size: 1.2em;
}
i.icon.md-volume-mute {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-volume-mute:after {
    content: 'volume_mute';
    font-size: 1.2em;
}
i.icon.md-volume-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-volume-off:after {
    content: 'volume_off';
    font-size: 1.2em;
}
i.icon.md-volume-up {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-volume-up:after {
    content: 'volume_up';
    font-size: 1.2em;
}
i.icon.md-volunteer-activism {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-volunteer-activism:after {
    content: 'volunteer_activism';
    font-size: 1.2em;
}
i.icon.md-vpn-key {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-vpn-key:after {
    content: 'vpn_key';
    font-size: 1.2em;
}
i.icon.md-vpn-key-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-vpn-key-off:after {
    content: 'vpn_key_off';
    font-size: 1.2em;
}
i.icon.md-vpn-lock {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-vpn-lock:after {
    content: 'vpn_lock';
    font-size: 1.2em;
}
i.icon.md-vrpano {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-vrpano:after {
    content: 'vrpano';
    font-size: 1.2em;
}
i.icon.md-wallet {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-wallet:after {
    content: 'wallet';
    font-size: 1.2em;
}
i.icon.md-wallpaper {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-wallpaper:after {
    content: 'wallpaper';
    font-size: 1.2em;
}
i.icon.md-warehouse {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-warehouse:after {
    content: 'warehouse';
    font-size: 1.2em;
}
i.icon.md-warning {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-warning:after {
    content: 'warning';
    font-size: 1.2em;
}
i.icon.md-warning-amber {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-warning-amber:after {
    content: 'warning_amber';
    font-size: 1.2em;
}
i.icon.md-wash {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-wash:after {
    content: 'wash';
    font-size: 1.2em;
}
i.icon.md-watch {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-watch:after {
    content: 'watch';
    font-size: 1.2em;
}
i.icon.md-watch-later {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-watch-later:after {
    content: 'watch_later';
    font-size: 1.2em;
}
i.icon.md-watch-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-watch-off:after {
    content: 'watch_off';
    font-size: 1.2em;
}
i.icon.md-water {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-water:after {
    content: 'water';
    font-size: 1.2em;
}
i.icon.md-water-damage {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-water-damage:after {
    content: 'water_damage';
    font-size: 1.2em;
}
i.icon.md-water-drop {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-water-drop:after {
    content: 'water_drop';
    font-size: 1.2em;
}
i.icon.md-waterfall-chart {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-waterfall-chart:after {
    content: 'waterfall_chart';
    font-size: 1.2em;
}
i.icon.md-waves {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-waves:after {
    content: 'waves';
    font-size: 1.2em;
}
i.icon.md-waving-hand {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-waving-hand:after {
    content: 'waving_hand';
    font-size: 1.2em;
}
i.icon.md-wb-auto {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-wb-auto:after {
    content: 'wb_auto';
    font-size: 1.2em;
}
i.icon.md-wb-cloudy {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-wb-cloudy:after {
    content: 'wb_cloudy';
    font-size: 1.2em;
}
i.icon.md-wb-incandescent {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-wb-incandescent:after {
    content: 'wb_incandescent';
    font-size: 1.2em;
}
i.icon.md-wb-iridescent {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-wb-iridescent:after {
    content: 'wb_iridescent';
    font-size: 1.2em;
}
i.icon.md-wb-shade {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-wb-shade:after {
    content: 'wb_shade';
    font-size: 1.2em;
}
i.icon.md-wb-sunny {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-wb-sunny:after {
    content: 'wb_sunny';
    font-size: 1.2em;
}
i.icon.md-wb-twilight {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-wb-twilight:after {
    content: 'wb_twilight';
    font-size: 1.2em;
}
i.icon.md-wc {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-wc:after {
    content: 'wc';
    font-size: 1.2em;
}
i.icon.md-web {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-web:after {
    content: 'web';
    font-size: 1.2em;
}
i.icon.md-web-asset {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-web-asset:after {
    content: 'web_asset';
    font-size: 1.2em;
}
i.icon.md-web-asset-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-web-asset-off:after {
    content: 'web_asset_off';
    font-size: 1.2em;
}
i.icon.md-web-stories {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-web-stories:after {
    content: 'web_stories';
    font-size: 1.2em;
}
i.icon.md-webhook {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-webhook:after {
    content: 'webhook';
    font-size: 1.2em;
}
i.icon.md-weekend {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-weekend:after {
    content: 'weekend';
    font-size: 1.2em;
}
i.icon.md-west {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-west:after {
    content: 'west';
    font-size: 1.2em;
}
i.icon.md-whatshot {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-whatshot:after {
    content: 'whatshot';
    font-size: 1.2em;
}
i.icon.md-wheelchair-pickup {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-wheelchair-pickup:after {
    content: 'wheelchair_pickup';
    font-size: 1.2em;
}
i.icon.md-where-to-vote {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-where-to-vote:after {
    content: 'where_to_vote';
    font-size: 1.2em;
}
i.icon.md-widgets {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-widgets:after {
    content: 'widgets';
    font-size: 1.2em;
}
i.icon.md-width-full {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-width-full:after {
    content: 'width_full';
    font-size: 1.2em;
}
i.icon.md-width-normal {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-width-normal:after {
    content: 'width_normal';
    font-size: 1.2em;
}
i.icon.md-width-wide {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-width-wide:after {
    content: 'width_wide';
    font-size: 1.2em;
}
i.icon.md-wifi {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-wifi:after {
    content: 'wifi';
    font-size: 1.2em;
}
i.icon.md-wifi-1-bar {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-wifi-1-bar:after {
    content: 'wifi_1_bar';
    font-size: 1.2em;
}
i.icon.md-wifi-2-bar {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-wifi-2-bar:after {
    content: 'wifi_2_bar';
    font-size: 1.2em;
}
i.icon.md-wifi-calling {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-wifi-calling:after {
    content: 'wifi_calling';
    font-size: 1.2em;
}
i.icon.md-wifi-calling-3 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-wifi-calling-3:after {
    content: 'wifi_calling_3';
    font-size: 1.2em;
}
i.icon.md-wifi-channel {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-wifi-channel:after {
    content: 'wifi_channel';
    font-size: 1.2em;
}
i.icon.md-wifi-find {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-wifi-find:after {
    content: 'wifi_find';
    font-size: 1.2em;
}
i.icon.md-wifi-lock {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-wifi-lock:after {
    content: 'wifi_lock';
    font-size: 1.2em;
}
i.icon.md-wifi-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-wifi-off:after {
    content: 'wifi_off';
    font-size: 1.2em;
}
i.icon.md-wifi-password {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-wifi-password:after {
    content: 'wifi_password';
    font-size: 1.2em;
}
i.icon.md-wifi-protected-setup {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-wifi-protected-setup:after {
    content: 'wifi_protected_setup';
    font-size: 1.2em;
}
i.icon.md-wifi-tethering {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-wifi-tethering:after {
    content: 'wifi_tethering';
    font-size: 1.2em;
}
i.icon.md-wifi-tethering-error {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-wifi-tethering-error:after {
    content: 'wifi_tethering_error';
    font-size: 1.2em;
}
i.icon.md-wifi-tethering-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-wifi-tethering-off:after {
    content: 'wifi_tethering_off';
    font-size: 1.2em;
}
i.icon.md-wind-power {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-wind-power:after {
    content: 'wind_power';
    font-size: 1.2em;
}
i.icon.md-window {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-window:after {
    content: 'window';
    font-size: 1.2em;
}
i.icon.md-wine-bar {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-wine-bar:after {
    content: 'wine_bar';
    font-size: 1.2em;
}
i.icon.md-woman {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-woman:after {
    content: 'woman';
    font-size: 1.2em;
}
i.icon.md-woman-2 {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-woman-2:after {
    content: 'woman_2';
    font-size: 1.2em;
}
i.icon.md-work {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-work:after {
    content: 'work';
    font-size: 1.2em;
}
i.icon.md-work-history {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-work-history:after {
    content: 'work_history';
    font-size: 1.2em;
}
i.icon.md-work-off {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-work-off:after {
    content: 'work_off';
    font-size: 1.2em;
}
i.icon.md-work-outline {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-work-outline:after {
    content: 'work_outline';
    font-size: 1.2em;
}
i.icon.md-workspace-premium {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-workspace-premium:after {
    content: 'workspace_premium';
    font-size: 1.2em;
}
i.icon.md-workspaces {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-workspaces:after {
    content: 'workspaces';
    font-size: 1.2em;
}
i.icon.md-wrap-text {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-wrap-text:after {
    content: 'wrap_text';
    font-size: 1.2em;
}
i.icon.md-wrong-location {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-wrong-location:after {
    content: 'wrong_location';
    font-size: 1.2em;
}
i.icon.md-wysiwyg {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-wysiwyg:after {
    content: 'wysiwyg';
    font-size: 1.2em;
}
i.icon.md-yard {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-yard:after {
    content: 'yard';
    font-size: 1.2em;
}
i.icon.md-youtube-searched-for {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-youtube-searched-for:after {
    content: 'youtube_searched_for';
    font-size: 1.2em;
}
i.icon.md-zoom-in {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-zoom-in:after {
    content: 'zoom_in';
    font-size: 1.2em;
}
i.icon.md-zoom-in-map {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-zoom-in-map:after {
    content: 'zoom_in_map';
    font-size: 1.2em;
}
i.icon.md-zoom-out {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-zoom-out:after {
    content: 'zoom_out';
    font-size: 1.2em;
}
i.icon.md-zoom-out-map {
    font-family: 'Material Icons' !important;
    vertical-align: sub;
}
i.icon.md-zoom-out-map:after {
    content: 'zoom_out_map';
    font-size: 1.2em;
}
